import { FC } from "react";
import styled from "styled-components";
import { FlexColumn, H3, H4 } from "notes";
import { getDateOrdinal } from "Utils";

import type { EventModel } from "Hooks/useEventList";

interface EventCardProps {
  event: EventModel;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const EventCard: FC<EventCardProps> = ({ event, ...props }) => {
  const { venue, image, startsAt } = event;
  return (
    <Wrapper {...props}>
      <Content yEnd>
        <H3>{venue}</H3>
        <Divider />
        <H4>
          {startsAt?.toFormat("cccc, LLLL d")}
          {getDateOrdinal(startsAt.day)} ({startsAt?.toFormat("h:mm")}
          <span>{startsAt.toFormat("a").toLowerCase()}</span>
          {startsAt.toFormat("ZZZZ")})
        </H4>
      </Content>
      <Gradient />
      <Image image={image} />
    </Wrapper>
  );
};

const Divider = styled.div`
  height: 2px;
  width: 156px;
  background-color: #ff8c00;
  flex-shrink: 0;
  margin-bottom: 8px;
`;

const Wrapper = styled(FlexColumn)`
  cursor: pointer;
  padding-bottom: 52.44%;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.32), 0 0 8px 0 rgba(0, 0, 0, 0.24);
  margin-top: 16px;
  position: relative;
  overflow: hidden;
  z-index: 2;
  ${H3} {
    color: #ffffff;
    font-family: ${(props) => props.theme.branding.headerFont};
    font-size: 15px;
    font-weight: 700;
    line-height: 19px;
    margin-bottom: 8px;
    text-transform: uppercase;
  }
  ${H4} {
    color: #ffffff;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 300;
    line-height: 19px;
    span {
      margin-right: 4px;
    }
  }
`;

const Content = styled(FlexColumn)`
  padding: 24px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
`;

const Gradient = styled.div`
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

interface Props {
  image: string;
}

const Image = styled.div<Props>`
  background: url(${(props) => props.image}), #000000;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;
