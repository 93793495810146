import { firestore } from "firebase-internal";
import * as nextsong from "@musicaudienceexchange/nextsong-interface";
import { collection, query } from "firebase/firestore";
import { useCollectionModel, whereModel } from "./models";

export const useNextEvent = (groupId: string) => {
  const q = groupId
    ? query(
        collection(firestore, `nextsong_events`),
        whereModel(nextsong.firestore.Event, "groupId", "==", groupId),
        whereModel(nextsong.firestore.Event, "status", "==", "published")
      )
    : undefined;

  const [events, loading] = useCollectionModel(nextsong.firestore.Event, q);

  const nextEvent = events
    // Sort events in ascending order
    ?.sort((a, b) => a.startsAt.diff(b.startsAt, "seconds").seconds)
    .find(
      (event, idx, events) =>
        // Now we find the first event in the future...
        event.startsAt.diffNow("seconds").seconds > 0 ||
        // ...or the first one that hasn't ended...
        event.endedAt === null ||
        // ...or the last event in the array
        events.length - 1 === idx
    );

  return { nextEventId: nextEvent?._id, nextEventLoading: loading };
};
