import { useAppContext } from "Context";
import React, {
  createContext,
  useContext,
  useState,
  FC,
  useEffect,
} from "react";
import { ModalQRView } from ".";
import { ModalAddShow } from "./ModalAddShow";
import { ModalEndEvent } from "./ModalEndEvent";
import { ModalVenueAddress } from "./ModalVenueAddress";
import { SideDrawer } from "./SideDrawer";
import { ModalWinnerDetail } from "./ModalWinnerDetail";
import { ModalConfirm } from "./ModalConfirm";

interface ShowDetailProps {
  venue: string;
  date: string;
  time: string;
  type: string;
}

interface VenueAddressProps {
  venue: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: number;
}

interface ArtistModalProps {
  endEvent?: string;
  venueAddress?: string | boolean;
  addShow?: boolean;
  qrView?: string;
  confirm?: {
    action: () => void;
    title?: string;
    description?: string;
  };
  winnerDetail?: {
    name: string;
    location?: string;
    email: string;
    phone?: string;
    remove?: ArtistModalProps["confirm"];
    zipcode?: string;
  };
}

interface ArtistModalContextProps {
  modal: ArtistModalProps;
  setModal: React.Dispatch<React.SetStateAction<ArtistModalProps>>;
  eventView: "past" | "upcoming" | "qr";
  setEventView: React.Dispatch<React.SetStateAction<string>>;
  venueAddress: VenueAddressProps;
  setVenueAddress: React.Dispatch<React.SetStateAction<VenueAddressProps>>;
  showDetail: ShowDetailProps;
  setShowDetail: React.Dispatch<React.SetStateAction<ShowDetailProps>>;
  closeModal: (val: void) => void;
  groupIdView?: string;
  setGroupIdView: React.Dispatch<React.SetStateAction<string>>;
}

export const ArtistModalProvider: FC = ({ children }) => {
  const { isSideDrawerOpen, setIsSideDrawerOpen } = useAppContext();
  const [modal, setModal] = useState<ArtistModalProps>(null);
  const [showDetail, setShowDetail] = useState<ShowDetailProps>(null);
  const [venueAddress, setVenueAddress] = useState<VenueAddressProps>(null);
  const [eventView, setContextEventView] =
    useState<ArtistModalContextProps["eventView"]>("upcoming");
  const [groupIdView, setGroupIdView] = useState<string>(null);

  const setEventView = (view: ArtistModalContextProps["eventView"]) => {
    if (!!groupIdView && view !== eventView) {
      setGroupIdView(null);
    }
    setContextEventView(view);
  };

  const closeModal = () => setModal(null);

  useEffect(() => {
    if (!!modal) {
      if (document.body.style.overflow !== "hidden")
        document.body.style.overflow = "hidden";
    } else {
      if (document.body.style.overflow !== "auto")
        document.body.style.overflow = "auto";
    }
  }, [modal]);

  const value: ArtistModalContextProps = {
    modal,
    setModal,
    closeModal,
    eventView,
    setEventView,
    venueAddress,
    setVenueAddress,
    showDetail,
    setShowDetail,
    groupIdView,
    setGroupIdView,
  };

  return (
    <ArtistModalContext.Provider value={value}>
      <ModalEndEvent open={!!modal?.endEvent} />
      <ModalVenueAddress open={!!modal?.venueAddress} />
      <ModalQRView open={!!modal?.qrView} />
      <ModalAddShow open={!!modal?.addShow} />
      <ModalWinnerDetail open={!!modal?.winnerDetail} />
      <ModalConfirm open={!!modal?.confirm} />
      <SideDrawer
        isMenuOpen={isSideDrawerOpen}
        setIsMenuOpen={setIsSideDrawerOpen}
      />
      {children}
    </ArtistModalContext.Provider>
  );
};

export const ArtistModalContext =
  createContext<ArtistModalContextProps>(undefined);
export const useArtistModalContext = () => useContext(ArtistModalContext);
