import { createContext, useContext, FC } from "react";
import { ThemeProvider } from "styled-components";
import { mergeThemeOverrides, defaultBrandingContent } from "theme";
import {
  useDocumentData,
  useDocumentDataOnce,
} from "react-firebase-hooks/firestore";
import { firestore } from "firebase-internal";
import { doc } from "firebase/firestore";
import _ from "lodash";
import { useNextEvent } from "Hooks";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";

export interface BrandOverrides {
  groupId?: string;
  brandingId?: string;
  brand?: string;
  logo?: string;
  logoAlt?: string;
  headerFont?: string;
  bodyFont?: string;
  googleFontUrl?: string;
  colors?: {
    action?: string;
    actionLabel?: string;
    actionSecondary?: string;
    actionSecondaryLabel?: string;
    actionSecondaryBorder?: string;
    input?: {
      background?: string;
      border?: string;
      text?: string;
      font?: string;
      placeholder?: string;
      placeholderFont?: string;
    };
  };
  landing?: {
    title?: string;
    button?: string;
    buttonLoading?: string;
    blocks?: {
      image?: string;
      title?: string;
      body?: string;
      button?: string;
      link?: string;
    }[];
    colors?: {
      button?: string;
      buttonBorder?: string;
      buttonText?: string;
      title?: string;
      page?: string;
      copyright?: string;
      footerLinks?: string;
      blocks?: {
        title?: string;
        body?: string;
        background?: string;
      };
    };
  };
  lobby?: {
    joinButton?: string;
    cancelButton?: string;
    splashImage?: string;
    disableAvatar?: boolean;
    disableText?: boolean;
    colors?: {
      header?: string;
      headerActions?: string;
      page?: string;
      label?: string;
      artist?: string;
      venue?: string;
      date?: string;
      legal?: string;
      copyright?: string;
      footerLinks?: string;
    };
  };
  event?: {
    bannerGradientOffset?: number;
    bannerHeight?: number;
    disableImageBlur?: boolean;
    colors?: {
      header?: string;
      headerActions?: string;
      page?: string;
      artist?: string;
      venue?: string;
      date?: string;
      divider?: string;
      title?: string;
      description?: string;
      body?: string;
      caption?: string;
      action?: string;
      actionLabel?: string;
      listTitle?: string;
      listSubtitle?: string;
      listDivider?: string;
      progressBar?: string;
      progressBarBackground?: string;
      tabs?: {
        activeBackground?: string;
        activeText?: string;
        inactiveBackground?: string;
        inactiveText?: string;
      };
    };
  };
  footer?: {
    copyright?: string;
    termsOfUseUrl?: string;
    privacyPolicyUrl?: string;
  };
}

export const BrandingContext = createContext<BrandOverrides>(null);

export const BrandingProvider: FC = ({ children }) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const history = useHistory();
  const isDev = process.env.NODE_ENV === "development";
  const match = window.location.hostname.match(
    isDev
      ? /(?<slug>[^.]+)?\.localhost/
      : /(?<slug>[^.]+)?(\.((stage)|(demo)|(preprod))\.)?nextsong\.live$/
  );

  const { slug } = match?.groups || {};
  const subdomain = slug ?? params?.brand;

  const hasSubdomain =
    !!subdomain && !["stage", "demo", "www", "preprod"].includes(subdomain);

  const [domain, brandLoading] = useDocumentDataOnce(
    doc(firestore, `nextsong_subdomains/${subdomain}`)
  );

  const [overrides, loading] = useDocumentData<BrandOverrides>(
    doc(firestore, `nextsong_branding/${domain?.brandingId}`)
  );

  const groupId = domain?.groupId;
  const brandingId = domain?.brandingId;

  const { nextEventId, nextEventLoading } = useNextEvent(groupId);

  if (hasSubdomain && (loading || brandLoading || nextEventLoading)) {
    return null;
  }

  if (nextEventId) {
    history.push(`/event/${nextEventId}`);
  }

  const mergedContent = _.merge({}, defaultBrandingContent, overrides);
  const updatedContent = {
    ...mergedContent,
    landing: {
      ...mergedContent.landing,
      blocks:
        overrides?.landing?.blocks ?? defaultBrandingContent.landing.blocks,
    },
  };

  const value: BrandOverrides = {
    groupId,
    brandingId,
    ...updatedContent,
  };

  const updatedTheme = mergeThemeOverrides(overrides);

  return (
    <BrandingContext.Provider value={value}>
      <ThemeProvider theme={updatedTheme}>
        <Helmet>
          <link href={value?.googleFontUrl} rel="stylesheet" />
        </Helmet>
        {children}
      </ThemeProvider>
    </BrandingContext.Provider>
  );
};

export const useBrandingContext = () => useContext(BrandingContext);
