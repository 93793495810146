import { createContext, useState, useEffect, FC, useContext } from "react";
import { auth, firestore } from "firebase-internal";
import LogRocket from "logrocket";
import { signInAnonymously } from "firebase/auth";
import { useUser } from "auth";
import { collection, getDocs, query, where } from "firebase/firestore";

interface AuthContextProps {
  isArtist: boolean;
}

export const AuthContext = createContext<AuthContextProps>(undefined);

export const AuthProvider: FC = ({ children }) => {
  const { isLoggedIn, user } = useUser();
  const [isArtist, setIsArtist] = useState<boolean | null>(null);

  if (!isLoggedIn) {
    signInAnonymously(auth);
  }

  useEffect(() => {
    if (user?.uid) {
      getDocs(
        query(
          collection(firestore, "nextsong_events"),
          where(`memberUids`, "array-contains", user?.uid)
        )
      )
        .then((response) => {
          setIsArtist(response.size > 0);
        })
        .catch(() => setIsArtist(false));
    }
  }, [user?.uid]);

  useEffect(() => {
    LogRocket.identify(user?.uid, {
      name: user?.displayName,
      email: user?.email,
      phone: user?.phoneNumber,
    });
  }, [user]);

  if (!user || isArtist === null) {
    return null;
  }

  const value: AuthContextProps = {
    isArtist,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
