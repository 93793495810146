import styled, { css } from "styled-components";
import { FlexColumn } from "notes";
import { BulletList, Container, H2 } from "../Components";
import { Video } from ".";

export const HowItWorks = ({
  videoPath,
  bullets,
}: {
  videoPath: string | string[];
  bullets?: object[];
}) => {
  return (
    <VideoSection>
      <Container>
        <VideoContainer forMobile>
          <Video path={videoPath} />
        </VideoContainer>
        <H2>How it Works</H2>
        <GridContainer>
          <BulletList items={bullets} />
          <VideoContainer forDesktop>
            <Video path={videoPath} />
          </VideoContainer>
        </GridContainer>
      </Container>
    </VideoSection>
  );
};

const VideoSection = styled(FlexColumn)`
  padding-top: 0;
  ${H2} {
    margin: 72px 0 40px 0;
  }
`;

const VideoContainer = styled(FlexColumn)`
  width: 50vw;
  max-width: 800px;
  margin: 40px auto 0 auto;
  ${(props) =>
    props.forMobile &&
    css`
      @media only screen and ${(props) => props.theme.media.medium} {
        display: none;
      }
    `};
  @media only screen and ${(props) => props.theme.media.small} {
    width: 100%;
    ${(props) =>
      props.forDesktop &&
      css`
        display: none;
      `};
  }
`;

const GridContainer = styled.div`
  box-sizing: border-box;
  display: grid;
  column-gap: 40px;
  grid-template-columns: 1fr;
  row-gap: 0px;
  justify-items: center;
  @media only screen and ${(props) => props.theme.media.medium} {
    grid-template-columns: 40% 60%;
  }
`;
