import { useState, useRef } from "react";
import styled, { css } from "styled-components";
import { FlexColumn, FlexRow } from "notes";
import {
  H4,
  Paragraph,
  useOnScreen,
  VideoBackground,
  Container as ContainerBase,
  H3,
  GradientButton,
  useAboutContext,
} from ".";

import { ReactComponent as IconArrowLeft } from "../assets/icon-arrow_left.svg";
import { ReactComponent as IconArrowRight } from "../assets/icon-arrow_right.svg";
import imagePhone from "../assets/overlay-phone-notchless.png";

interface PhoneGalleryProps {
  steps: {
    media: { type: string; src: string | string[] };
    title: string;
    content: string;
  }[];
}

export const PhoneGallery = ({ steps, ...props }: PhoneGalleryProps) => {
  const { setScheduleModalOpen } = useAboutContext();
  const [index, setIndex] = useState(0);

  const videoRef = useRef();
  const isVideoVisible = useOnScreen(videoRef);

  const handleModalAction = () => {
    setScheduleModalOpen(true);
  };

  const handlePrevious = () => {
    setIndex(index === 0 ? steps.length - 1 : index - 1);
  };
  const handleNext = () => {
    setIndex(index === steps.length - 1 ? 0 : index + 1);
  };

  return (
    <Container columns={2} {...props}>
      <SlideImage ref={videoRef}>
        <ArrowLeft alt="Previous" onClick={handlePrevious} hlarge />
        <OverlayPhone alt="Phone Display" src={imagePhone} />
        <ArrowRight alt="Next" onClick={handleNext} right hlarge />
        {steps[index].media.type === "video" ? (
          <MediaContainer>
            <VideoBackground
              path={steps[index].media.src}
              play={isVideoVisible}
              loop
            />
          </MediaContainer>
        ) : (
          <SlideBackgroundImage imageSrc={steps[index].media.src} />
        )}
      </SlideImage>
      <SlideContent>
        <SlideText>
          <H4>{steps[index].title}</H4>
          <Paragraph>{steps[index].content}</Paragraph>
        </SlideText>
        <NavigationContainer>
          <ArrowLeft alt="Previous" onClick={handlePrevious} hsmall />
          <SlideIncrement>
            {index + 1}/{steps.length}
          </SlideIncrement>
          <ArrowRight alt="Next" onClick={handleNext} hsmall />
        </NavigationContainer>
        <GradientButton onClick={handleModalAction}>
          Schedule Demo
        </GradientButton>
      </SlideContent>
    </Container>
  );
};

const Container = styled(ContainerBase)`
  display: grid;
  @media only screen and ${(props) => props.theme.media.small} {
    align-items: center;
    justify-items: center;
    grid-template-columns: 1fr;
    row-gap: 2em;
    margin-left: auto;
    margin-right: auto;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    padding-top: min(5.63vw, 72px);
    column-gap: 80px;
    grid-template-columns: 1fr 1fr;
  }
`;

const SlideIncrement = styled(H3)`
  font-weight: 300;
  letter-spacing: 6px;
  margin: 0;
  text-align: center;
  width: min(6.8vw, 76px);
  @media only screen and ${(props) => props.theme.media.small} {
    width: 100%;
  }
`;

const SlideContent = styled(FlexColumn)`
  margin-left: 2vw;
  align-items: flex-start;
  justify-content: center;

  @media only screen and ${(props) => props.theme.media.small} {
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
`;

const SlideText = styled(FlexColumn)`
  text-align: left;
  min-height: min(12.11vw, 155px);
  width: 100%;
  max-width: 392px;
  @media only screen and ${(props) => props.theme.media.small} {
    text-align: center;
    width: 80%;
    margin-left: 12vw;
    margin-right: 12vw;
    p {
      min-height: 100px;
    }
  }
`;

const NavigationContainer = styled(FlexRow)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5vw;
  margin-bottom: 4vw;
  h3 {
    font-weight: 300;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    justify-content: center;
    margin-top: min(6.4vw, 24px);
    margin-bottom: min(10.67vw, 40px);
    h3 {
      width: 100%;
    }
  }
`;

const ArrowLeft = styled<any>(IconArrowLeft)`
  cursor: pointer;
  width: 20%;
  max-width: 98px;
  user-select: none;
  path {
    fill: #222222;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    ${(props) =>
      props.hsmall &&
      css`
        display: none;
      `};
    position: absolute;
    width: min(10.67vw, 40px);
    left: -15.47vw;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    ${(props) =>
      props.hlarge &&
      css`
        display: none;
      `};
  }
`;

const ArrowRight = styled<any>(IconArrowRight)`
  cursor: pointer;
  width: 20%;
  max-width: 98px;
  user-select: none;
  path {
    fill: #222222;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    ${(props) =>
      props.hsmall &&
      css`
        display: none;
      `};
    position: absolute;
    width: min(10.67vw, 40px);
    right: -15.47vw;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    ${(props) =>
      props.hlarge &&
      css`
        display: none;
      `};
  }
`;

const OverlayPhone = styled.img`
  position: relative;
  width: 100%;
  z-index: 2;
`;

const MediaContainer = styled.div`
  background: #222222;
  background-size: cover;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 4.8vw;
  box-shadow: 0px 15px 89px #ffffff3b;
  overflow: hidden;
  video {
    object-fit: cover;
  }
  @media only screen and ${(props) => props.theme.media.desktop} {
    border-radius: 68px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    border-radius: 36px;
  }
`;

const SlideBackgroundImage = styled<any>(MediaContainer)`
  background: url(${(props) => props.imageSrc}), #222222;
  background-size: cover;
`;

const SlideImage = styled(FlexColumn)`
  width: 100%;
  max-width: 300px;
  align-items: center;
  justify-self: flex-end;
  position: relative;
  @media only screen and ${(props) => props.theme.media.small} {
    width: min(54.13vw, 203px);
    justify-self: center;
    justify-content: center;
  }
`;
