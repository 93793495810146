import styled, { css } from "styled-components";
import {
  useArtistEventContext,
  useArtistModalContext,
  Toggle,
} from "../Components";
import {
  FlexColumn,
  Label,
  Input,
  Button,
  H4,
  P,
  FlexRow,
  Icon,
  Small,
  Link,
  FormMessage,
  Footnote,
} from "notes";
import { InfoCard } from "Components";
import { useState } from "react";
import { MapIcon } from "Images";
import { updateFirestoreDoc } from "firebase-internal";
import { deleteField } from "firebase/firestore";

interface SweepsConfig {
  numberOfWinners: number;
  limitToVenue: boolean;
}

export const Sweeps = () => {
  const { event, proximityLimit, entries: requests } = useArtistEventContext();
  const { setModal } = useArtistModalContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [sweepsConfig, setSweepsConfig] = useState<SweepsConfig>({
    numberOfWinners: 1,
    limitToVenue: false,
  });

  const entryWinnerHashes = event?.sweeps?.entryWinnerHashes ?? [];
  const entryWinnerUids = event?.sweeps?.entryWinnerUids ?? [];
  const ignoreList = event?.sweeps?.entryIgnoreUids ?? [];
  const limitToVenue = event?.sweeps?.limitToVenue;

  const updateConfig = (value, name) => {
    setSweepsConfig({ ...sweepsConfig, [name]: value });
  };

  const allEntries = requests?.map((req) => req._id);
  const entriesByProximity = requests
    ?.filter((req) => req.isInVenue)
    .map((req) => req._id);

  const entries =
    limitToVenue || sweepsConfig?.limitToVenue
      ? entriesByProximity
      : allEntries;

  const entryWinners = requests
    ?.filter(({ _id }) => entryWinnerHashes?.includes(_id))
    .sort(
      (a, b) =>
        entryWinnerHashes.indexOf(a._id) - entryWinnerHashes.indexOf(b._id)
    );

  const createNumberArray = (quantity: number, entryIds?: string[]) => {
    const length = entryIds?.length;
    let numberArray = [];
    if (quantity > length) {
      return [];
    }
    while (numberArray.length < quantity) {
      let r: number = Math.floor(Math.random() * length);
      if (
        numberArray.indexOf(r) === -1 &&
        !entryWinnerHashes?.includes(entryIds[r])
      )
        numberArray.push(r);
    }
    return numberArray;
  };

  const handleSelectEntries = () => {
    setLoading(true);
    const numberArray = createNumberArray(
      sweepsConfig?.numberOfWinners,
      entries
    );
    if (!numberArray?.length) {
      setError(true);
      return;
    }
    const entryWinnerHashes = numberArray.map(
      (entryIndex) => entries[entryIndex]
    );
    const numWinners =
      typeof sweepsConfig?.numberOfWinners === "string"
        ? parseInt(sweepsConfig?.numberOfWinners)
        : sweepsConfig?.numberOfWinners;
    updateFirestoreDoc(`nextsong_events/${event._id}`, {
      sweeps: {
        ...event?.sweeps,
        limitToVenue: sweepsConfig?.limitToVenue,
        numberOfWinners: numWinners,
        entryWinnerHashes,
      },
    }).then(() => setLoading(false));
  };

  const handleReplaceEntry = (replaceUid: string) => {
    const qualifiedEntryIds = entries?.filter(
      (entId) =>
        !ignoreList?.includes(entId) && !entryWinnerHashes?.includes(entId)
    );
    const numberArray = createNumberArray(1, qualifiedEntryIds);
    if (!numberArray?.length) {
      setError(true);
      return;
    }
    const newWinnerHash = qualifiedEntryIds[numberArray[0]];
    const replaceIndex = entryWinnerHashes.indexOf(replaceUid);
    const updatedEntryWinnerHashes = entryWinnerHashes.map((hash, index) =>
      index === replaceIndex ? newWinnerHash : hash
    );
    updateFirestoreDoc(`nextsong_events/${event._id}`, {
      "sweeps.entryWinnerHashes": updatedEntryWinnerHashes,
    });
  };

  const handleRemoveEntry = (entryId: string) => {
    const updatedIgnore = ignoreList.concat([entryId]);
    updateFirestoreDoc(`nextsong_events/${event._id}`, {
      "sweeps.entryIgnoreUids": updatedIgnore,
    });
  };

  const handleResetSweeps = () => {
    updateFirestoreDoc(`nextsong_events/${event._id}`, {
      "sweeps.entryWinnerUids": deleteField(),
      "sweeps.entryWinnerHashes": deleteField(),
      "sweeps.entryIgnoreUids": deleteField(),
    });
  };

  const handleNotifyWinners = () => {
    const winnerUids = entryWinners?.map(({ uid }) => uid);
    updateFirestoreDoc(`nextsong_events/${event._id}`, {
      "sweeps.entryWinnerUids": winnerUids,
    });
  };

  return (
    <Wrapper yStart>
      {error && (
        <FormMessage
          style={{ marginBottom: "16px" }}
          content={
            <>
              No more qualified entries available. Would you like to reset
              ignored entires?{" "}
              <Link
                onClick={() => {
                  updateFirestoreDoc(`nextsong_events/${event._id}`, {
                    "sweeps.entryIgnoreUids": [],
                  }).then(() => setError(false));
                }}
                style={{ marginTop: "8px", textDecoration: "underline" }}
              >
                Reset Ignored Entries
              </Link>
            </>
          }
        />
      )}
      {!entryWinnerHashes?.length && (
        <FlexColumn xStart>
          <Title>
            <H4>Choose Sweeps Winners</H4>
          </Title>
          <Row>
            <FlexColumn>
              <Label>Limit by proximity</Label>
              <Caption>
                {!!sweepsConfig?.limitToVenue
                  ? `Within ${(proximityLimit * 1760)?.toFixed(2)} yards`
                  : "Disabled"}
              </Caption>
            </FlexColumn>
            <Toggle
              checked={!!sweepsConfig?.limitToVenue}
              onChange={(value) => updateConfig(value, "limitToVenue")}
            />
          </Row>
          <Row>
            <FlexColumn>
              <Label>Number of winners</Label>
              <Caption>
                {!!entries?.length
                  ? `Out of ${entries?.length} eligible entries`
                  : "No eligible entries"}
              </Caption>
            </FlexColumn>

            <FlexRow>
              <SquareButton
                disabled={sweepsConfig.numberOfWinners <= 1}
                type="tertiary"
                onClick={() => {
                  const num = --sweepsConfig.numberOfWinners;
                  if (num >= 1) {
                    updateConfig(num, "numberOfWinners");
                  }
                }}
              >
                <Icon tertiary name="Subtract" />
              </SquareButton>
              <Input
                value={sweepsConfig?.numberOfWinners}
                onChange={(value) => updateConfig(value, "numberOfWinners")}
                style={{ width: "56px", flexGrow: 0, marginLeft: "8px" }}
              />
              <SquareButton
                disabled={sweepsConfig.numberOfWinners >= entries?.length}
                type="tertiary"
                onClick={() => {
                  const num = ++sweepsConfig.numberOfWinners;
                  if (num <= entries?.length) {
                    updateConfig(num, "numberOfWinners");
                  }
                }}
              >
                <Icon tertiary name="Add" />
              </SquareButton>
            </FlexRow>
          </Row>
        </FlexColumn>
      )}
      {!entryWinnerHashes?.length ? (
        <Button
          disabled={!(sweepsConfig?.numberOfWinners > 0) || !entries?.length}
          loading={loading}
          onClick={() => handleSelectEntries()}
          style={{ marginTop: "24px" }}
        >
          {!entries?.length ? (
            "No eligible entries"
          ) : (
            <>
              Find {sweepsConfig?.numberOfWinners} Winner
              {sweepsConfig?.numberOfWinners === 1 ? "" : "s"}
            </>
          )}
        </Button>
      ) : (
        <FlexColumn>
          <Title>
            <H4>Sweeps Winners</H4>
            {!entryWinnerUids?.length && (
              <ButtonThin
                onClick={() =>
                  setModal({
                    confirm: {
                      title: `Are you sure you want to notify all winners?`,
                      action: () => handleNotifyWinners(),
                    },
                  })
                }
                type="link"
                iconLeft={<Icon tertiary name="Bell" />}
              >
                Notify
              </ButtonThin>
            )}
            <ButtonThin
              onClick={() =>
                setModal({
                  confirm: {
                    title: `Are you sure you want to reset ignored and winning entries?`,
                    action: () => handleResetSweeps(),
                  },
                })
              }
              type="destroy"
              iconLeft={<Icon tertiary name="Refresh" />}
            >
              Reset
            </ButtonThin>
          </Title>
          {entryWinners?.map((entry, index) =>
            ignoreList?.includes(entry?._id) ? (
              <EntrySelection isPlaceholder key={index}>
                <FlexRow flex="0 0 38px" yStart xEnd>
                  <Button
                    onClick={() => handleReplaceEntry(entry?._id)}
                    style={{
                      minWidth: "80px",
                      width: "auto",
                    }}
                  >
                    Replace
                  </Button>
                </FlexRow>
              </EntrySelection>
            ) : (
              <EntrySelection key={entry._id}>
                <FlexRow>
                  <FlexRow flex="1 1 100%">
                    <P>
                      {index < 10 ? "0" : ""}
                      {index + 1}.
                    </P>
                    <H4>
                      {entry.name}
                      {entry.isInVenue && <MapIcon />}
                    </H4>
                  </FlexRow>
                </FlexRow>
                <FlexRow style={{ marginLeft: "30px" }}>
                  {(entry.location || entry.zipcode) &&
                    (entry.location ? (
                      <Small>from {entry.location}</Small>
                    ) : (
                      <Small>from zip code {entry.zipcode}</Small>
                    ))}
                </FlexRow>
                <MoreButton
                  onClick={() =>
                    setModal({
                      winnerDetail: {
                        ...entry,
                        remove: {
                          title: `Are you sure you want to remove ${entry.name}?`,
                          action: () => handleRemoveEntry(entry._id),
                        },
                      },
                    })
                  }
                  platform
                  name="More"
                />
              </EntrySelection>
            )
          )}
        </FlexColumn>
      )}
    </Wrapper>
  );
};

export const ButtonThin = styled(Button)`
  border: none;
  min-width: initial;
  padding-top: 2px;
  padding-bottom: 2px;
  &:not(:disabled):hover,
  &:not(:disabled):focus {
    border: none;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  margin-top: -4px;
  & + & {
    margin-left: 8px;
  }
`;

const Caption = styled(Footnote)`
  color: ${(props) => props.theme.palette.gray.primary}cc;
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
`;

const Row = styled(FlexRow)`
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.lightest};
  padding: 11px 0 11px 12px;
  justify-content: space-between;
  width: 100%;
  position: relative;
`;

const SquareButton = styled(Button)`
  min-width: 40px;
  height: 40px;
  padding: 0;
  margin-left: 8px;
  color: ${(props) => props.theme.palette.gray.primary};
  &:not(:disabled):hover,
  &:not(:disabled):focus {
    border: 1px solid ${(props) => props.theme.palette.gray.primary};
    box-shadow: none;
  }
`;

const EntrySelection = styled(InfoCard)`
  padding: 12px;
  position: relative;
  justify-content: center;
  ${H4} {
    font-weight: 600;
    margin-left: 6px;
    svg {
      color: ${(props) => props.theme.palette.red.primary};
      margin-left: 6px;
      margin-bottom: -1px;
      width: 16px;
      height: 16px;
    }
  }
  ${P} {
    font-weight: 400;
    text-align: right;
    width: 24px;
  }
  & + & {
    margin-top: 8px;
  }
  ${(props) =>
    props.isPlaceholder &&
    css`
      background: ${(props) => props.theme.palette.gray.lightest};
      box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    `};
`;

export const Title = styled(FlexRow)`
  margin-bottom: 16px;
  display: flex;
  ${H4} {
    flex-grow: 1;
    font-weight: 700;
  }
`;

const Wrapper = styled(FlexColumn)`
  background: #f7fafc;
  padding: 16px 16px 68px 16px;
  flex-grow: 1;
  width: 100%;
  max-width: 600px;
  ${Label} {
    color: ${(props) => props.theme.palette.gray.primary};
    font-weight: 600;
  }
  input {
    text-align: center;
  }
`;

const MoreButton = styled(Icon)`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  path {
    fill: ${(props) => props.theme.palette.gray.primary};
  }
  position: absolute;
  right: 16px;
`;
