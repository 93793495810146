import { FC } from "react";
import styled from "styled-components";
import { FlexColumn, H4, FlexRow, Footnote, Icon, P, Small } from "notes";

import type { UpdatedEventSongModel } from "../../Components";

export const ItemContentVote: FC<{ song: UpdatedEventSongModel }> = ({
  song,
}) => {
  const { title, artist, accumAmount, uniqueNames, _id } = song;
  return (
    <Wrapper flex="1 1 100%">
      <Row yStart>
        <FlexColumn flex="1 1 100%">
          <H4>{title}</H4>
          <Small>{artist}</Small>
        </FlexColumn>
        {accumAmount > 0 && (
          <>
            <IconTeal form name="Charity" />
            <P>${accumAmount / 100}</P>
          </>
        )}
      </Row>
      <Row yCenter>
        {!!uniqueNames?.length && (
          <>
            <IconGray form name="Audience" />
            {uniqueNames?.length > 2 ? (
              <Footnote>
                Requested by{" "}
                {uniqueNames
                  ?.map((displayName, index) => (
                    <span key={`${_id}-${index}`}>{displayName}, </span>
                  ))
                  .splice(0, 2)}
                and <span>{uniqueNames.length - 2} other</span>
                {uniqueNames.length - 2 > 1 ? "s" : ""}
              </Footnote>
            ) : (
              <Footnote>
                Requested by{" "}
                {uniqueNames?.map((displayName, index) =>
                  index === uniqueNames.length - 1 ? (
                    <span key={`${_id}-${index}`}>{displayName}</span>
                  ) : (
                    <>
                      <span key={`${_id}-${index}`}>{displayName}</span> and{" "}
                    </>
                  )
                )}
              </Footnote>
            )}
          </>
        )}
      </Row>
    </Wrapper>
  );
};

const Row = styled(FlexRow)`
  ${P} {
    color: ${(props) => props.theme.palette.teal.primary};
    font-size: 15px;
    font-weight: 700;
    line-height: 19px;
  }
`;

const Wrapper = styled(FlexColumn)`
  ${H4} {
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
  }
  ${Footnote}, ${Small} {
    color: ${(props) => props.theme.palette.gray.primary};
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
  }
  ${Small} {
    margin-bottom: 7px;
  }
  ${Footnote} {
    span {
      font-weight: 600;
    }
  }
`;

const IconTeal = styled(Icon)`
  color: ${(props) => props.theme.palette.teal.primary};
  margin-right: 8px;
`;

const IconGray = styled(Icon)`
  color: ${(props) => props.theme.palette.gray.lighter};
  margin-right: 8px;
`;

const Spacer = styled(FlexColumn)`
  width: 16px;
  flex-shrink: 0;
`;
