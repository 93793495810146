import styled from "styled-components";
import { FlexColumn, Loader } from "notes";
import { Header } from "Components";
import {
  Banner,
  Navigation,
  ShowSupport,
  FanEventProvider,
  useFanEventContext,
  FanModalProvider,
} from "./Components";
import { TimeProvider } from "Context";
import * as nextsong from "@musicaudienceexchange/nextsong-interface";
import type { DocumentModel } from "Hooks/models";
import { Playlist } from "./Playlist";
import { Requests } from "./Requests";
import { SponsorList } from "./SponsorList";
import { Lobby } from "./Lobby";
import { Encore } from "./Encore";
import { CustomTab } from "./CustomTab";
import { PostShow } from "./PostShow";
import { CheckIn } from "./CheckIn";
import { FreeRequest } from "./FreeRequest";
import { Charity } from "./Charity";

export type EventModel = DocumentModel<nextsong.firestore.Event>;

export const FanEvent = (props) => {
  const eventId = props?.match?.params?.eventId;
  return (
    <TimeProvider>
      <FanEventProvider eventId={eventId}>
        <FanModalProvider>
          <EventComponent {...props} />
        </FanModalProvider>
      </FanEventProvider>
    </TimeProvider>
  );
};

export const EventComponent = (props) => {
  const { event, eventLoading, view } = useFanEventContext();
  const showConfirm = props?.location?.state?.showConfirm;
  const isSponsorEventWithEncore =
    event?.type === "sponsor" && !!Object.keys(event?.encore ?? {})?.length;

  if (eventLoading) {
    return <Loader />;
  }

  if (showConfirm) {
    return <Lobby />;
  }

  if (!!event["endedAt"] || event?.type === "donate") {
    <Wrapper>
      <Header secondary showExitLink />
      <Banner />
      <ShowSupport layoutColumn />
      <PostShow />
    </Wrapper>;
  }

  return (
    <Wrapper>
      <Header secondary showExitLink />
      <Banner />
      <ShowSupport />
      <Navigation />
      <Content>
        {view === "requests" && <Requests />}
        {view === "playlist" && <Playlist />}
        {view === "freeRequest" && <FreeRequest />}
        {view === "sponsor" && (
          <SponsorList
            style={{ marginTop: !isSponsorEventWithEncore ? "-32px" : "0" }}
          />
        )}
        {view === "encore" && <Encore />}
        {view === "html" && <CustomTab />}
        {view === "checkIn" && <CheckIn />}
        {view === "charity" && <Charity />}
      </Content>
    </Wrapper>
  );
};

const Content = styled(FlexColumn)`
  flex-grow: 1;
  width: 100%;
  max-width: 600px;
`;

const Wrapper = styled(FlexColumn)`
  align-items: center;
  background-color: ${(props) => props.theme.branding.event.colors.page};
  flex-grow: 1;
`;
