import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FlexColumn } from "notes";

export const Map: React.FC<any> = ({ center }) => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const centerCoords = center
    ? {
        lat: center?.latitude,
        lng: center?.longitude,
      }
    : undefined;

  const parameters = {
    center: `${centerCoords.lat},${centerCoords.lng}`,
    size: `${width > 600 ? 600 : width}x128`,
    scale: "2",
    zoom: "15",
    key: process.env.REACT_APP_FIREBASE_APIKEY,
  };

  const queryString = Object.keys(parameters)
    .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(parameters[k]))
    .join("&");

  const staticMapApi = `https://maps.googleapis.com/maps/api/staticmap?${queryString}`;

  return <Container flex="0 0 128px" image={staticMapApi} />;
};

const Container = styled(FlexColumn)`
  background: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  width: 100%;
  height: 128px;
  overflow: hidden;
  align-items: center;
  @media only screen and ${(props) => props.theme.media.medium} {
    max-width: 600px;
    border-radius: 0 0 12px 12px;
  }
`;
