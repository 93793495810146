import styled from "styled-components";
import { FlexColumn, Loader } from "notes";
import { Header } from "Components";
import { TimeProvider } from "Context";
import * as nextsong from "@musicaudienceexchange/nextsong-interface";
import {
  useArtistEventContext,
  ArtistEventProvider,
  Banner,
  Navigation,
} from "../Components";
import { Support } from "./Support";
import { Dashboard } from "./Dashboard";
import type { DocumentModel } from "Hooks/models";
import { SetList } from "./SetList";
import { Encore } from "./Encore";
import { Leads } from "./Leads";
import { Sweeps } from "./Sweeps";
export type EventModel = DocumentModel<nextsong.firestore.Event>;

export const ArtistEvent = (props) => {
  const eventId = props?.match?.params?.eventId;
  return (
    <TimeProvider>
      <ArtistEventProvider eventId={eventId}>
        <EventComponent {...props} />
      </ArtistEventProvider>
    </TimeProvider>
  );
};

export const EventComponent = () => {
  const { eventLoading, view } = useArtistEventContext();

  if (eventLoading) {
    return <Loader />;
  }

  return (
    <ScrollWrapper>
      <FlexColumn style={{ position: "relative" }}>
        <Header secondary isArtist hideLogo />
        <Banner />
      </FlexColumn>
      <Content>
        {view === "requests" && <SetList />}
        {view === "encore" && <Encore />}
        {view === "support" && <Support />}
        {view === "leads" && <Leads />}
        {view === "sweeps" && <Sweeps />}
        {view === "dashboard" && <Dashboard />}
      </Content>
      <Navigation />
    </ScrollWrapper>
  );
};

const Content = styled(FlexColumn)`
  width: 100%;
  flex-grow: 1;
  overflow-y: scroll;
  @media only screen and ${(props) => props.theme.media.medium} {
    align-items: center;
    align-self: center;
  }
`;

const ScrollWrapper = styled(FlexColumn)`
  background: #f7fafc;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
`;
