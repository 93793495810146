import styled, { css } from "styled-components";
import { FlexColumn, FlexRow, H4, Small, P, FormMessage } from "notes";
import { useArtistEventContext } from "../Components";
import {
  PercentageBar,
  PercentageWrapper as PercentageWrapperBase,
} from "../../Fan/Requests";
import { useTimeContext } from "Context";
import { DateTime, Duration } from "luxon";

export const Encore = () => {
  const { playlist, encorePaymentAllowed, event } = useArtistEventContext();
  const { time } = useTimeContext();

  const songs = playlist?.filter(
    ({ encore, playedAt }) => !!encore && !playedAt?.length
  );

  const compareSongPriority = (a, b) => {
    const valueA = encorePaymentAllowed ? a.accumAmount : a.requests?.length;
    const valueB = encorePaymentAllowed ? b.accumAmount : b.requests?.length;
    return valueB - valueA;
  };

  const topVoteRequestAccumAmount = Math.max.apply(
    Math,
    songs?.map((item) => item.accumAmount)
  );
  const sortedSongs = songs?.sort((a, b) => compareSongPriority(a, b));
  const encoreEnd: DateTime = event?.startsAt?.plus({
    minutes: event?.encore?.endOffset,
  });
  const isEncoreEnded = time > encoreEnd;
  const timeRemaining = Duration.fromMillis(
    encoreEnd?.toMillis() - time?.toMillis()
  ).shiftTo("hours", "minutes", "seconds", "milliseconds");
  const encoreRequestsMax = Math.max.apply(
    Math,
    sortedSongs?.map((item) => item.requests?.length ?? 0)
  );
  return (
    <Wrapper>
      <MessageContainer encoreEnd={isEncoreEnded}>
        {!isEncoreEnded ? (
          <FormMessage
            style={{ width: "auto" }}
            type={timeRemaining?.minutes > 1 ? "info" : "error"}
            active
            content={
              <FlexRow style={{ fontWeight: 600, width: "100%" }}>
                <H4>Time Remaining:</H4>
                {timeRemaining?.hours > 1 ? (
                  <>
                    <span>{timeRemaining?.hours}</span>
                    hr{timeRemaining?.hours !== 1 ? "s" : ""}.
                    <span>{timeRemaining?.minutes}</span>
                    min.
                  </>
                ) : (
                  <>
                    <span>{timeRemaining?.minutes}</span>
                    min.
                    <span>{timeRemaining?.seconds}</span>sec.
                  </>
                )}
              </FlexRow>
            }
          />
        ) : (
          <H4>Encore Ended</H4>
        )}
      </MessageContainer>
      {sortedSongs?.map(
        ({ title, artist, accumAmount, requests, _id }, index) => {
          const amount = encorePaymentAllowed
            ? accumAmount
            : requests?.length ?? 0;
          const total = encorePaymentAllowed
            ? topVoteRequestAccumAmount
            : encoreRequestsMax;
          const calcPercent = (amount / total) * 100;
          return (
            <SongContainer key={_id}>
              <FlexRow>
                <FlexRow flex="1 1 100%" xStart yStart>
                  <Order>
                    <P>
                      {index < 9 && "0"}
                      {index + 1}.
                    </P>
                  </Order>
                  <FlexColumn>
                    <H4>{title}</H4>
                    <Small>{artist}</Small>
                  </FlexColumn>
                </FlexRow>
                {(!!accumAmount || !!amount) && (
                  <H4>
                    {encorePaymentAllowed ? `$${accumAmount / 100}` : amount}
                  </H4>
                )}
              </FlexRow>
              <PercentageWrapper style={{ margin: "8px 0 0 0" }}>
                <PercentageBar percent={calcPercent} />
              </PercentageWrapper>
            </SongContainer>
          );
        }
      )}
    </Wrapper>
  );
};

const MessageContainer = styled(FlexColumn)`
  & > div > div {
    width: 100%;
  }
  padding: 8px 16px;
  ${H4} {
    font-weight: 600;
  }
  ${(props) =>
    props.encoreEnd &&
    css`
      margin-top: -8px;
      margin-bottom: 8px;
      background: ${(props) => props.theme.palette.red.primary};
      align-items: center;
      ${H4} {
        color: #ffffff;
      }
    `};
  font-weight: 600;
  span {
    margin-left: 8px;
    margin-right: 4px;
    display: inline-block;
    width: 24px;
    text-align: right;
  }
`;

const Order = styled(FlexRow)`
  width: 26px;
  flex-shrink: 0;
  ${P} {
    font-size: 15px;
    font-weight: 600;
  }
`;

const PercentageWrapper = styled(PercentageWrapperBase)`
  margin-top: 8px;
  background: ${(props) => props.theme.palette.gray.lighter}50;
`;

const Wrapper = styled(FlexColumn)`
  background: #f7fafc;
  padding: 8px 0 68px 0;
  flex-grow: 1;
  width: 100%;
  max-width: 600px;
`;

const SongContainer = styled(FlexColumn)`
  padding: 16px 24px 16px 8px;
  margin-left: 16px;
  & + & {
    border-top: 1px solid ${(props) => props.theme.palette.gray.lighter}aa;
  }
`;
