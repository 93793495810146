import styled from "styled-components";
import { FlexColumn } from "notes";
import { GradientButton, Paragraph, useAboutContext } from ".";

export const BulletList = ({ items, ...props }) => {
  const { setScheduleModalOpen } = useAboutContext();
  return (
    <Wrapper {...props}>
      {items.map((value, index) => (
        <FlexColumn key={index}>
          <H3>{value.title}</H3>
          <Paragraph>{value.content}</Paragraph>
        </FlexColumn>
      ))}
      <GradientButton onClick={() => setScheduleModalOpen(true)}>
        Schedule Demo
      </GradientButton>
    </Wrapper>
  );
};

const H3 = styled.h3`
  font-size: min(5.87vw, 22px);
  font-weight: 300;
  line-height: 1.2em;
  margin-bottom: 1vw;
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: min(2.34vw, 30px);
  }
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: 30px;
  }
`;

const Wrapper = styled(FlexColumn)`
  text-align: left;
  align-items: flex-start;
  h3 {
    font-weight: 600;
    width: 65%;
    margin-bottom: 0;
  }
  p {
    width: 80%;
    margin-top: 0.5vw;
    margin-bottom: 2em;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    margin-left: 5vw;
    margin-right: 5vw;
    text-align: left;
    h3 {
      width: 70%;
    }
    p {
      width: 100%;
    }
    button {
      align-self: center;
    }
  }
`;
