import React, { useEffect, useState, useCallback, FC, useMemo } from "react";
import styled from "styled-components";
import {
  useStripe,
  PaymentRequestButtonElement,
  CardElement,
  useElements,
} from "@stripe/react-stripe-js";
import type {
  ConfirmCardPaymentData,
  PaymentRequestPaymentMethodEvent,
  StripePaymentRequestButtonElementClickEvent,
  PaymentIntent,
  PaymentRequest,
  StripeError,
} from "@stripe/stripe-js";

import { FlexColumn, Small } from "notes";
import { Button } from "Components";

import * as nextsong from "@musicaudienceexchange/nextsong-interface";
import { isValid, getErrors } from "@musicaudienceexchange/toccata";
import { functions } from "firebase-internal";
import { httpsCallable } from "firebase/functions";

//export const PaymentFields = ['card', 'name', 'email'] as const;
export type PaymentField = keyof nextsong.functions.Payment | "paymentMethod";

export interface PaymentError extends StripeError {
  fields?: PaymentField[];
}

export interface PaymentProps {
  /**
   * The Payment object
   */
  payment?: nextsong.functions.Payment;

  /**
   * Either the card element or payment method ID.
   */
  paymentMethod?: ConfirmCardPaymentData["payment_method"];

  /**
   * Called when an error is encountered
   */
  onPaymentError?: (error: PaymentError) => void;

  /**
   * Called when the payment is completed
   */
  onPaymentComplete?: () => void;

  /**
   * Called when payment is being processed
   */
  onPaymentProcessing?: (isProcessing: boolean) => void;
}

interface PaymentCleanupProps extends PaymentProps {
  setLoading: (s: boolean) => unknown;
}

const paymentCleanup = (props: PaymentCleanupProps, error?: PaymentError) => {
  const { setLoading } = props;

  const onPaymentProcessing =
    typeof props.onPaymentProcessing === "function"
      ? props.onPaymentProcessing
      : () => {};
  const onPaymentComplete =
    typeof props.onPaymentComplete === "function"
      ? props.onPaymentComplete
      : () => {};
  const onPaymentError =
    typeof props.onPaymentError === "function"
      ? props.onPaymentError
      : () => {};

  setLoading(false);
  onPaymentProcessing(false);

  if (error) {
    onPaymentError(error);
  } else {
    onPaymentComplete();
  }
};

export interface PayButtonProps
  extends PaymentProps,
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    > {
  loading?: boolean;
}

export const PayButton = React.forwardRef<HTMLButtonElement, PayButtonProps>(
  (props, ref) => {
    const {
      payment,
      paymentMethod,
      children,
      onPaymentError,
      onPaymentComplete,
      onPaymentProcessing,
      onClick,
      ...rest
    } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const payments = usePayments();
    const elements = useElements();

    const handlePayment = useCallback(async () => {
      const handlers = {
        onPaymentError,
        onPaymentComplete,
        onPaymentProcessing,
        setLoading,
      };

      setLoading(true);

      if (typeof onPaymentProcessing === "function") {
        onPaymentProcessing(true);
      }

      const card =
        typeof paymentMethod === "object" && paymentMethod?.card
          ? paymentMethod?.card
          : elements?.getElement(CardElement);

      if (payment.amount > 0) {
        if (!card && !paymentMethod) {
          console.error("Couldn't get payment method");

          return paymentCleanup(handlers, {
            type: "validation_error",
            fields: ["paymentMethod"],
          });
        }
      }

      const errors = payments.validate(payment);
      if (errors.length > 0) {
        const fields: PaymentField[] = [];
        errors.forEach((error) => {
          if (error.property === "name") {
            fields.push("name");
          } else if (error.property === "email") {
            fields.push("email");
          }
        });

        return paymentCleanup(handlers, {
          type: "validation_error",
          fields: fields,
        });
      }

      const { success, error }: { success?: boolean; error?: any } =
        await payments
          .init(payment)
          .then(async ({ id, secret, error }) => {
            if (secret) {
              return await payments
                .confirm(secret, paymentMethod || { card })
                .then((success) => ({ success }))
                .catch((error) => ({ error }));
            }

            if (payment.amount === 0 && id) {
              return { success: true };
            }

            return { error };
          })
          .catch((error) => ({ success: false, error }));

      if (success) {
        return paymentCleanup(handlers);
      }

      return paymentCleanup(handlers, error);
    }, [
      payment,
      setLoading,
      paymentMethod,
      payments,
      onPaymentError,
      onPaymentComplete,
      onPaymentProcessing,
      elements,
    ]);

    return (
      <Button
        ref={ref}
        disabled={!!loading}
        loading={loading}
        onClick={handlePayment}
        {...rest}
      >
        {props.children}
      </Button>
    );
  }
);

const elementOptions: any = {
  style: {
    paymentRequestButton: {
      type: "default",
      theme: "dark",
      height: "40px",
    },
  },
};

export interface PaymentRequestButtonProps
  extends Omit<PaymentProps, "paymentMethod"> {
  disabled?: boolean;
}

export const PaymentRequestButton: FC<PaymentRequestButtonProps> = ({
  payment,
  onPaymentError,
  onPaymentComplete,
  onPaymentProcessing,
  disabled,
}) => {
  const stripe = useStripe();
  const payments = usePayments();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();

  useEffect(() => {
    //console.log("PaymentRequestButton:useEffect");

    if (paymentRequest) {
      paymentRequest.update({
        total: {
          label: payment?.type || "",
          amount: payment?.amount || 100,
        },
      });

      return;
    }

    if (stripe && payment?.amount) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: payment.type,
          amount: payment.amount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          //console.log("setPaymentRequest", pr);
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe, payment.amount, payment.type, paymentRequest]);

  useEffect(() => {
    if (!payments || !paymentRequest) {
      return;
    }

    paymentRequest.off("paymentmethod");

    paymentRequest.on("paymentmethod", async (ev) => {
      const handlers = {
        onPaymentError,
        onPaymentComplete,
        onPaymentProcessing,
        setLoading: () => {},
      };

      if (typeof onPaymentProcessing === "function") {
        onPaymentProcessing(true);
      }

      if (!payment.email) {
        payment.email = ev.payerEmail;
      }

      if (!payment.name) {
        payment.name = ev.payerName;
      }

      const errors = payments.validate(payment);
      if (errors.length > 0) {
        const fields: PaymentField[] = [];
        errors.forEach((error) => {
          if (error.property === "name") {
            fields.push("name");
          } else if (error.property === "email") {
            fields.push("email");
          }
        });

        ev.complete("fail");

        return paymentCleanup(handlers, {
          type: "validation_error",
          fields: fields,
        });
      }

      const { success, error }: { success?: boolean; error?: any } =
        await payments
          .init(payment, false)
          .then(async ({ secret, error }) => {
            if (secret) {
              return await payments
                .confirm(secret, ev.paymentMethod.id, ev)
                .then((success) => ({ success }))
                .catch((error) => ({ error }));
            }
            return { error };
          })
          .catch((error) => ({ success: false, error }));

      if (success) {
        ev.complete("success");
        return paymentCleanup(handlers);
      }

      ev.complete("fail");
      return paymentCleanup(handlers, error);
    });
  }, [
    paymentRequest,
    payment,
    payments,
    onPaymentError,
    onPaymentComplete,
    onPaymentProcessing,
  ]);

  // Memoize the options object we pass to
  // PaymentRequestButtonElement so that it doesn't complain about an
  // 'unsupported prop change'
  const options = useMemo(() => {
    return { paymentRequest, ...elementOptions };
  }, [paymentRequest]);

  const onClick = useCallback(
    (ev: StripePaymentRequestButtonElementClickEvent) => {
      //console.log("PaymentRequestButton:", payment);

      if (disabled) {
        ev.preventDefault();
        return;
      }

      paymentRequest.update({
        total: {
          amount: payment.amount,
          label: payment.type,
        },
      });
    },
    [paymentRequest, payment, disabled]
  );

  if (!options.paymentRequest) {
    return <></>;
  }

  return (
    <div style={{ width: "100%", display: "block" }}>
      <Divider>
        <Small>Or</Small>
        <Line />
      </Divider>
      <PaymentRequestButtonElement onClick={onClick} options={options} />
    </div>
  );
};

const Divider = styled(FlexColumn)`
  height: 18px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
  ${Small} {
    background: #ffffff;
    color: ${(props) => props.theme.palette.gray.lighter};
    width: 100px;
    height: 18px;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    position: absolute;
  }
`;

const Line = styled(FlexColumn)`
  border-top: 1px solid ${(props) => props.theme.palette.gray.lightest};
  width: 100%;
  height: 1px;
`;

/**
 * Example usage:
 *
 *  const payments = usePayments();
 *
 *  // Get the secret, this could throw an error if it fails
 *  const secret = await payments.init({
 *    amount: 100,
 *    type: "vote",
 *    eventId: "abh",
 *    songId: "asdasd",
 *    email: "weston@foo.com",
 *    name: "Weston",
 *  });
 *
 *  // Get the payment method somehow
 *  const paymentMethod = "...";
 *
 *  const success = await payments.confirm(secret, paymentMethod);
 */
export const usePayments = () => {
  const stripe = useStripe();
  const [payments, setPayments] = useState<nextsong.functions.Functions>();

  useEffect(() => {
    setPayments(nextsong.functions.init(functions, httpsCallable));
  }, []);

  const init = useCallback(
    async (
      payment: nextsong.functions.PaymentInitRequest["payment"],
      savePaymentMethod: boolean = true
    ) => {
      const { result, error } = await payments
        .initPayment({ payment, savePaymentMethod })
        .catch((error) => ({ result: { secret: "", id: "" }, error }));
      return { secret: result?.secret, id: result?.id, error };
    },
    [payments]
  );

  const confirm = useCallback(
    async (
      /**
       * Secret obtained from call to init.
       */
      secret: string,
      /**
       * Valid payment method object
       */
      paymentMethod: ConfirmCardPaymentData["payment_method"],
      /**
       * PaymentRequest event, if the payment method is from a payment request
       */
      event?: PaymentRequestPaymentMethodEvent
    ): Promise<boolean> => {
      if (!stripe || !payments) {
        return;
      }

      const { paymentIntent, error } = await (async (): Promise<{
        paymentIntent?: PaymentIntent;
        error?: StripeError;
      }> => {
        const { paymentIntent, error } = await stripe.confirmCardPayment(
          secret,
          { payment_method: paymentMethod },
          { handleActions: false }
        );

        if (error) {
          if (event) {
            // Report to the browser that the payment failed, prompting it to
            // re-show the payment interface, or show an error message and close
            // the payment interface.
            event.complete("fail");
          }

          // The payment failed
          return { error };
        }

        if (event) {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          event.complete("success");
        }

        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11"
        // instead check for: `paymentIntent.status === "requires_source_action"`.
        if (paymentIntent.status === "requires_action") {
          // Let Stripe.js handle the rest of the payment flow.
          const { paymentIntent, error } = await stripe.confirmCardPayment(
            secret
          );

          if (error) {
            // The payment failed
            console.error(error);
            return { error };
          }

          // The payment has succeeded
          return { paymentIntent };
        }

        // The payment has succeeded
        return { paymentIntent };
      })().catch((error) => ({ error, paymentIntent: undefined }));

      if (paymentIntent) {
        // No await because we don't actually care about the response
        payments
          .finalizePayment({ id: paymentIntent.id })
          .catch((error) => console.error("Error finalizing payment", error));
        return true;
      }

      throw error;
    },
    [stripe, payments]
  );

  const validate = useCallback((payment: nextsong.functions.Payment) => {
    payment = nextsong.functions.Payment.load(payment, { silent: true });
    if (!isValid(payment)) {
      return getErrors(payment);
    }
    return [];
  }, []);

  return useMemo(
    () => ({
      /**
       * Init payment.
       */
      init,

      /**
       * Confirm payment.
       */
      confirm,

      /**
       * Pre-validate payment data
       */
      validate,
    }),
    [init, confirm, validate]
  );
};
