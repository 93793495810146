import { FC } from "react";
import styled, { css } from "styled-components";
import { FlexColumn, FlexRow, H4, P, Link, Icon, Footnote, Small } from "notes";
import { useArtistEventContext, UpdatedEventSongModel } from "../../Components";

export const ListItemStatic: FC<{
  song: UpdatedEventSongModel;
  declined?: boolean;
}> = ({ song, declined = false, ...props }) => {
  const { title, artist, accumAmount = 0, requests } = song;
  const { event } = useArtistEventContext();

  return (
    <Wrapper {...props} yCenter declined={declined}>
      <SwipeContainer
        declined={declined}
        yCenter
        isCompact={event?.type === "sponsor"}
      >
        <DragArea yCenter xCenter />
        <FlexColumn>
          <H4>{title}</H4>
          <Small>{artist}</Small>
          {event?.type !== "sponsor" && (
            <Row yCenter>
              <IconTeal form name="Charity" />
              <P>${accumAmount / 100}</P>
              <IconGray style={{ marginLeft: "24px" }} form name="Audience" />
              <Footnote>{requests?.length ?? 0} Requests</Footnote>
            </Row>
          )}
        </FlexColumn>
      </SwipeContainer>
    </Wrapper>
  );
};

const DragArea = styled(FlexRow)`
  width: 40px;
  height: 40px;
  margin-right: 8px;
  color: ${(props) => props.theme.palette.gray.lighter};
`;

const SwipeContainer = styled(FlexRow)`
  background-color: ${(props) =>
    props.theme.palette[props.declined ? "red" : "green"].lightest};
  box-shadow: -8px 0 16px 0 rgba(0, 0, 0, 0.25),
    8px 0 16px 0 rgba(0, 0, 0, 0.25);
  padding: 16px 24px 16px 8px;
  position: relative;
  left: ${(props) => props.position ?? 0}px;
  width: 100%;
  user-select: none;
  z-index: 3;
  ${(props) =>
    props.isCompact &&
    css`
      padding-right: 16px;
      padding-left: 0px;
      ${DragArea} {
        margin-right: 0;
      }
    `};
`;

const Row = styled(FlexRow)`
  ${P} {
    color: ${(props) => props.theme.palette.gray.lighter};
    font-size: 15px;
    font-weight: 700;
    line-height: 19px;
  }
`;

const Wrapper = styled(FlexRow)`
  background-color: #ffffff;
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.lighter};
  position: relative;
  overflow: hidden;
  ${H4} {
    color: ${(props) => props.theme.palette.gray.lighter};
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
  }
  ${Footnote}, ${Small} {
    color: ${(props) => props.theme.palette.gray.lighter};
    font-size: 12px;
    font-style: normal;
    line-height: 15px;
  }
  ${Small} {
    margin-bottom: 7px;
  }
  ${Link} {
    font-size: 12px;
    font-weight: 900;
    line-height: 15px;
    text-transform: uppercase;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    border: none;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    margin-top: 8px;
  }
`;

const IconTeal = styled(Icon)`
  color: ${(props) => props.theme.palette.gray.lighter};
  margin-right: 8px;
`;

const IconGray = styled(Icon)`
  color: ${(props) => props.theme.palette.gray.lighter};
  margin-right: 8px;
`;
