import styled from "styled-components";
import parse from "html-react-parser";
import { useFanEventContext } from "./Components";

export const CustomTab = () => {
  const { event } = useFanEventContext();
  return (
    <Wrapper>
      {event?.customTab?.tabHtml && parse(event?.customTab?.tabHtml)}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 24px 16px 80px 16px;
  label {
    display: block;
    color: #ffffff;
    margin-bottom: 12px;
    width: 100%;
    text-align: center;
    font-size: 12px;
    line-height: 15px;
  }
  .columns-1 {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 16px;
  }
  .columns-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px 16px;
  }
  a.styled {
    background-color: ${(props) =>
      props.theme.branding.event.colors.action ??
      props.theme.branding.colors.action};
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    text-decoration: none;
    padding: 4px 6px;
    border-radius: 4px;
    border: none;
    color: ${(props) =>
      props.theme.branding.event.colors.actionLabel ??
      props.theme.branding.colors.actionLabel};
    &:hover {
      color: ${(props) =>
        props.theme.branding.event.colors.actionLabel ??
        props.theme.branding.colors.actionLabel};
      text-decoration: none;
    }
  }
  .columns-1,
  .columns-2 {
    > div {
      img {
        margin-bottom: 8px;
        display: block;
        width: 100%;
      }
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background: #1c1f20;
      border-radius: 6px;
      padding: 16px;
      a {
        background-color: ${(props) =>
          props.theme.branding.event.colors.action ??
          props.theme.branding.colors.action};
        align-items: center;
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        text-decoration: none;
        padding: 4px 6px;
        border-radius: 4px;
        border: none;
        color: ${(props) =>
          props.theme.branding.event.colors.actionLabel ??
          props.theme.branding.colors.actionLabel};
        &:hover {
          color: ${(props) =>
            props.theme.branding.event.colors.actionLabel ??
            props.theme.branding.colors.actionLabel};
          text-decoration: none;
        }
      }
    }
  }
`;
