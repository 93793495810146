import styled, { css } from "styled-components";
import { FlexRow, Button } from "notes";

export const Toggle = ({ checked, onChange }) => {
  return (
    <ToggleBackground yCenter>
      <ToggleButton checked={checked} onClick={() => onChange(true)}>
        On
      </ToggleButton>
      <ToggleButton right checked={!checked} onClick={() => onChange(false)}>
        Off
      </ToggleButton>
    </ToggleBackground>
  );
};

const ToggleBackground = styled(FlexRow)`
  width: 152px;
`;

const ToggleButton = styled(Button)`
  min-width: 76px;
  width: 76px;
  padding: 0 9px;
  height: 40px;
  border-color: ${(props) => props.theme.palette.gray.primary}80;
  border-style: solid;
  border-radius: ${(props) => (props.right ? "0 4px 4px 0" : "4px 0 0 4px")};
  ${(props) =>
    !props.checked &&
    css`
      border-width: ${props.right ? "1px 1px 1px 0" : "1px 0 1px 1px"};
      background-color: transparent;
      color: ${(props) => props.theme.palette.gray.lighter};
      &:not(:disabled):hover,
      &:not(:disabled):focus {
        background-color: transparent;
        color: ${(props) => props.theme.palette.gray.lighter};
      }
    `};
  ${(props) =>
    props.checked &&
    css`
      border-width: 1px;
      background-color: ${
        props.right ? "#cccccc" : props.theme.palette.green.primary
      };
      border-color: ${props.right ? "#aaaaaa" : "#4b9d46"};
      color: ${
        props.right ? props.theme.palette.black : props.theme.palette.white
      };
      &:not(:disabled):hover,
      &:not(:disabled):focus {
        background-color: ${
          props.right ? "#cccccc" : props.theme.palette.green.primary
        };
        color: ${
          props.right ? props.theme.palette.black : props.theme.palette.white
        };
      &:not(:disabled):hover,
      }
    `};
  &:not(:disabled):hover,
  &:not(:disabled):focus {
    box-shadow: none;
  }
`;
