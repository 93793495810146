import React from "react";
import styled from "styled-components";
import { FlexRow } from "notes";
import { LogoWhite } from "Images";
import { useAboutContext } from ".";
import { useHistory } from "react-router-dom";

export const Header = () => {
  const { setScheduleModalOpen } = useAboutContext();
  const history = useHistory();
  return (
    <Wrapper yCenter>
      <LogoWhite onClick={() => history.push("/")} />
      <ActionButton onClick={() => setScheduleModalOpen(true)}>
        Schedule Demo
      </ActionButton>
    </Wrapper>
  );
};

const Wrapper = styled(FlexRow)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
  width: 100%;
  justify-content: space-between;
  padding: 2em 9.6%;

  svg {
    cursor: pointer;
    width: 200px;
    height: 41px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    padding: 2em 24px;
    svg {
      width: 120px;
      height: 24px;
    }
  }
`;

const ActionButton = styled.button`
  background: #ffffff;
  border-radius: 22px;
  color: #222222;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  height: 44px;
  padding: 0 24px;
  font-weight: 400;
  border: none;
  @media only screen and ${(props) => props.theme.media.small} {
    border-radius: 22px;
    font-size: 12px;
    line-height: 15px;
    height: 32px;
    padding: 0 16px;
    font-weight: 400;
    min-width: initial;
  }

  &:hover {
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.32);
  }
`;
