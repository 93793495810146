import { useNextEvent } from "Hooks";
import { useHistory } from "react-router-dom";

export const EventRedirect = (props) => {
  const history = useHistory();
  const groupId = props?.match?.params?.groupId;
  const { nextEventId, nextEventLoading } = useNextEvent(groupId);
  if (!nextEventLoading && !!nextEventId) {
    history.push(`/event/${nextEventId}`);
  }

  if (!nextEventLoading && nextEventId === undefined) {
    history.push("/");
  }

  return <div></div>;
};
