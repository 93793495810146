import styled from "styled-components";
import { FlexColumn, H3 } from "notes";
import { LogoGradient } from "Images";
import QRCode from "qrcode.react";
import { useEffect } from "react";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { firestore } from "firebase-internal";
import { doc } from "firebase/firestore";

export const QRPrint = (props) => {
  const eventId = props?.match?.params?.eventId;

  const [snapshot, loading, error] = useDocumentDataOnce(
    doc(firestore, `nextsong_events/${eventId}`)
  );

  const event: any = snapshot || {};
  const qrLink = `${window.location.origin}/event/${eventId}`;

  useEffect(() => {
    if (!loading) {
      window.print();
    }
  }, [snapshot]);

  return (
    <Wrapper xCenter>
      <Content xCenter style={{ marginBottom: "52px" }}>
        <Logo />
        <H3>
          Scan the QR Code below with your phone's camera
          <br />
          to vote which song you want played by {event?.artistName}!
        </H3>
      </Content>
      <QRCodeWrapper xCenter yCenter>
        <TopLeft />
        <TopRight />
        <QRCode value={qrLink} size={256} />
        <BottomLeft />
        <BottomRight />
      </QRCodeWrapper>
      <Content xCenter yEnd style={{ margin: "52px 0" }}>
        <H3>
          Already love what you're hearing?
          <br />
          Scan the QR code above and show your appreciation for{" "}
          {event?.artistName}.
        </H3>
      </Content>
      <Logo />
    </Wrapper>
  );
};

const Wrapper = styled(FlexColumn)`
  height: calc(100vh - 100px);
`;

const Content = styled(FlexColumn)`
  width: 100%;
  margin: 0 auto;
  max-width: 600px;
  flex-grow: 1;
  ${H3} {
    text-align: center;
  }
  ${H3} + ${H3} {
    margin-top: 24px;
  }
`;

const Logo = styled(LogoGradient)`
  margin-bottom: 40px;
  width: 250px;
  height: 51px;
`;

const QRCodeWrapper = styled(FlexColumn)`
  position: relative;
  width: 350px;
  height: 350px;
  flex-shrink: 0;
`;

const TopLeft = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  border-top: 4px solid ${(props) => props.theme.palette.gray.lightest};
  border-left: 4px solid ${(props) => props.theme.palette.gray.lightest};
`;

const TopRight = styled(TopLeft)`
  right: 0;
  left: initial;
  border-top: 4px solid ${(props) => props.theme.palette.gray.lightest};
  border-right: 4px solid ${(props) => props.theme.palette.gray.lightest};
  border-left: none;
`;

const BottomLeft = styled(TopLeft)`
  bottom: 0;
  top: initial;
  border-bottom: 4px solid ${(props) => props.theme.palette.gray.lightest};
  border-left: 4px solid ${(props) => props.theme.palette.gray.lightest};
  border-top: none;
  border-right: none;
`;

const BottomRight = styled(TopLeft)`
  bottom: 0;
  right: 0;
  top: initial;
  left: initial;
  border-bottom: 4px solid ${(props) => props.theme.palette.gray.lightest};
  border-right: 4px solid ${(props) => props.theme.palette.gray.lightest};
  border-top: none;
  border-left: none;
`;
