import styled from "styled-components";
import { FlexColumn } from "notes";
import {
  Banner,
  PhoneGallery,
  Section,
  SectionHeader,
  Header,
  Footer,
  HowItWorks,
  AboutProvider,
} from "./Components";
import {
  steps1,
  steps2,
  howItWorks,
  bannerContent,
  trailerPaths,
} from "./assets/data";
import drummerIcon from "./assets/Drummer.png";
import concertIcon from "./assets/Concert.png";
import singerIcon from "./assets/Singer.png";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

export const About = () => {
  return (
    <>
      <Helmet>
        <link
          href={
            "https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap"
          }
          rel="stylesheet"
        />
      </Helmet>
      <AboutProvider>
        <AboutComponent />
      </AboutProvider>
    </>
  );
};

export const AboutComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Wrapper>
      <Header />
      <Banner content={bannerContent} />
      <Section style={{ background: "#efefef" }}>
        <SectionHeader
          icon={drummerIcon}
          title="NextSong.Live"
          subtitle="An interactive experience for fans"
          description=""
        />
        <HowItWorks videoPath={trailerPaths} bullets={howItWorks} />
      </Section>
      <Section>
        <SectionHeader
          icon={concertIcon}
          title="Fun and Easy for Fans"
          description="NextSong gets fans more invested in your show whether you're playing for your most dedicated fans or a room full of future fans."
        />
        <PhoneGallery steps={steps1} />
      </Section>
      <Section style={{ background: "#efefef" }}>
        <SectionHeader
          icon={singerIcon}
          title="Rewarding and Easy for Artists"
          description="NextSong gets fans more invested in your show whether you're playing for your most dedicated fans or a room full of future fans."
        />
        <PhoneGallery steps={steps2} />
      </Section>
      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled(FlexColumn)`
  h1,
  h2,
  h3,
  h4,
  p,
  button {
    font-family: Poppins;
  }
`;
