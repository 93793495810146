import styled from "styled-components";
import { FlexColumn } from "notes";
import QRCode from "qrcode.react";
import { PageModal } from "Components";
import { useArtistModalContext } from ".";

export const ModalQRView = ({ open }) => {
  const { closeModal } = useArtistModalContext();
  return (
    <PageModal open={open} title="Event QR Code" isQR onBack={closeModal}>
      <QRView />
    </PageModal>
  );
};

export const QRView = () => {
  const { modal } = useArtistModalContext();
  const eventLink = modal?.qrView;
  return (
    <Wrapper xCenter yCenter>
      <QRCodeWrapper xCenter yCenter>
        <TopLeft />
        <TopRight />
        <QRCode value={eventLink} size={256} />
        <BottomLeft />
        <BottomRight />
      </QRCodeWrapper>
    </Wrapper>
  );
};

const Wrapper = styled(FlexColumn)`
  min-height: 60vh;
`;

const QRCodeWrapper = styled(FlexColumn)`
  position: relative;
  width: 350px;
  height: 350px;
`;

const TopLeft = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  border-top: 4px solid ${(props) => props.theme.palette.gray.lightest};
  border-left: 4px solid ${(props) => props.theme.palette.gray.lightest};
`;

const TopRight = styled(TopLeft)`
  right: 0;
  left: initial;
  border-top: 4px solid ${(props) => props.theme.palette.gray.lightest};
  border-right: 4px solid ${(props) => props.theme.palette.gray.lightest};
  border-left: none;
`;

const BottomLeft = styled(TopLeft)`
  bottom: 0;
  top: initial;
  border-bottom: 4px solid ${(props) => props.theme.palette.gray.lightest};
  border-left: 4px solid ${(props) => props.theme.palette.gray.lightest};
  border-top: none;
  border-right: none;
`;

const BottomRight = styled(TopLeft)`
  bottom: 0;
  right: 0;
  top: initial;
  left: initial;
  border-bottom: 4px solid ${(props) => props.theme.palette.gray.lightest};
  border-right: 4px solid ${(props) => props.theme.palette.gray.lightest};
  border-top: none;
  border-left: none;
`;
