import { firestore } from "firebase-internal";
import { doc } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import * as nextsong from "@musicaudienceexchange/nextsong-interface";
import { useUser } from "auth";

interface Customer extends nextsong.firestore.User {
  paymentMethod: nextsong.firestore.PaymentMethod;
  name: string;
  email: string;
  displayName: string;
}

export const useCustomerDetails = (): Customer => {
  const { user } = useUser();

  const [snapshot] = useDocumentData(
    doc(firestore, `nextsong_users/${user.uid}`)
  );

  const paymentMethods: nextsong.firestore.PaymentMethod[] =
    snapshot?.paymentMethods ?? [];

  const customer: Customer = {
    name: snapshot?.name || "",
    email: snapshot?.email || "",
    displayName: snapshot?.displayName || "",
    paymentMethod: paymentMethods[paymentMethods?.length - 1],
    paymentMethods: paymentMethods,
  };

  return customer;
};
