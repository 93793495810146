import styled, { css } from "styled-components";
import { Drawer, Dropdown } from "Components";
import { timeIncrements } from "Utils";
import { useArtistModalContext } from "../Components";
import {
  FlexColumn,
  FlexRow,
  Icon,
  Footnote,
  Button,
  DatePicker,
  Input,
  P,
  H4,
} from "notes";

export const ModalAddShow = ({ open }) => {
  return (
    <Drawer open={open} onClose={() => {}}>
      <AddShow />
    </Drawer>
  );
};

export const AddShow = () => {
  const types = [
    { label: "Song Requests + Tips", value: "requests" },
    { label: "Tips Only", value: "tips" },
  ];

  const { setShowDetail, showDetail, setModal } = useArtistModalContext();

  return (
    <Wrapper>
      <Item yCenter onClick={() => setModal({ venueAddress: true })}>
        <Icon form name="Marker" />
        {showDetail?.venue ? (
          <Label>{showDetail?.venue}</Label>
        ) : (
          <Footnote>Venue</Footnote>
        )}
      </Item>
      <Item yCenter margin>
        <Icon form name="Calendar" />
        <DatePicker
          style={{ flexGrow: 1 }}
          selected={showDetail.date}
          onSelect={(v) => setShowDetail({ ...showDetail, date: v })}
          dateFormat={"cccc, MMMM d, yyyy"}
          InternalField={(props) => (
            <StyledInput {...props} leftIcon={undefined} placeholder="Date" />
          )}
        />
      </Item>
      <Dropdown
        trigger={({ onClick }) => (
          <Item yCenter onClick={onClick}>
            <Icon form name="Clock" />
            {showDetail?.time ? (
              <Label>{showDetail?.time}</Label>
            ) : (
              <Footnote>Time</Footnote>
            )}
          </Item>
        )}
      >
        {({ handleClose }) =>
          timeIncrements.map((time) => (
            <SelectOption
              onClick={() => {
                setShowDetail({ ...showDetail, time: time.value });
                handleClose();
              }}
              selected={showDetail?.time === time.value}
            >
              {time.value}
            </SelectOption>
          ))
        }
      </Dropdown>
      <Dropdown
        trigger={({ onClick }) => (
          <Item yCenter onClick={onClick}>
            <Icon form name="Preferences" />
            {showDetail?.type ? (
              <Label>
                {types?.find((type) => type.value === showDetail?.type)?.label}
              </Label>
            ) : (
              <Footnote>Show type</Footnote>
            )}
          </Item>
        )}
      >
        {({ handleClose }) =>
          types.map((type) => (
            <SelectOption
              onClick={() => {
                setShowDetail({ ...showDetail, type: type.value });
                handleClose();
              }}
              selected={showDetail?.type === type.value}
            >
              {type.label}
            </SelectOption>
          ))
        }
      </Dropdown>
      <Action>
        <Button style={{ width: "100%" }}>Create</Button>
      </Action>
    </Wrapper>
  );
};

const Label = styled(H4)`
  font-weight: 600;
  margin: 9px 0 9px 12px;
`;

const Wrapper = styled(FlexColumn)`
  .react-datepicker-wrapper {
    flex-grow: 1;
  }
`;

const SelectOption = styled(P)`
  color: ${(props) => props.theme.palette.gray.primary};
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  font-weight: 400;
  padding: 13px 16px;
  width: 100%;
  height: 48px;
  ${(props) =>
    props.selected &&
    css`
      background-color: ${(props) => props.theme.palette.gray.lightest};
      color: ${(props) => props.theme.palette.black};
    `};
`;

const Action = styled(FlexRow)`
  padding: 24px;
`;

const Item = styled(FlexRow)`
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.lightest};
  padding: 8px 32px;
  color: ${(props) => props.theme.palette.gray.primary};
  ${(props) =>
    props.margin &&
    `
    padding-left: 8px;
    margin-left: 24px;
  `};
  ${Footnote} {
    color: ${(props) => props.theme.palette.gray.primary};
    font-style: normal;
    margin: 13px 0 13px 12px;
  }
`;

const StyledInput = styled(Input)`
  border: none;
  width: 100%;
  &:focus {
    box-shadow: none;
  }
`;
