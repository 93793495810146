import { Notification } from "Components";
import { createContext, useState, useContext, FC } from "react";
interface AppContextProps {
  allowSearch: boolean;
  setAllowSearch: (value: boolean) => void;
  notify: (value: string) => void;
  isSideDrawerOpen: boolean;
  setIsSideDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AppContext = createContext<AppContextProps>(null);

export const AppProvider: FC = ({ children }) => {
  const [allowSearch, setAllowSearch] = useState(true);
  const [notifyValue, setNotify] = useState<string | null>(null);
  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);

  const notify = (value: string) => {
    setNotify(value);
    setTimeout(() => {
      setNotify(null);
    }, 2300);
  };

  const value = {
    allowSearch,
    setAllowSearch,
    notify,
    isSideDrawerOpen,
    setIsSideDrawerOpen,
  };
  return (
    <AppContext.Provider value={value}>
      <Notification text={notifyValue} onClose={() => setNotify(null)} />
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
