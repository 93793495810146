import styled from "styled-components";
import { FanEvent } from "./Fan";
import { ArtistEventList, ArtistEvent } from "./Artist";
import { Terms } from "./Terms";
import { Privacy } from "./Privacy";
import { Contact } from "./Contact";
import { QRPrint } from "./QRPrint";
import { QRGroup } from "./QRGroup";
import { About } from "./About";
import { Landing } from "./Landing";
import { ArtistModalProvider } from "./Artist/Components";
import { EventRedirect } from "./EventRedirect";
import { useUser } from "auth";
import { Switch, Route, Redirect } from "react-router-dom";
import { useAuthContext } from "Context";

export const Routes = () => {
  const {
    claims: { isAdmin },
  } = useUser();
  const { isArtist } = useAuthContext();
  const isAccessAllowed = isAdmin || isArtist;
  return (
    <Wrapper>
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/artists" component={About} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/contact" component={Contact} />
        <Route path="/event/:eventId" component={FanEvent} />
        <Route path="/qr/:eventId" component={QRPrint} />
        <Route path="/qrg/:groupId" component={QRGroup} />
        <Route path="/group/:groupId" component={EventRedirect} />
        {isAccessAllowed && (
          <ArtistModalProvider>
            <Switch>
              <Route exact path="/artist" component={ArtistEventList} />
              <Route path="/artist/:eventId" component={ArtistEvent} />
            </Switch>
          </ArtistModalProvider>
        )}
        <Redirect to="/" />
      </Switch>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${(props) => props.theme.branding.bodyFont};
  width: 100%;
  height: 100%;
  min-height: 100vh;
  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;
  }
`;
