import React from "react";
import styled from "styled-components";
import { FlexColumn } from "notes";
import { Container, useOnScreen, VideoBackground } from ".";

export const Banner = ({ content }) => {
  const { title, bannerImage, subtitle, videoSource } = content;

  const videoRef = React.useRef();
  const isVideoVisible = useOnScreen(videoRef);

  return (
    <Wrapper imageSrc={bannerImage} id="banner" ref={videoRef}>
      <Container>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </Container>
      <Overlay />
      <StyledVideoBackground loop path={videoSource} play={isVideoVisible} />
    </Wrapper>
  );
};

const StyledVideoBackground = styled(VideoBackground)`
  filter: blur(18px);
`;

const Overlay = styled.div`
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 30%),
    linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%),
    linear-gradient(
      243.93deg,
      rgba(231, 30, 61, 0.5) 11%,
      rgba(200, 26, 70, 0.5) 17%,
      rgba(138, 16, 87, 0.5) 31%,
      rgba(89, 9, 101, 0.5) 47%,
      rgba(54, 4, 110, 0.5) 62%,
      rgba(34, 1, 116, 0.5) 80%,
      rgba(27, 0, 118, 0.5) 100%
    );
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
`;

const Title = styled.h1`
  font-size: min(7.46vw, 28px);
  font-weight: 600;
  line-height: 1em;
  width: 90%;
  margin: 0 auto 1vw auto;
  text-shadow: 0 0 15px rgba(0, 0, 0, 0.6);
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: min(5vw, 64px);
  }
  @media only screen and ${(props) => props.theme.media.desktop} {
    font-size: 64px;
  }
`;

const Subtitle = styled.h2`
  font-size: min(5.6vw, 21px);
  width: 80%;
  max-width: 332px;
  font-weight: 300;
  line-height: 1.2em;
  text-shadow: 0 0 15px rgba(0, 0, 0, 0.6);
  margin: 0 auto;
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: min(3.1vw, 44px);
    max-width: 80%;
    width: 80%;
  }
  @media only screen and ${(props) => props.theme.media.desktop} {
    font-size: 44px;
  }
`;

const Wrapper = styled(FlexColumn)`
  background: url(${(props) => props.imageSrc}), #000000;
  background-size: cover;
  color: #ffffff;
  text-align: center;
  width: 100%;
  padding-right: 32px;
  padding-left: 32px;
  padding-top: min(37.33vw, 140px);
  padding-bottom: min(26.67vw, 100px);
  position: relative;
  overflow: hidden;
  & > ${Container} {
    z-index: 10;
  }

  @media only screen and ${(props) => props.theme.media.medium} {
    padding-right: 14vw;
    padding-left: 14vw;
    padding-top: calc(min(19%, 270px));
    padding-bottom: calc(min(19%, 270px));
  }

  @media only screen and ${(props) => props.theme.media.large} {
    padding-left: 40px;
    padding-right: 40px;
  }
`;
