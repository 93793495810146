import { createContext, useContext, useEffect, useState } from "react";
import * as nextsong from "@musicaudienceexchange/nextsong-interface";
import {
  DocumentModel,
  useCollectionModel,
  useDocumentModel,
} from "Hooks/models";
import { firestore } from "firebase-internal";
import { collection, query, doc } from "firebase/firestore";
import { DateTime } from "luxon";
import { updateRequests } from "Components";
import { SweepsEntry } from "@musicaudienceexchange/nextsong-interface/lib/esm/firestore";
import { useHistory } from "react-router-dom";
export type EventModel = DocumentModel<nextsong.firestore.Event>;
export type RequestModel = DocumentModel<nextsong.firestore.Request>;
export type PaymentModel = DocumentModel<nextsong.firestore.Payment>;

type EventSongModel = DocumentModel<nextsong.firestore.EventSong>;

export interface RequestProps extends PaymentModel {
  songName?: string;
  userTotal?: number;
}

export interface UpdatedEventSongModel extends EventSongModel {
  accumAmount: number;
  isRepeatRequest?: boolean;
  lastRequestedAt: DateTime;
  requests: RequestProps[];
  sponsorships?: nextsong.firestore.Request["sponsorship"];
  uniqueNames: string[];
}

interface PriorityProps {
  [key: string]: number;
}

interface SweepsUpdate extends SweepsEntry {
  isInVenue?: boolean;
  _id?: string;
}

const ViewPropTypes = [
  "requests",
  "encore",
  "support",
  "leads",
  "dashboard",
  "sweeps",
] as const;
export type ViewProps = typeof ViewPropTypes[number];

interface ArtistEventContextProps {
  event: EventModel;
  eventLoading: boolean;
  playlist: UpdatedEventSongModel[];
  requests: RequestProps[];
  setPriority: (val: PriorityProps) => void;
  priority: any;
  requestsTotalCents: number;
  view: ViewProps;
  setView: React.Dispatch<React.SetStateAction<ViewProps>>;
  encorePaymentAllowed: boolean;
  proximityLimit?: number;
  totalEntriesInVenue?: number;
  entries?: SweepsUpdate[];
}

export const ArtistEventContext = createContext<ArtistEventContextProps>(
  {} as ArtistEventContextProps
);

export const ArtistEventProvider = ({ eventId, children }) => {
  const [view, setView] = useState<ViewProps>("requests");
  const [eventSnapshot, eventLoading] = useDocumentModel(
    nextsong.firestore.Event,
    doc(firestore, `nextsong_events/${eventId}`)
  );
  const history = useHistory();

  const event = {
    ...eventSnapshot,
    startsAt: eventSnapshot?.startsAt?.toLocal(),
  };

  const defaultView =
    event?.type === "encore"
      ? "encore"
      : event?.type === "checkIn"
      ? "leads"
      : "requests";

  useEffect(() => {
    setView(defaultView);
  }, [defaultView]);

  if (!eventLoading && event === undefined) {
    history.push("/artist");
  }

  const [playlist] = useCollectionModel(
    nextsong.firestore.EventSong,
    query(collection(firestore, `nextsong_events/${eventId}/playlist`))
  );

  const [requests] = useCollectionModel(
    nextsong.firestore.Payment,
    query(collection(firestore, `nextsong_events/${eventId}/payments`))
  );

  const [entriesData] = useCollectionModel(
    nextsong.firestore.SweepsEntry,
    query(collection(firestore, `nextsong_events/${eventId}/entries`))
  );

  const proximityLimit = event?.sweeps?.proximityLimitInMiles ?? 1.25;
  const entries: SweepsUpdate[] = entriesData?.map((entry) => {
    const isInVenue = !!(
      entry.minDistance &&
      typeof entry.minDistance === "number" &&
      entry.minDistance <= proximityLimit
    );
    return { ...entry, isInVenue };
  });

  const priorityMap = playlist?.reduce((map, { _id, priority }) => {
    map[_id] = priority;
    return map;
  }, {});

  const [priority, setPriority] = useState<PriorityProps>(priorityMap);

  const isEqual = JSON.stringify(priorityMap) === JSON.stringify(priority);

  useEffect(() => {
    if (!isEqual) {
      setPriority(priorityMap);
    }
  }, [priorityMap]);

  const updatedPlaylist = updateRequests(playlist, requests, false);

  const updatedRequests: RequestProps[] = requests
    ?.map((req) => {
      const songName = playlist?.find((p) => p._id === req.songId)?.title;
      const userTotal = requests
        ?.filter((r) => r.uid === req.uid)
        ?.reduce((a, b) => a + b.amount, 0);
      return {
        ...req,
        songName,
        userTotal,
        displayName: req.displayName ?? req.name,
      };
    })
    ?.sort((a, b) => b.createdAt?.diff(a.createdAt, "seconds")?.seconds);

  const requestsTotalCents = requests?.reduce((a, b) => a + b.amount, 0) / 100;
  const encorePaymentAllowed = event?.encore?.minVoteAmount > 0;
  const totalEntriesInVenue = entries?.filter((req) => req.isInVenue)?.length;

  const value = {
    event,
    eventLoading,
    playlist: updatedPlaylist,
    requests: updatedRequests,
    setPriority,
    priority,
    requestsTotalCents,
    view,
    setView,
    encorePaymentAllowed,
    proximityLimit,
    totalEntriesInVenue,
    entries,
  };

  return (
    <ArtistEventContext.Provider value={value}>
      {children}
    </ArtistEventContext.Provider>
  );
};

export const useArtistEventContext = () => useContext(ArtistEventContext);
