import styled from "styled-components";
import {
  FlexColumn,
  FlexRow,
  Subheader,
  H4,
  Loader,
  Avatar as AvatarBase,
  Icon,
} from "notes";
import { IconQRCode } from "Images";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { collection, query, where } from "@firebase/firestore";
import { firestore } from "firebase-internal";
import { useState } from "react";
import { useArtistModalContext } from "./Components";
import { MenuButton, CopyIndicator } from "./Event/Dashboard";
import { useUser } from "auth";

export const QRCodes = () => {
  const {
    user,
    claims: { isAdmin },
  } = useUser();
  const [copyIndicatorIsVisible, setCopyIndicatorIsVisible] = useState(false);
  const { setModal } = useArtistModalContext();
  const q = isAdmin
    ? query(collection(firestore, "artist_groups"))
    : query(
        collection(firestore, "artist_groups"),
        where(`members.${user?.uid}`, "!=", "")
      );

  const [groups, loading] = useCollectionDataOnce(q, { idField: "groupId" });

  const onCopyToClipboard = () => {
    setCopyIndicatorIsVisible(true);
    setTimeout(() => {
      setCopyIndicatorIsVisible(false);
    }, 2300);
  };

  return (
    <Wrapper>
      <Subheader>Artist QR Codes</Subheader>

      {loading ? (
        <Loader />
      ) : (
        groups?.map(({ name, assets, groupId }) => {
          const qrLink = `${window.location.origin}/group/${groupId}`;
          const image =
            assets?.icon?.path ??
            assets?.headerLarge?.path ??
            assets?.headerSmall?.path;
          return (
            <GroupContainer key={groupId}>
              <CardHeader yCenter>
                {image ? (
                  <Avatar src={image} alt={name} />
                ) : (
                  <NoAvatar xCenter yCenter>
                    <Icon form name="NoImage" />
                  </NoAvatar>
                )}

                <H4>{name}</H4>
              </CardHeader>
              <ManageCard>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                  href={`/qrg/${groupId}`}
                >
                  <IconQRCode />
                  <span>Print QR</span>
                </Link>
                <MenuButton onClick={() => setModal({ qrView: qrLink })}>
                  <IconQRCode />
                  <span>View QR</span>
                </MenuButton>
                <MenuButton
                  onClick={() => {
                    navigator.clipboard.writeText(qrLink);
                    onCopyToClipboard();
                  }}
                >
                  <LinkIcon name="Link" />
                  <span>Artist Link</span>
                </MenuButton>
              </ManageCard>
            </GroupContainer>
          );
        })
      )}
      {copyIndicatorIsVisible && (
        <CopyIndicator onClick={() => setCopyIndicatorIsVisible(false)}>
          Copied to clipboard
          <Icon platform name="Close" />
        </CopyIndicator>
      )}
    </Wrapper>
  );
};

const CardHeader = styled(FlexRow)`
  padding: 12px;
  background: #f2f5f7;
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.lightest};
`;

const ManageCard = styled(FlexRow)`
  padding: 16px;
  background: #ffffff;
  display: flex;
  justify-content: space-around;
`;

const NoAvatar = styled(FlexColumn)`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: ${(props) => props.theme.palette.gray.lighter};
  color: #ffffff;
  margin-right: 12px;
  flex-shrink: 0;
  svg {
    width: 18px;
    height: 18px;
  }
`;

export const Avatar = styled(AvatarBase)`
  width: 32px;
  height: 32px;
  margin-right: 12px;
  flex-shrink: 0;
`;

const GroupContainer = styled(FlexColumn)`
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  overflow: hidden;
  ${H4} {
    font-family: ${(props) => props.theme.branding.headerFont};
    font-size: 15px;
    font-weight: 700;
    line-height: 19px;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  & + & {
    margin-top: 44px;
  }
`;

const Wrapper = styled(FlexColumn)`
  ${Subheader} {
    color: ${(props) => props.theme.palette.gray.lighter};
    font-size: 20px;
    font-family: ${(props) => props.theme.branding.headerFont};
    line-height: 24px;
    text-transform: uppercase;
    margin-bottom: 24px;
  }
`;

const Link = styled(MenuButton).attrs({ as: "a" })``;

const LinkIcon = styled(Icon)`
  width: 22px;
  margin: -4px;
`;
