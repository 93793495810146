import { directPath } from "Utils";

export const trailerPaths = [
  `${directPath}/nextsong-trailer.webm`,
  `${directPath}/nextsong-trailer.mp4`,
];

export const bannerContent = {
  title: "Use NextSong to Earn More from Gigs",
  subtitle: "Fun for fans, rewarding for you, easy for everyone.",
  bannerImage: "",
  videoSource: [
    `${directPath}/hero-video.webm`,
    `${directPath}/hero-video.mp4`,
  ],
};

// How it Works
export const howItWorks = [
  {
    title: "Quick Setup",
    content:
      "Let the NextSong.Live know when your shows are and which songs you'd like to make available for fans, we'll get your logins and shows set up for you",
  },
  {
    title: "Invite Fan Participation",
    content:
      "Tell fans to go to NextSong.Live and they'll be automatically routed to your show. NextSong also provides printable sheets with QR codes that can be placed throughout the venue.",
  },
  {
    title: "Get More Tips",
    content:
      "Fans will put money behind the songs they want to hear and tip throughout the show. The more you thank fans and reference NextSong the more fans will get involved",
  },
];

// Set 1
export const steps1 = [
  {
    title: "Easy Navigation",
    content:
      "Fans visit NextSong.Live and are automatically routed to your show's page",
    media: {
      type: "video",
      src: [`${directPath}/fan-slide-1.webm`, `${directPath}/fan-slide-1.mp4`],
    },
  },
  {
    title: "Part of the Show",
    content:
      "NextSong gives the fans the ability to see what songs you play and get invested in your setlist - both emotionally and with their tips",
    media: {
      type: "video",
      src: [`${directPath}/fan-slide-2.webm`, `${directPath}/fan-slide-2.mp4`],
    },
  },
  {
    title: "Convenient and Secure",
    content:
      "With Apple Pay, Google Pay and credit card support, NextSong makes it easy for fans to show their support. There's no app for them to download, no balance for them to check, and no worries about transferring money to an unfamiliar account on a peer-to-peer app",
    media: {
      type: "video",
      src: [`${directPath}/fan-slide-3.webm`, `${directPath}/fan-slide-3.mp4`],
    },
  },
];

// Set 2
export const steps2 = [
  {
    title: "Fully Automated",
    content:
      "Your artist view updates automatically - you'll see the fans votes and the tips roll in in real time directly on your phone's web browser - there's no page refreshing or app to download.",
    media: {
      type: "video",
      src: [
        `${directPath}/artist-slide-1.webm`,
        `${directPath}/artist-slide-1.mp4`,
      ],
    },
  },
  {
    title: "Doubles as a Setlist",
    content:
      "NextSong helps you organize your set and mark songs as played. Doing so lets fans know what songs they might have missed and incentives them to pay big money to have you repeat a song",
    media: {
      type: "video",
      src: [
        `${directPath}/artist-slide-2.webm`,
        `${directPath}/artist-slide-2.mp4`,
      ],
    },
  },
  {
    title: "See your Top Fans",
    content:
      "NextSong's dashboard shows how much you've brought in and who your top fans are. You can also download the contact information for your fans to build your mailing list.",
    media: {
      type: "video",
      src: [
        `${directPath}/artist-slide-3.webm`,
        `${directPath}/artist-slide-3.mp4`,
      ],
    },
  },
];
