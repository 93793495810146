import styled from "styled-components";
import parse from "html-react-parser";
import { H3, Subheader, FlexRow, H4, Small, P } from "notes";
import { ShowSupport, useFanEventContext } from "./Components";
import { ListWrapper as ListWrapperBase } from "./Playlist";

export const Charity = () => {
  const { event, donationsTotal, myDonationsTotal } = useFanEventContext();
  const data = event?.charity;

  const handleStringVariables = (text: string) => {
    let updatedText = text;
    if (updatedText?.includes("{total}")) {
      updatedText = updatedText.replace(
        "{total}",
        `$${(donationsTotal / 100).toFixed(2)}`
      );
    }
    return updatedText;
  };

  const donationMessage =
    data.donationsTotalMessage &&
    handleStringVariables(data.donationsTotalMessage);

  return (
    <ListWrapper>
      <H3>{data?.title ?? "Show Your Support"}</H3>
      {data?.description && <Subheader>{data?.description}</Subheader>}

      {data.showDonationsTotal && (
        <Content style={{ marginBottom: "24px" }}>
          {donationMessage ?? (
            <H4>Total Support: ${(donationsTotal / 100).toFixed(2)}</H4>
          )}
        </Content>
      )}

      <Content>
        {!myDonationsTotal
          ? data?.preDonateMessage && parse(data?.preDonateMessage)
          : data?.postDonateMessage && parse(data?.postDonateMessage)}
      </Content>

      <FlexRow style={{ maxWidth: "380px" }} xCenter>
        <StyledSupport enabled actionText={data?.actionText} />
      </FlexRow>
      {!!myDonationsTotal && (
        <Subheader>
          You've contributed ${(myDonationsTotal / 100).toFixed(2)}
        </Subheader>
      )}
    </ListWrapper>
  );
};

const StyledSupport = styled(ShowSupport)`
  padding: 0;
  margin-top: 24px;
  margin-bottom: 12px;
`;

const Content = styled(FlexRow)`
  color: ${(props) => props.theme.branding.event.colors.body};
  h4 {
    color: ${(props) => props.theme.branding.event.colors.title};
    font-family: ${(props) => props.theme.branding.headerFont};
  }
  .description {
    color: ${(props) => props.theme.branding.event.colors.description};
  }
  a.styled {
    background-color: ${(props) =>
      props.theme.branding.event.colors.action ??
      props.theme.branding.colors.action};
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    text-decoration: none;
    padding: 4px 6px;
    border-radius: 4px;
    border: none;
    color: ${(props) =>
      props.theme.branding.event.colors.actionLabel ??
      props.theme.branding.colors.actionLabel};
    &:hover {
      color: ${(props) =>
        props.theme.branding.event.colors.actionLabel ??
        props.theme.branding.colors.actionLabel};
      text-decoration: none;
    }
  }
`;

const ListWrapper = styled(ListWrapperBase)`
  font-family: ${(props) => props.theme.branding.bodyFont};
  font-size: 17px;
  line-height: 22px;
  font-weight: 400;
  padding: 24px 16px 80px 16px;
  & > ${FlexRow} {
    width: 100%;
  }
  ${Subheader} {
    margin-bottom: 24px;
  }
  ${H4}, ${Small} {
    width: 100%;
    text-align: center;
  }
  ${Small} {
    margin-bottom: 16px;
  }
  ${P} {
    color: ${(props) => props.theme.branding.event.colors.body};
  }
`;
