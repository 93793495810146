import styled from "styled-components";
import { FlexColumn } from "notes";
import { SoundWaves } from "Images";

export const PostShow = () => {
  return (
    <Content yEnd>
      <Graphic />
    </Content>
  );
};

const Content = styled(FlexColumn)`
  flex-grow: 1;
  padding: 24px 0;
`;

const Graphic = styled(SoundWaves)`
  width: 100%;
  opacity: 0.6;
`;
