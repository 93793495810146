import { Fragment, useEffect, useState } from "react";
import haversine from "Utils/haversine";
import styled from "styled-components";
import {
  FlexRow,
  FlexColumn,
  H1,
  P,
  Label,
  Input,
  Icon,
  CountryPhone,
  Checkbox,
} from "notes";
import { Drawer } from "Components";
import { useFanEventContext, useFanModalContext } from ".";
import { PayButton } from "Hooks";
import * as nextsong from "@musicaudienceexchange/nextsong-interface";
import { stripePromise } from ".";
import { Elements } from "@stripe/react-stripe-js";
import parse from "html-react-parser";
import { defaultLeadFields } from "./defaultFormContent";
import { FormField } from "@musicaudienceexchange/nextsong-interface/lib/esm/firestore/customizations";
import { useLocationContext } from "Context";

export const ModalLeadCollection = ({ open }) => {
  const { setModalState } = useFanModalContext();
  return (
    <Drawer open={open} onClose={() => setModalState(null)}>
      <Elements stripe={stripePromise}>
        <LeadCollection />
      </Elements>
    </Drawer>
  );
};

interface UserDetailsProps {
  name?: string;
  email?: string;
  phone?: string;
  zipcode?: string;
  consent?: boolean;
}

export const LeadCollection = () => {
  const { setModalState, modalState } = useFanModalContext();
  const { playlist, event } = useFanEventContext();
  const { location, handleLocationRequest } = useLocationContext();
  const [userDetails, setUserDetails] = useState<UserDetailsProps>({});
  const [error, setError] = useState(null);
  const { songId, description, modalTitle } = modalState?.leadCollection;
  const selectedSong = playlist?.find(({ _id }) => _id === songId);
  const type = songId ? "encore" : "checkIn";

  useEffect(() => {
    handleLocationRequest();
  }, []);

  const handleError = (error) => {
    const fields = error?.fields;
    if (!!fields?.length) {
      setError(fields);
    }
  };

  const handleChange = (v, e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (!!error && error.includes(name)) {
      let updatedError = error?.filter((error) => error !== name);
      setError(updatedError);
    }
    setUserDetails({ ...userDetails, [name]: value });
  };

  const extraFields =
    userDetails &&
    Object.keys(userDetails)?.reduce((map, key) => {
      const isExtra = !["name", "email", "phone", "zipcode"].includes(key);
      if (isExtra) {
        map[key] = userDetails[key];
      }
      return map;
    }, {});

  let payment: nextsong.functions.Payment = {
    extraFields,
    amount: 0,
    songId,
    type,
    eventId: event?._id,
    email: userDetails.email || "",
    name: userDetails.name || "",
    phone: userDetails.phone || "",
    zipcode: userDetails.zipcode || "",
  };

  if (!!location && !!Object.keys(location)?.length) {
    payment = {
      ...payment,
      coordinates: location,
    };
  }

  const handleStringVariables = (text: string) => {
    let updatedText = text;
    if (updatedText?.includes("{artist}")) {
      updatedText = updatedText.replace("{artist}", event?.artistName);
    }
    return updatedText;
  };

  const renderField = (field) => {
    const { label: uneditedLabel, name, type, onChange, placeholder } = field;
    const label = handleStringVariables(uneditedLabel);
    const value = userDetails[name];
    const hasError = error?.includes(name);
    const fieldUI = (type) => {
      switch (type) {
        case "text":
          return (
            <Input
              name={name}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              error={!!hasError}
            />
          );
        case "email":
          return (
            <Input
              name={name}
              leftIcon={<Icon form name="Email" />}
              placeholder={placeholder ?? "john@email.com"}
              value={value}
              onChange={onChange}
              error={!!hasError}
            />
          );
        case "phone":
          return (
            <CountryPhone
              name={name}
              value={value ?? ""}
              onChange={(value) => {
                setUserDetails({ ...userDetails, [name]: value });
                let updatedError = error?.filter((error) => error !== name);
                setError(updatedError);
              }}
              error={!!hasError}
            />
          );
        case "zipcode":
          return (
            <Input
              name={name}
              placeholder={"5-digit code..." ?? placeholder}
              value={value}
              onChange={onChange}
              leftIcon={<Icon form name="Marker" />}
              maxLength={5}
              error={!!hasError}
            />
          );
        case "checkbox":
          return (
            <FlexRow yCenter style={{ marginTop: "16px" }}>
              <Label style={{ flexFlow: "row nowrap" }}>
                <StyledCheckbox
                  name={name}
                  checked={value}
                  onChange={(value) => {
                    setUserDetails({ ...userDetails, [name]: value });
                    let updatedError = error?.filter((error) => error !== name);
                    setError(updatedError);
                  }}
                />
                <LegalText>{parse(label)}</LegalText>
              </Label>
            </FlexRow>
          );
        default:
          break;
      }
    };
    return (
      <Fragment key={name}>
        {type !== "checkbox" && <Label>{label}</Label>}
        {fieldUI(type)}
      </Fragment>
    );
  };

  const fieldOverrides =
    event?.type === "encore"
      ? event?.customizations?.encore?.leadForm
      : event?.checkIn?.form;

  const fields: FormField[] = !fieldOverrides
    ? defaultLeadFields
    : fieldOverrides;

  const requiredFields = fields?.filter(
    (field) => !!field?.required && !userDetails[field.name]
  );

  const form = fields
    ?.sort((a, b) => (a.order > b.order ? 1 : -1))
    ?.map((field) => renderField({ ...field, onChange: handleChange }));

  return (
    <Wrapper>
      <H1>{modalTitle ?? "Cast Your Vote!"}</H1>
      {description ? (
        <P>{description}</P>
      ) : (
        <P>
          Provide the following details to cast your vote
          {selectedSong?.title ? (
            <>
              {" "}
              for<span> {selectedSong?.title}!</span>
            </>
          ) : (
            "!"
          )}
        </P>
      )}
      <Form>{form}</Form>
      <PayButton
        disabled={requiredFields?.length > 0}
        payment={payment}
        onPaymentComplete={() => setModalState(null)}
        onPaymentError={(error) => handleError(error)}
      >
        {event?.checkIn?.modalActionText ?? "Vote"}
      </PayButton>
    </Wrapper>
  );
};

const StyledCheckbox = styled(Checkbox)`
  & > input:checked + div {
    background-color: ${(props) => props.theme.branding.colors.action};
    border-color: ${(props) => props.theme.branding.colors.action};
  }
`;

const LegalText = styled.div`
  display: inline-block;
  a,
  a:hover {
    color: ${(props) => props.theme.branding.colors.action};
  }
`;

const Form = styled(FlexColumn)`
  margin-bottom: 32px;
  & > ${Label} {
    margin-top: 16px;
    margin-bottom: 4px;
  }
`;

const Wrapper = styled(FlexColumn)`
  padding: 16px 24px 40px 24px;
  ${H1} {
    font-size: 25px;
    line-height: 32px;
    margin-bottom: 4px;
  }
  ${H1} + ${P} {
    font-size: 15px;
    color: ${(props) => props.theme.palette.gray.primary};
    span {
      font-weight: 400;
    }
  }
`;
