import haversine from "Utils/haversine";
import { useBrandingContext } from "Context";
import { firestore } from "firebase-internal";
import { collection, query } from "firebase/firestore";
import { useCollectionModel, DocumentModel, whereModel } from "Hooks/models";
import * as nextsong from "@musicaudienceexchange/nextsong-interface";
import { useUser } from "auth";
export type EventModel = DocumentModel<nextsong.firestore.Event>;

export const useEventList = (location?: nextsong.firestore.Coordinates) => {
  const {
    claims: { isAdmin },
  } = useUser();
  const { groupId, brandingId } = useBrandingContext();

  let q = query(collection(firestore, "nextsong_events"));

  if (!isAdmin) {
    q = query(
      q,
      whereModel(nextsong.firestore.Event, "endedAt", "==", null),
      whereModel(nextsong.firestore.Event, "status", "==", "published")
    );
  }

  if (!isAdmin && groupId) {
    q = query(
      q,
      whereModel(nextsong.firestore.Event, "groupId", "==", groupId)
    );
  }

  if (!isAdmin && brandingId) {
    q = query(
      q,
      whereModel(nextsong.firestore.Event, "brandingId", "==", brandingId)
    );
  }

  const [eventsWithinTimeframe, eventsLoading] = useCollectionModel(
    nextsong.firestore.Event,
    q
  );

  let timeSortedEvents: EventModel[] = eventsWithinTimeframe
    ?.map((event) => ({ ...event, startsAt: event.startsAt.toLocal() }))
    ?.sort((a, b) => {
      return a.startsAt > b.startsAt ? 1 : -1;
    });

  if (location && Object.keys(location).length === 2) {
    timeSortedEvents = eventsWithinTimeframe
      ?.map((event) => {
        const distance = haversine(event.coordinates, location, {
          unit: "meter",
        });
        return { ...event, distance };
      })
      .sort((a, b) => {
        return a.distance > b.distance ? 1 : -1;
      });
  }

  return { events: timeSortedEvents, eventsLoading };
};
