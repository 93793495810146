import { useState } from "react";
import styled from "styled-components";
import { useTimeContext } from "Context";
import { FlexColumn, H3, Subheader, Input, Icon, FlexRow, P } from "notes";
import { textCompare } from "Utils";
import {
  useFanEventContext,
  useFanModalContext,
  ActionButton,
} from "./Components";
import {
  getTimeSince,
  Remove,
  SongWrapper,
  ListWrapper,
  ArtistName,
  LastPlayed,
} from "./Playlist";

export const SponsorList = (props) => {
  const { event, playlist, myRequests } = useFanEventContext();
  const { setModalState } = useFanModalContext();
  const [search, setSearch] = useState("");
  const { time } = useTimeContext();

  const songs = playlist?.filter(({ encore }) => !encore);

  const handleClick = (songId) => {
    setModalState({
      sponsor: {
        type: "sponsor",
        songId,
      },
    });
  };

  const overrides = event?.customizations?.sponsor;

  return (
    <ListWrapper {...props}>
      <FlexColumn>
        <H3>
          {event?.customizations?.sponsor?.title ??
            "SPONSOR AND DEDICATE A SONG"}
        </H3>
        <Subheader>
          {event?.customizations?.sponsor?.description ??
            `Want to say thanks, congrats, happy birthday or I love you to someone
          at the show? Choose a song to sponsor, and you can ask the artist to
          incorporate a message along with playing the song.`}
        </Subheader>
        <Input
          value={search}
          onChange={setSearch}
          placeholder="Search for a song by name..."
          leftIcon={<Icon form name="Search" />}
          style={{ marginTop: "16px" }}
          rightIcon={
            search?.length > 0 ? (
              <Remove
                form
                name="RemoveCircle"
                onClick={() => {
                  setSearch("");
                }}
              />
            ) : undefined
          }
        />
      </FlexColumn>
      <List>
        {songs
          ?.filter(({ title, artist }) => textCompare(title, artist, search))
          .sort((a, b) => a.title.localeCompare(b.title))
          .map(({ _id, title, artist, playedAt, requests }, index) => {
            const hasPlayed = playedAt?.length > 0;
            const maxSponsors = event?.maxSponsorshipCount || 3;
            const hasMaxRequests = requests?.length >= maxSponsors;
            const isSponsoredByMe = myRequests?.find(
              ({ songId }) => songId === _id
            );
            const { minutes } = getTimeSince(
              playedAt[playedAt?.length - 1],
              time
            );
            return (
              <SongWrapper key={index} played={hasPlayed}>
                <FlexColumn flex="1 1 100%" xStart>
                  <Title yCenter>
                    <P>{title}</P>
                  </Title>

                  <ArtistName style={{ width: hasPlayed ? "80%" : "100%" }}>
                    {artist}
                  </ArtistName>
                </FlexColumn>
                <FlexColumn flex="1 1 auto" xEnd>
                  <ActionButton
                    isReadOnly={hasMaxRequests || !!isSponsoredByMe}
                    played={hasPlayed}
                    disabled={hasPlayed || hasMaxRequests || !!isSponsoredByMe}
                    onClick={() => !hasPlayed && handleClick(_id)}
                  >
                    {!!hasPlayed ? (
                      "Played"
                    ) : !!isSponsoredByMe ? (
                      overrides?.actionCompleteText ?? "Sponsored by You"
                    ) : hasMaxRequests ? (
                      overrides?.actionUnavailableText ?? "Sponsored"
                    ) : (
                      <>
                        <Icon form name="Ribbon" />{" "}
                        {overrides?.actionText ?? "Sponsor"}
                      </>
                    )}
                  </ActionButton>
                  {!!hasPlayed && (
                    <LastPlayed>
                      Played{" "}
                      {minutes > 59
                        ? `more than 1 hr. ago`
                        : minutes < 1
                        ? "less than 1 min. ago"
                        : `${minutes} min. ago`}
                    </LastPlayed>
                  )}
                </FlexColumn>
              </SongWrapper>
            );
          })}
      </List>
    </ListWrapper>
  );
};

const List = styled(FlexColumn)`
  width: 100%;
`;

const Title = styled(FlexRow)`
  flex-grow: 1;
`;
