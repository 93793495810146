import { useState } from "react";
import styled from "styled-components";
import { useTimeContext } from "Context";
import {
  FlexColumn,
  H3,
  Subheader,
  Icon,
  Footnote,
  FlexRow,
  P,
  Input,
} from "notes";
import { Duration, DateTime } from "luxon";
import { textCompare } from "Utils";
import {
  useFanEventContext,
  useFanModalContext,
  ActionButton,
} from "./Components";

export const getTimeSince = (
  fbDate: DateTime,
  time: DateTime
): { minutes?: number; hours?: number } => {
  if (!fbDate || !time) {
    return {};
  }
  const requestedAt = fbDate;
  const timeSince = Duration?.fromMillis(
    time?.toMillis() - requestedAt?.toMillis()
  );
  return {
    minutes: parseInt(timeSince?.toFormat("m")),
    hours: parseInt(timeSince?.toFormat("h")),
  };
};

export const Playlist = () => {
  const { event, playlist: songs } = useFanEventContext();
  const { setModalState } = useFanModalContext();
  const [search, setSearch] = useState("");
  const { time } = useTimeContext();

  const handleClick = (songId, playedAt) => {
    setModalState({
      vote: {
        songId,
        modalTitle: playedAt?.length > 0 ? "Repeat A Song" : "Request A Song",
      },
    });
  };

  const overrides = event?.customizations?.catalog;

  return (
    <ListWrapper>
      <FlexColumn>
        <H3>{overrides?.title ?? "Our Catalog"}</H3>
        <Subheader>
          {overrides?.description ??
            `Here are the songs you can request ${event?.artistName} to play. Search
          through the list to find the songs you most want to hear!`}
        </Subheader>
        <Input
          value={search}
          onChange={setSearch}
          placeholder="Search for a song by name or artist..."
          leftIcon={<Icon form name="Search" />}
          style={{ marginTop: "16px" }}
          rightIcon={
            search?.length > 0 ? (
              <Remove
                form
                name="RemoveCircle"
                onClick={() => {
                  setSearch("");
                }}
              />
            ) : undefined
          }
        />
      </FlexColumn>
      <List>
        {songs
          ?.filter(({ title, artist }) => textCompare(title, artist, search))
          .sort((a, b) => a.title.localeCompare(b.title))
          .map(
            ({ _id: songId, title, artist, playedAt, accumAmount }, index) => {
              const hasPlayed = playedAt?.length > 0;
              const { minutes } =
                hasPlayed && getTimeSince(playedAt[playedAt?.length - 1], time);
              return (
                <SongWrapper key={index} played={hasPlayed}>
                  <FlexColumn flex="1 1 100%" xStart>
                    <Title yCenter>
                      <P>{title}</P>
                    </Title>
                    <ArtistName style={{ width: hasPlayed ? "80%" : "100%" }}>
                      {artist}
                    </ArtistName>
                  </FlexColumn>
                  <FlexColumn flex="1 1 auto" xEnd>
                    <ActionButton
                      played={hasPlayed}
                      onClick={() => handleClick(songId, playedAt)}
                    >
                      {hasPlayed && <Icon tertiary name="Refresh" />}
                      {!!hasPlayed
                        ? overrides?.actionRepeatText ?? "Repeat"
                        : overrides?.actionText ?? "Request"}
                    </ActionButton>
                    {!!hasPlayed && (
                      <LastPlayed>
                        Played{" "}
                        {minutes > 59
                          ? `more than 1 hr. ago`
                          : minutes < 1
                          ? "less than 1 min. ago"
                          : `${minutes} min. ago`}
                      </LastPlayed>
                    )}
                  </FlexColumn>
                </SongWrapper>
              );
            }
          )}
      </List>
    </ListWrapper>
  );
};

export const ListWrapper = styled(FlexColumn)`
  padding: 24px 0 80px 16px;
  & > ${FlexColumn}:first-of-type {
    padding: 0 16px 16px 0;
  }
  ${H3} {
    color: ${(props) => props.theme.branding.event.colors.title};
    font-size: 17px;
    font-family: ${(props) => props.theme.branding.headerFont};
    font-weight: 500;
    line-height: 24px;
    text-transform: uppercase;
  }
  ${Subheader} {
    color: ${(props) => props.theme.branding.event.colors.description};
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
  }
`;

const List = styled(FlexColumn)`
  width: 100%;
`;

const Title = styled(FlexRow)`
  flex-grow: 1;
`;

export const LastPlayed = styled(Footnote)`
  color: ${(props) => props.theme.branding.event.colors.listSubtitle};
  display: flex;
  justify-content: flex-end;
  text-align: right;
  white-space: nowrap;
  width: 84px;
`;

export const ArtistName = styled(Footnote)`
  color: ${(props) => props.theme.branding.event.colors.listSubtitle};
  font-style: normal;
  font-weight: 600;
`;

export const SongWrapper = styled(FlexRow)`
  align-items: flex-start;
  padding: 18px 16px 18px 0;
  width: 100%;
  & + & {
    border-top: 1px solid
      ${(props) => props.theme.branding.event.colors.listDivider};
  }
  & > ${FlexRow} {
    margin-bottom: 4px;
  }
  ${P} {
    color: ${(props) => props.theme.branding.event.colors.listTitle};
    font-size: 15px;
    line-height: 19px;
    font-weight: 600;
  }
  ${Footnote} {
    margin-top: 4px;
  }
`;

export const Remove = styled(Icon)`
  path {
    fill: ${(props) => props.theme.branding.colors.input.placeholder};
  }
`;
