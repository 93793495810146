import styled from "styled-components";
import ReactPlayer from "react-player";
import { forwardRef } from "react";

interface VideoProps {
  path: string | string[];
  thumbnail?: string;
  play?: boolean;
  onEnded?: () => void;
  onProgress?: ({ played: number }) => void;
  onPause?: (val: boolean) => void;
  loop?: boolean;
  mute?: boolean;
  className?: string;
  volume?: number;
  ref?: any;
  children?: any;
}

export const VideoBackground = ({ className, ...props }: VideoProps) => {
  return (
    <VideoContainer className={className}>
      <VideoPlayer {...props} />
    </VideoContainer>
  );
};

const VideoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
`;

export const VideoPlayer = forwardRef<HTMLDivElement, VideoProps>(
  (
    {
      play = false,
      path,
      loop = false,
      mute = true,
      onEnded,
      thumbnail,
      volume,
      ...props
    },
    ref
  ) => {
    const handleEnd = () => {
      !!onEnded && onEnded();
    };

    const options = {
      controls: false,
      disablekb: 1,
      loop: loop,
      muted: mute,
      playing: play,
      url: path,
      volume: mute ? 0 : volume ?? 1,
      width: "100%",
      height: "100%",
      playsinline: true,
      config: {
        file: {
          hlsVersion: "0.14.17",
          hlsOptions: {
            maxMaxBufferLength: 10,
          },
          attributes: {
            // <video> element attributes
            poster: thumbnail ?? null,
          },
        },
      },
      onEnded: handleEnd,
    };

    return <StyledReactPlayer ref={ref} {...options} {...props} />;
  }
);

const StyledReactPlayer = styled(ReactPlayer)`
  video {
    outline: none;
    object-fit: cover;
  }
`;
