import styled, { css } from "styled-components";
import { FlexColumn, FlexRow, Modal, ModalContainer, P, Icon } from "notes";
import { useEffect } from "react";
interface PageModalProps {
  open: boolean;
  children: any;
  title?: string;
  onBack?: () => void;
  isQR?: boolean;
}

export const PageModal: React.FC<PageModalProps> = ({
  open = false,
  children,
  title = "",
  onBack,
  isQR = false,
}) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else document.body.style.overflow = "auto";
  }, [open]);

  return (
    <Wrapper size="small" open={open} setOpen={() => {}}>
      <Chrome yCenter>
        <BackArrow
          name="Left"
          onClick={() => {
            !!onBack && onBack();
          }}
        />
        <P>{title}</P>
      </Chrome>
      <Content isQR={isQR}>{children}</Content>
    </Wrapper>
  );
};

const Wrapper = styled(Modal)`
  ${ModalContainer} {
    padding: 0;
    position: absolute;
    bottom: 0;
    max-width: 100%;
    overflow-y: auto;
    & > svg {
      display: none;
    }
    height: 100%;
    min-height: 100vh;
    @supports (-webkit-touch-callout: none) {
      min-height: -webkit-fill-available;
    }
  }
`;

const Content = styled(FlexColumn)`
  width: 100%;
  ${(props) =>
    props.isQR &&
    css`
      padding: 24px;
      align-items: center;
    `};
`;

const Chrome = styled(FlexRow)`
  background-color: #ffffff;
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.lightest};
  flex: 0 0 40px;
  padding: 0 16px;
  position: sticky;
  top: 0;
  ${P} {
    font-weight: 700;
    margin-left: 20px;
  }
`;

const BackArrow = styled(Icon)`
  color: ${(props) => props.theme.palette.black};
  width: 24px;
  height: 24px;
  margin: -1px;
`;
