import * as nextsong from "@musicaudienceexchange/nextsong-interface";
import { DocumentModel } from "Hooks/models";
import {
  RequestProps,
  UpdatedEventSongModel,
} from "../Routes/Artist/Components";
type EventSongModel = DocumentModel<nextsong.firestore.EventSong>;

export const updateRequests = (
  songs: EventSongModel[],
  requests: RequestProps[],
  isFan: boolean = false
) => {
  const songsArray = songs?.map((song) => {
    const songRequests = requests?.filter((req) => req.songId === song._id);

    const isRepeatRequest = false;
    const lastRequestedAt = songRequests?.sort((a, b) =>
      a.createdAt > b.createdAt ? 1 : -1
    )[songRequests.length - 1]?.createdAt;

    const uniqueNames: string[] = Array.from(new Set(songRequests))
      .map((req) => songRequests?.find((a) => a?.uid === req?.uid))
      ?.reduce((a, b) => !!b?.name && a.concat([b.name]), []);

    const repeatRequests =
      song.playedAt &&
      song.playedAt?.length > 0 &&
      songRequests?.filter(
        (request) => request.createdAt > song.playedAt[song.playedAt.length - 1]
      );

    const accumAmount = songRequests?.reduce((a, b) => a + b.amount, 0);

    const updatedSong: UpdatedEventSongModel = {
      ...song,
      accumAmount,
      isRepeatRequest,
      lastRequestedAt,
      requests: songRequests,
      uniqueNames,
    };

    if (repeatRequests?.length > 0) {
      updatedSong.isRepeatRequest = true;
      updatedSong.playedAt = [];
      if (isFan) {
        updatedSong.requests = repeatRequests;
        updatedSong.accumAmount = repeatRequests?.reduce(
          (a, b) => a + b.amount,
          0
        );
      }
    }

    return updatedSong;
  });
  return songsArray;
};
