import styled from "styled-components";
import Joi from "@hapi/joi";
import { useState } from "react";
import { useAppContext } from "Context";
import {
  FlexColumn,
  Label,
  Input,
  FieldMessage,
  TextArea,
  Icon,
  Button,
  Modal,
  ModalContainer,
  H3,
} from "notes";
import { validate } from "Utils";
import { addFirestoreDoc } from "firebase-internal";
import { useUser } from "auth";

export const ModalSchedule = ({ open, setOpen }) => {
  return (
    <Wrapper open={open} setOpen={setOpen} size="small">
      <Schedule setOpen={setOpen} />
    </Wrapper>
  );
};

const formSchema = Joi.object({
  name: Joi.string().min(1).required(),
  email: Joi.string().email({ tlds: false }).required(),
  artist: Joi.string().min(1).required(),
  message: Joi.string().min(3).required(),
});

export const Schedule = ({ setOpen }) => {
  const { notify } = useAppContext();
  const { user } = useUser();
  const [form, setForm] = useState({
    type: "artist",
    name: "",
    email: "",
    artist: "",
    message: "",
    uid: user?.uid ?? "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (value, name) => {
    setForm({ ...form, [name]: value });
    delete errors[name];
    setErrors(errors);
  };

  const handleClose = () => {
    setErrors(false);
    setLoading(false);
    setOpen(false);
    setForm({
      type: "artist",
      name: "",
      email: "",
      artist: "",
      message: "",
      uid: "",
    });
  };

  const handleSubmit = async (form) => {
    const isError = validate(form, formSchema);
    setLoading(true);
    console.log("error", isError);
    if (!isError) {
      setLoading(true);
      const docRef = await addFirestoreDoc("nextsong_feedback", form);
      if (docRef.id) {
        notify("Message Sent");
        handleClose();
      }
    } else {
      setErrors(isError);
      setLoading(false);
    }
  };

  return (
    <>
      <H3>Schedule Demo</H3>
      <Form>
        <FlexColumn>
          <Label>Name</Label>
          <Input
            onChange={(value) => handleChange(value, "name")}
            placeholder="First and last name..."
            value={form.name}
            error={!!errors["name"]}
          />
          <FieldMessage
            active={!!errors["name"]}
            content="This is a required field and cannot be blank."
          />

          <Label>Email address</Label>
          <Input
            leftIcon={<Icon form name="Email" />}
            onChange={(value) => handleChange(value, "email")}
            placeholder="john@email.com"
            value={form.email}
            error={!!errors["email"]}
          />
          <FieldMessage
            active={!!errors["email"]}
            content="This is a required field and cannot be blank."
          />

          <Label>Artist / Band Name</Label>
          <Input
            onChange={(value) => handleChange(value, "artist")}
            placeholder="Stage name..."
            value={form.artist}
            error={!!errors["artist"]}
          />
          <FieldMessage
            active={!!errors["email"]}
            content="This is a required field and cannot be blank."
          />
        </FlexColumn>
        <Label>Message</Label>
        <TextArea
          onChange={(e) => handleChange(e.target.value, "message")}
          placeholder="Please provide a brief description of your band..."
          value={form.message}
          error={!!errors["message"]}
          style={{ minHeight: "102px" }}
        />
        <FieldMessage
          active={!!errors["message"]}
          content="This is a required field and cannot be blank."
        />

        <Button
          loading={loading}
          disabled={loading}
          onClick={() => handleSubmit(form)}
          style={{ marginTop: "40px" }}
        >
          Submit
        </Button>
      </Form>
    </>
  );
};

const Wrapper = styled(Modal)`
  ${ModalContainer} {
    border-radius: 8px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 40px;
    min-height: initial;
    width: calc(100% - 32px);
    padding: 24px;
    & > svg {
      top: 24px;
      right: 24px;
      width: 20px;
      height: 20px;
    }
    ${H3} {
      font-family: Poppins, Arial, sans-serif;
      font-size: 25px;
      font-weight: 600;
      line-height: 28px;
    }
  }
`;

const Form = styled(FlexColumn)`
  ${Label} {
    margin-top: 24px;
    margin-bottom: 4px;
  }
`;
