import styled from "styled-components";
import { FlexColumn } from "notes";
export const TermsContent = () => {
  return (
    <Wrapper>
      <p>
        Welcome to NEXTSONG.LIVE (“NS”), wholly owned properties of MUSIC
        AUDIENCE EXCHANGE, INC., a Delaware corporation (“MAX”). We are excited
        to have you here. This document acts as our official terms and
        conditions (“Terms”) for services and products used or provided by MAX
        through NS (“Sites”). It is a legal contract between you, an individual
        user of at least 13 years of age or a single entity (“you,” “User”,
        “user” or, collectively, “Users”, “users”), and MAX regarding your use
        of our services and the purchase of products or services from the Sites.
        This agreement governs all purchases from the Sites. By using the Sites,
        you acknowledge and accept these Terms.
      </p>
      <p>
        Please read these Terms carefully because they govern your use of our
        services, accessible via the Sites, which enable you to participate,
        support, or interact with Events.
      </p>
      <p>
        <strong>Agreement to Terms</strong>
      </p>
      <p>
        By using our Services, you agree to be bound by these Terms. If you
        don't agree to these Terms, do not use the Services.
      </p>
      <p>
        <strong>Changes to Terms or Services</strong>
      </p>
      <p>
        We may modify the Terms at any time, at our sole discretion. If we do
        so, we'll let you know either by posting the modified Terms on the Sites
        or through other communications. It is important that you review any
        modified Terms because, if you continue to use the Services after we
        have posted modified Terms, you're indicating to us that you agree to be
        bound by the modified Terms. If you don't agree to be bound by the
        modified Terms then you may no longer use the Services. Because our
        Services are evolving over time, we may change or discontinue all or any
        part of the Services, at any time and without notice, at our sole
        discretion.
      </p>
      <p>
        <strong>Who May Use the Services</strong>
      </p>
      <p>
        <strong>Eligibility</strong>
      </p>
      <p>
        You may use the Services only if you are 13 years or older and are not
        barred from using the Services under applicable law.
      </p>
      <p>
        <strong>Using the Services; Payment Terms</strong>
      </p>
      <p>
        Through the Services, you'll be able to find music, artist related live
        and recorded content, and various other Events hosted and operated by
        MAX, NS, STS, or third parties at various locations (each such location,
        a "Venue"). When you make a purchase through the Sites, you represent
        that: (i) any credit card information you supply is true and complete;
        and (ii) you'll pay the charges incurred by you at the posted prices,
        including any applicable taxes. You agree that you will not attempt to
        evade, avoid, or circumvent any refund prohibitions in any manner with
        regard to tickets you purchased. You will not dispute or otherwise seek
        a "chargeback" from the company whose credit card you used to purchase
        tickets through the Sites. Should you do so, your tickets may be
        cancelled, and we may, in our sole discretion, refuse to honor pending
        and future ticket purchases made from all credit card accounts or online
        accounts on which such chargebacks have been made, and may prohibit all
        persons in whose name the credit card accounts exist and any person who
        accesses any associated online account or credit card or who otherwise
        breaches this provision from using our Services. You are solely
        responsible for ensuring that you have selected the correct Event for
        which you are purchasing a ticket. Except as otherwise stated in MAX's
        Refund Policy below, after your purchase of a ticket for a Paid Event is
        processed, the transaction is final, and MAX will have no liability for
        any disputes related to tickets purchased through the Sites.
      </p>
      <p>
        Purchases from MAX through the Services may not be exempt from sales or
        use taxes simply because they are made from the Internet or because MAX
        is not required to collect sales or use tax by any particular state or
        region. Whether any sales tax will be collected on a given purchase and
        the amount of tax charged depends on a number of factors including
        whether the seller is subject to tax in a given jurisdiction. The
        purchaser is responsible for any applicable taxes not collected by MAX
        and certain states require purchasers to file a sales/use tax return
        annually reporting taxable purchases that were not taxed and to pay such
        tax. For details, see the website of, or otherwise contact, the
        applicable taxing authority. If any sales tax will be collected directly
        by MAX in connection with a purchase, that amount will be shown either
        prior to the completion of any purchase or reflected in the final
        confirmation of that purchase.
      </p>
      <p>
        <strong>Refund Policy</strong>
      </p>
      <p>
        By purchasing Services (provided the Event is not cancelled) through our
        Sites, you are purchasing a limited, non-transferable, revocable license
        to participate in the Event. Further, by purchasing Services you agree
        and are aware that these purchases and contributions are non-refundable
        and non-returnable, except in the event of an Event cancellation. The
        license represented by the purchase may be terminated immediately if
        your Account is terminated for any reason, in MAX's sole and absolute
        discretion, or if MAX discontinues providing the Services.
      </p>
      <p>
        <strong>Cancellations</strong>
      </p>
      <p>
        On occasion, Events may be canceled or postponed by the artist or Event
        promoter. Should this occur, we will provide a refund of ticket fees or
        charges and any pre-Event artist services charges.
      </p>
      <p>
        If a refund is provided, the value paid as well as the service fee and
        any applicable taxes will be refunded. If a refund is issued, it will be
        issued using the same method of payment that was used to purchase the
        original Services. If a credit card was used to make the refunded
        purchase, then only that actual credit card will receive the credit for
        the refund. MAX will not be liable for travel or any other expenses that
        you or anyone else incurs in connection with a canceled or postponed
        Event. The refund will be processed and issued within 30 business days
        from the date of the cancellation notice, or as soon thereafter as is
        administratively practical.
      </p>
      <p>
        <strong>Events</strong>
      </p>
      <p>
        All Events are organized and operated by either MAX or third parties. In
        the case where an Event is organized by a third party, MAX, NS, SL, and
        STS have no part in such organization or operation and such Event
        organizers and operators and/or Venue owners may have additional terms
        and conditions governing your attendance at an Event. You agree to
        comply with any such terms and conditions when you attend Events.
      </p>
      <p>
        <strong>Feedback</strong>
      </p>
      <p>
        We welcome feedback, comments and suggestions for improvements to the
        Services ("Feedback"). You can submit Feedback by emailing us at
        support@set.live. You agree that all Feedback will be the sole and
        exclusive property of MAX and you hereby irrevocably assign to MAX and
        agree to irrevocably assign to MAX all of your right, title, and
        interest in and to all Feedback, including, without limitation, all
        worldwide patent rights, copyright rights, trade secret rights, and
        other proprietary or intellectual property rights therein. At MAX's
        request and expense, you will execute documents and take such further
        acts as MAX may reasonably request to assist MAX to acquire, perfect,
        and maintain its intellectual property rights and other legal
        protections for the Feedback.
      </p>
      <p>
        <strong>Privacy Policy</strong>
      </p>
      <p>
        Please refer to our Privacy Policy (
        <strong>
          <a href="https://max.live/privacy-policy">
            https://max.live/privacy-policy
          </a>
        </strong>
        ) for information on how we collect, use and disclose information from
        our users.
      </p>
      <p>
        <strong>Content and Content Rights</strong>
      </p>
      <p>
        For purposes of these Terms: (i) "Content" means, without limitation,
        all video (and the sound recordings and musical compositions embodied
        therein), artwork, photographs, illustrations, graphics, logos, copy,
        lyrics, text, computer code, data, user interfaces, visual interfaces,
        information, materials, and all copyrightable or otherwise legally
        protectable elements of the Services, including, without limitation, the
        design, selection, sequence, look and feel, and arrangement of the
        Services, and any copyrights, trademarks, service marks, trade names,
        trade dress, patent rights, database rights and/or other intellectual
        property and/or proprietary rights therein (including with respect to
        any content contained and/or made available in any advertisements or
        information presented to you via the Services); and (ii) "User Content"
        means any Content that Account holders (including you) provide to be
        made available through the Services. Content includes, without
        limitation, User Content.
      </p>
      <p>
        <strong>Content Ownership, Responsibility and Removal</strong>
      </p>
      <p>
        MAX does not claim any ownership rights in any User or other third-party
        Content that may be made available through the Services and nothing in
        these Terms will be deemed to restrict any rights that third parties may
        have to use and exploit their Content. Subject to the foregoing, MAX and
        its licensors exclusively own all right, title and interest in and to
        the Services and Content, including all associated intellectual property
        rights. You acknowledge that the Services and Content are protected by
        copyright, trademark, and other laws of the United States and foreign
        countries. You agree not to remove, alter, or obscure any copyright,
        trademark, service mark, or other proprietary rights notices
        incorporated in or accompanying the Services or Content.
      </p>
      <p>
        <strong>Digital Millennium Copyright Act.</strong>
      </p>
      <p>
        It is MAX's policy to respond to notices of alleged infringement that
        comply with the Digital Millennium Copyright Act (the “DMCA”). MAX will
        promptly terminate without notice any User's access to the Service if
        that User is determined to be a repeat infringer.
      </p>
      <p>
        If you are a copyright owner or an authorized agent thereof (the
        “Copyright Owner”) and believe that any Content infringes your
        copyrights, you may submit a notification pursuant to Title 17, United
        States Code, Section 512(c)(3), the DMCA. This notification of claimed
        infringement must be a written communication provided to the designated
        agent of MAX that includes the following information:
      </p>
      <p>
        (a) A physical or electronic signature of a person authorized to act on
        behalf of the Copyright Owner of an exclusive right that is allegedly
        infringed.
      </p>
      <p>
        (b) Identification of the copyrighted work claimed to have been
        infringed, or, if multiple copyrighted works are covered by a single
        notification, a representative list of such works.
      </p>
      <p>
        (c) Identification of the material that is claimed to be infringing or
        to be the subject of infringing activity and that is to be removed or
        access to which is to be disabled, and information reasonably sufficient
        to permit MAX to locate the material.
      </p>
      <p>
        Written notification of claimed infringement must be submitted to our
        Copyright Agent at [support@set.live]. You may also submit notifications
        pursuant to this Section by mail, to MAX Copyright Agent, Music Audience
        Exchange, Inc. 2595 Dallas Parkway Suite #440, Frisco, TX 75034. You
        acknowledge and agree that if you fail to comply with all of the
        requirements of this Section, your request to remove the material at
        issue may not be valid.
      </p>
      <p>
        If you believe that materials you uploaded or posted on the Services was
        removed (or to which access was disabled) is not infringing, or that you
        have the authorization from the Copyright Owner, the Copyright Owner's
        agent, or pursuant to applicable law, to make such materials available
        to MAX for use on the Services, you may send a counter-notice containing
        the following information to our Copyright Agent:
      </p>
      <p>
        (a) Your physical or electronic signature;(b) Identification of the
        material that has been removed or to which access has been disabled and
        the location at which the material appeared before it was removed or
        disabled;(c) A statement under penalty of perjury that you have a good
        faith belief that the material was removed or disabled as a result of a
        mistake or a misidentification of the material; and
      </p>
      <p>
        (d) Your name, address, telephone number, and, if applicable, e-mail
        address, and a statement that you shall accept service of process from
        the person who provided notification of the alleged infringement.
      </p>
      <p>
        If a counter-notice is received by our Copyright Agent, MAX will send a
        copy of the counter-notice to the original complaining party informing
        that person that it may replace the removed material or cease disabling
        it in 10 business days. Unless the Copyright Owner files an action
        seeking a court order against the provider of the material or the User,
        the removed material may be replaced, or access to it restored, in 10 to
        14 business days or more after receipt of the counter-notice, at MAX's
        sole discretion.
      </p>
      <p>
        <strong>Rights in User Content Granted by You</strong>
      </p>
      <p>
        By making any User Content available through the Services you hereby
        grant to MAX a non-exclusive, transferable, sublicensable, worldwide,
        royalty-free license to use, copy, modify, create derivative works based
        upon, publicly display, publicly perform and distribute copies of your
        User Content in connection with operating and providing the Services and
        Content to you and to other Account holders.
      </p>
      <p>
        You are solely responsible for all your User Content. You represent and
        warrant that you own all your User Content or you have all rights that
        are necessary to grant us the license rights in your User Content under
        these Terms. You also represent and warrant that neither your User
        Content, nor your use and provision of your User Content, nor any use of
        your User Content by MAX on or through the Services will infringe,
        misappropriate or violate a third party's intellectual property rights,
        or rights of publicity or privacy, or result in the violation of any
        applicable law or regulation.
      </p>
      <p>
        MAX may rely on advertisers to help fund the Services. You agree that
        MAX may display advertisements and promotions of all kinds in and with
        the Services. You understand that MAX may have agreements with third
        parties, including revenue sharing from advertising or other
        transactions. You understand that you are not entitled to any benefits
        received by MAX related to such advertisements or promotions. You
        further understand that you may not sell, upload, or display
        advertisements, promotions, or solicitations of business in and with the
        Services. MAX will always have the right to use and display your name
        and likeness for advertising, publicizing, and exploiting the Services.
      </p>
      <p>
        <strong>Content from Social Media and Networking Sites</strong>
      </p>
      <p>
        As part of the functionality of the Services, you may link your Account
        to an SNS Account by either: (i) providing your SNS Account login
        information to MAX through the Services; or (ii) allowing MAX to access
        your SNS Account, as permitted under the applicable terms and conditions
        that govern your use of each SNS Account. You agree that you won't
        disclose your SNS Account login information to MAX and/or grant MAX
        access to your SNS Account unless you are permitted to do so by the
        applicable SNS. MAX won't have any obligation to pay any fees or be
        subject to any usage limitations imposed by SNSes.
      </p>
      <p>
        By linking your SNS Account to your MAX Account, you understand that MAX
        will access, make available and store (if applicable) some of the
        content that you have provided to and stored in your SNS Account so that
        it is available on and through the Services via your Account. Unless
        otherwise specified in these Terms, all such content, if any, will be
        considered to be User Content for all purposes of these Terms. Depending
        on the SNS Accounts you choose and subject to applicable SNS privacy
        settings, personally identifiable information that you post to your SNS
        Accounts and information about your SNS friends will be available on and
        through your Account. Please note that if an SNS Account or associated
        service becomes unavailable or MAX's access to such SNS Account is
        terminated by the third party service provider, then the content from
        your SNS Account will no longer be available on and through the
        Services. PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE SNS ACCOUNT
        SERVICE PROVIDERS ASSOCIATED WTH YOUR SNS ACCOUNTS IS GOVERNED SOLELY BY
        YOUR AGREEMENT(S) WITH SUCH SNS ACCOUNT SERVICE PROVIDERS.
      </p>
      <p>
        <strong>Rights in Content Granted by MAX</strong>
      </p>
      <p>
        Except as expressly set forth herein, the Services are to be used solely
        for your noncommercial, non-exclusive, non-assignable, non-transferable
        and limited personal use and for no other purposes. You must not alter,
        delete or conceal any copyright, trademark, service mark or other
        notices contained on the Services. Except as authorized by these Terms,
        you acknowledge that you may not, or allow any third party (whether or
        not for your benefit or otherwise) to, reproduce, modify, create
        derivative works from, display, perform, publish, distribute,
        disseminate, broadcast or circulate to any third party (including,
        without limitation, on or via a third-party website or platform), or
        otherwise use any Content without the express, prior written consent of
        MAX. Any unauthorized or prohibited use of any Content may subject you
        to civil liability, criminal prosecution, or both, under applicable
        federal, state, local laws, or applicable foreign laws, rules,
        regulations and treaties.
      </p>
      <p>
        MAX requires Users to respect our and our licensors' copyrights,
        trademarks, and other intellectual property rights and shall
        aggressively enforce the same to the fullest extent of the law,
        including seeking criminal prosecution. We likewise respect the
        intellectual property of others. Moreover, the framing or scraping of or
        in-line linking to the Services or any Content contained thereon and/or
        the use of webcrawler, spidering or other automated means to access,
        copy, index, process and/or store any Content made available on or
        through the Services other than as expressly authorized by MAX and its
        licensors is prohibited.
      </p>
      <p>
        <strong>Social Sharing</strong>
      </p>
      <p>
        If you choose, you may share Content on certain of your SNS Accounts,
        via text messaging, and via email, through the social sharing
        functionality we've implemented through the Services.
      </p>
      <p>
        <strong>
          Electronic Communications, Text Message Alerts, and Notifications
        </strong>
      </p>
      <p>
        When you visit the Sites, use the Services or send e-mails to us, you
        are communicating with us electronically. By doing so, you consent to
        receive communications from us electronically. You agree that all
        agreements, notices, disclosures and other communications that we
        provide to you electronically satisfy any legal requirement that such
        communications be in writing.
      </p>
      <p>
        When you opt-in to the Services, we will send you a message to confirm
        your signup. Message and data rates may apply. Voluntarily providing
        your cellular number to MAX in connection with establishing an Account
        or otherwise using the Services constitutes prior express consent to be
        called or texted at the number provided. You may receive up to 10
        messages per month but will be determined by your level of activity on
        the site. Text "HELP" for help. Text "STOP" to cancel. You may also
        remove your phone number from your account and receive only
        communications via email.
      </p>
      <p>
        <strong>Usage Guidelines and MAX's Enforcement Rights</strong>
      </p>
      <p>
        <strong>Usage Guidelines</strong>
      </p>
      <p>
        We ask that you respect our community of users and our desire for such
        users to feel safe when using the Services, including any Content.
        Accordingly, you agree that you'll only use the Services in a manner
        that is consistent with these Terms, and in particular the terms below.
        If you use the Services in a manner that is inconsistent with these
        Terms we might have to suspend or terminate your Account.
      </p>
      <p>You agree not to do any of the following:</p>
      <ul>
        <li>
          Post, upload, publish, submit or transmit any Content that: (i)
          infringes, misappropriates or violates a third party's patent,
          copyright, trademark, trade secret, moral rights or other intellectual
          property rights, or rights of publicity or privacy; (ii) violates, or
          encourages any conduct that would violate, any applicable law or
          regulation or would give rise to civil liability; (iii) is fraudulent,
          false, misleading or deceptive; (iv) is defamatory, obscene,
          pornographic, vulgar or offensive; (v) promotes discrimination,
          bigotry, racism, hatred, harassment or harm against any individual or
          group; (vi) is violent or threatening or promotes violence or actions
          that are threatening to any person or entity; or (vii) promotes
          illegal or harmful activities or substances.
        </li>

        <li>
          Use, display, mirror or frame the Services, or any individual element
          within the Services, MAX's name, any MAX trademark, logo or other
          proprietary information, or the layout and design of any page or form
          contained on a page, without MAX's express written consent;
        </li>

        <li>
          Use the Services for any revenue generating endeavor, commercial
          enterprise or other purpose for which it is not designed or intended,
          including any unauthorized non-commercial marketing and promotional
          campaigns, target or mass solicitation campaigns, or political
          campaigning;
        </li>

        <li>
          Access, tamper with, or use non-public areas of the Services, MAX's
          computer systems, or the technical delivery systems of MAX's
          providers;
        </li>

        <li>
          Attempt to probe, scan, or test the vulnerability of any MAX system or
          network or breach any security or authentication measures;
        </li>

        <li>
          Avoid, bypass, remove, deactivate, impair, descramble or otherwise
          circumvent any technological measure implemented by MAX or any of
          MAX's providers or any other third party (including another User) to
          protect the Services or Content;
        </li>

        <li>
          Attempt to access or search the Services or Content or download
          Content from the Services through the use of any engine, software,
          tool, agent, device or mechanism (including spiders, robots, crawlers,
          data mining tools or the like) other than the software and/or search
          agents provided by MAX or other generally available third party web
          browsers;
        </li>

        <li>
          Send any unsolicited or unauthorized advertising, promotional
          materials, email, junk mail, spam, chain letters or other form of
          solicitation;
        </li>

        <li>
          Use any meta tags or other hidden text or metadata utilizing a MAX
          trademark, logo URL or product name without MAX's express written
          consent;
        </li>

        <li>
          Use the Services or Content for any commercial purpose or the benefit
          of any third party or in any manner not permitted by these Terms;
        </li>

        <li>
          Forge any TCP/IP packet header or any part of the header information
          in any email or newsgroup posting, or in any way use the Services or
          Content to send altered, deceptive or false source-identifying
          information;
        </li>

        <li>
          Attempt to decipher, decompile, disassemble or reverse engineer any of
          the software used to provide the Services or Content;
        </li>

        <li>
          Interfere with, or attempt to interfere with, the access of any user,
          host or network, including, without limitation, sending a virus,
          overloading, flooding, spamming, or mail-bombing the Services;
        </li>

        <li>
          Collect or store any personally identifiable information from the
          Services from other users of the Services without their express
          permission;
        </li>

        <li>
          Impersonate or misrepresent your affiliation with any person or
          entity;
        </li>

        <li>Violate any applicable law or regulation; or</li>

        <li>
          Encourage or enable any other individual to do any of the foregoing.
        </li>
      </ul>
      <p>
        <strong>MAX's Enforcement Rights</strong>
      </p>
      <p>
        Although we're not obligated to monitor access to or use of the Services
        or Content or to review or edit any Content, we have the right to do so
        for the purpose of operating the Services, to ensure compliance with
        these Terms or to comply with applicable law or other legal
        requirements. We reserve the right, but are not obligated, to remove or
        disable access to any Content, at any time and without notice,
        including, but not limited to, if we, at our sole discretion, consider
        any Content to be objectionable or in violation of these Terms. We have
        the right to investigate violations of these Terms or conduct that
        affects the Services. We may also consult and cooperate with law
        enforcement authorities to prosecute users who violate the law or other
        legal requirements.
      </p>
      <p>
        <strong>Links to Third Party Websites or Resources</strong>
      </p>
      <p>
        The Services may contain links to third-party websites or resources. We
        provide these links only as a convenience and are not responsible for
        the content, products or services on or available from those websites or
        resources or links displayed on such sites. You acknowledge sole
        responsibility for and assume all risk arising from, your use of any
        third-party websites or resources.
      </p>
      <p>
        <strong>Termination</strong>
      </p>
      <p>
        We may terminate your access to and use of the Services, at our sole
        discretion, at any time and without notice to you. You may cancel your
        Account at any time by sending an email to us at support@set.live. Upon
        any termination, discontinuation or cancellation of Services or your
        Account, all provisions of these Terms which by their nature should
        survive will survive, including, without limitation, ownership
        provisions, warranty disclaimers, limitations of liability, and dispute
        resolution provisions.
      </p>
      <p>
        <strong>Warranty Disclaimers</strong>
      </p>
      <p>
        The Services and Content are provided "AS IS," without warranty of any
        kind. Without limiting the foregoing, WE EXPLICITLY DISCLAIM ANY IMPLIED
        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET
        ENJOYMENT AND NON-INFRINGEMENT AND ANY WARRANTIES ARISING OUT OF COURSE
        OF DEALING OR USAGE OF TRADE. We make no warranty that the Services will
        meet your requirements or be available on an uninterrupted, secure, or
        error-free basis or be compatible or interoperable with your mobile
        device or any other hardware, software or equipment installed on or used
        in connection with your mobile device. We make no warranty regarding the
        quality, accuracy, timeliness, truthfulness, completeness or reliability
        of any Content.
      </p>
      <p>
        UNLESS OTHERWISEDISCLOSED, MAX IS NEITHER AN AGENT OF NOR IS CONNECTED
        OR AFFILIATED WITH ANY EVENT ORGANIZER OR OPERATOR OR VENUE OR OPERATOR
        OF ANY VENUE FOR ANY EVENT INCLUDED IN THE SERVICES OR FOR WHICH A USER
        HAS PURCHASED A TICKET THROUGH THE SERVICES. MAX MAKES NO WARRANTY OR
        REPRESENTATIONS OF ANY KIND REGARDING THE PERFORMANCE OR NON –
        PERFORMANCE OF ANY EVENT VENUE OR OPERATOR OF ANY VENUE IN CONNECTION
        WITH THE SERVICE, INCLUDING THE FAILURE TO HONOR TICKETS OR OTHER
        RESERVATIONS OR THE DELIVERY OF INCORRECT PRODUCTS AND/OR SERVICES.
      </p>
      <p>
        YOU ASSUME ALL RISKS RELATING TO YOUR ONLINE OR OFFLINE COMMUNICATIONS
        AND INTERACTIONS WITH OTHER USERS OF THE SERVICES AND WITH OTHER PERSONS
        WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE
        SERVICES. YOU UNDERSTAND THAT MAX DOES NOT SCREEN OR ENQUIRE INTO THE
        BACKGROUND OF ANY USERS OF THE SERVICES, NOR DOES MAX MAKE ANY ATTEMPT
        TO VERIFY THE STATEMENTS OF USERS OF THE SERVICES. MAX MAKES NO
        REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF USERS OF THE
        SERVICES. YOU AGREE TO TAKE REASONABLE PRECAUTIONS IN ALL COMMUNICATIONS
        AND INTERACTIONS WITH OTHER USERS OF THE SERVICES AND WITH OTHER PERSONS
        WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE
        SERVICES, PARTICULARLY IF YOU DECIDE TO MEET OFFLINE OR IN PERSON.
      </p>
      <p>
        <strong>Indemnity</strong>
      </p>
      <p>
        You indemnify and hold harmless MAX and its officers, directors,
        employee and agents, from and against any claims, disputes, demands,
        liabilities, damages, losses, and costs and expenses, including, without
        limitation, reasonable legal and accounting fees, arising out of or in
        any way connected with (i) your access to or use of the Services or
        Content, (ii) your User Content, or (iii) your violation of these Terms.
      </p>
      <p>
        <strong>Limitation of Liability</strong>
      </p>
      <p>
        NEITHER MAX NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR
        DELIVERING THE SERVICES OR CONTENT WILL BE LIABLE FOR ANY INCIDENTAL,
        SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS,
        LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR
        SYSTEM FAILURE, OR FOR ANY DAMAGES FOR PERSONAL OR BODILY INJURY OR
        EMOTIONAL DISTRESS, OR THE COST OF SUBSTITUTE SERVICES ARISING OUT OF OR
        IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE
        THE SERVICES OR CONTENT, OR FROM ANY COMMUNICATIONS, INTERACTIONS OR
        MEETINGS WITH OTHER USERS OF THE SERVICES OR OTHER PERSONS WITH WHOM YOU
        COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE SERVICES, WHETHER
        BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT
        LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT MAX HAS BEEN
        INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET
        FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. SOME
        JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR
        CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT
        APPLY TO YOU.
      </p>
      <p>
        IN NO EVENT WILL MAX'S TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION
        WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES OR
        CONTENT EXCEED THE AMOUNTS YOU'VE PAID TO MAX THROUGH YOUR USE OF THE
        SERVICES OR CONTENT OR ONE HUNDRED DOLLARS ($100), WHICHEVER IS GREATER.
        THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF
        THE BASIS OF THE BARGAIN BETWEEN SEATED AND YOU.
      </p>
      <p>
        <strong>Dispute Resolution</strong>
      </p>
      <p>
        Please read this Section carefully; it may significantly affect your
        legal rights, including your right to file a lawsuit in court.
      </p>
      <p>
        <strong>Governing Law</strong>
      </p>
      <p>
        These Terms and any action related thereto will be governed by the laws
        of the State of Texas without regard to its conflict of laws provisions.
      </p>
      <p>
        <strong>Agreement to Arbitrate</strong>
      </p>
      <p>
        MAX is available by email at [support@set.live] to address any concerns
        you may have regarding your use of the Services. Most concerns may be
        quickly resolved in this manner. Each of you and we agree to use best
        efforts to settle any dispute, claim, question, or disagreement directly
        through consultation and good faith negotiations which shall be a
        precondition to either party initiating a lawsuit or arbitration.
      </p>
      <p>
        If we do not reach an agreed upon solution within a period of 30 days
        from the time informal dispute resolution is pursued pursuant the
        immediately preceding paragraph, then either party may initiate binding
        arbitration. All claims arising out of or relating to these Terms
        (including their formation, performance and breach), your and our
        relationship and/or your use of the Services shall be finally settled by
        binding arbitration administered by JAMS, in accordance with the JAMS
        Streamlined Arbitration Rules and Procedures, excluding any rules or
        procedures governing or permitting class actions. Each party will have
        the right to use legal counsel in connection with arbitration at its own
        expense. You and we shall select a single neutral arbitrator in
        accordance with the JAMS Streamlined Arbitration Rules and Procedures.
        The arbitrator, and not any federal, state or local court or agency,
        shall have exclusive authority to resolve all disputes arising out of or
        relating to the interpretation, applicability, enforceability or
        formation of these Terms, including, but not limited to, any claim that
        all or any part of these Terms is void or voidable. The arbitrator shall
        be empowered to grant whatever relief would be available in a court
        under law or in equity. The arbitrator's award shall be in writing and
        provide a statement of the essential findings and conclusions, shall be
        binding on you and us and may be entered as a judgment in any court of
        competent jurisdiction. The interpretation and enforcement of these
        Terms shall be subject to the Federal Arbitration Act.
      </p>
      <p>
        The JAMS rules governing the arbitration may be accessed at
        https://www.jamsadr.com/adr-rules-procedures. If you initiate
        arbitration, to the extent the filing fee for the arbitration exceeds
        Two Hundred and Fifty U.S. Dollars ($250.00), we will pay the additional
        cost. If we are required to pay the additional cost of the filing fees,
        you should submit a request for payment of fees to JAMS along with your
        form for initiating the arbitration, and we will make arrangements to
        pay all necessary fees directly to JAMS. We will also be responsible for
        paying all other arbitration costs arising in connection with the
        arbitration, other than costs incurred by you for legal counsel, travel
        and other out-of-pocket costs and expenses not constituting fees or
        amounts payable to JAMS. You will not be required to pay fees and costs
        incurred by us if you do not prevail in arbitration. We will also pay
        JAMS to reimburse you for any portion of the $250 filing fee that is
        more than what you would otherwise have to pay to file suit in a court
        of law.
      </p>
      <p>
        You and we understand that, absent this mandatory provision, you and we
        would have the right to sue in court and have a jury trial. You and we
        further understand that the right to discovery may be more limited in
        arbitration than in court.
      </p>
      <p>
        You and we each further agree that any arbitration shall be conducted in
        our respective individual capacities only and not as a class action, and
        you and we each expressly waive our respective right to file a class
        action or seek relief on a class basis. If any court or arbitrator
        determines that the class action waiver set forth in this paragraph is
        void or unenforceable for any reason or that an arbitration can proceed
        on a class basis, then the arbitration provision set forth above shall
        be deemed null and void in its entirety and you and we shall be deemed
        to have not agreed to arbitrate disputes.
      </p>
      <p>
        Notwithstanding your and our agreement to resolve all disputes through
        arbitration, either you or we may seek relief in a small claims court
        for disputes or claims within the scope of that court's jurisdiction.
      </p>
      <p>
        You have the right to opt-out and not be bound by the arbitration and
        class action waiver provisions set forth above by sending written notice
        of your decision to opt-out by emailing us at support@set.live. The
        notice must be sent within 30 days of your agreement to these Terms,
        otherwise you shall be bound to arbitrate disputes in accordance with
        the terms of this Section. If you opt-out of these arbitration
        provisions, we also will not be bound by them.
      </p>
      <p>
        To the extent that the arbitration provisions set forth above do not
        apply or if you have opted out of arbitration, you and we agree that any
        litigation between you and us shall be filed exclusively in state or
        federal courts located in the State of Texas and Collin County. You and
        we expressly consent to exclusive jurisdiction in Texas for any
        litigation other than small claims court actions. In the event of
        litigation relating to these Terms or the Services, you and we agree to
        waive, to the maximum extent permitted by law, any right to a jury
        trial, except where a jury trial waiver is not permissible under
        applicable law.
      </p>
      <p>
        No action arising out of these Terms or your use of the Services,
        regardless of form or the basis of the claim, may be brought by you more
        than one year after the cause of action has arisen (or if multiple
        causes, from the date the first such cause arose).
      </p>
      <p>
        <strong>Changes</strong>
      </p>
      <p>
        Notwithstanding the provisions of the "Changes to Terms or Services"
        Section above, if MAX changes this "Dispute Resolution" section after
        the date you first accepted these Terms (or accepted any subsequent
        changes to these Terms), you may reject any such change by sending us
        written notice (including by email to support@set.live) within 30 days
        of the date such change became effective, as indicated in the "Last
        Updated" date above or in the date of MAX's email to you notifying you
        of such change. By rejecting any change, you are agreeing that you'll
        arbitrate any dispute between you and MAX in accordance with the
        provisions of this "Dispute Resolution" section as of the date you first
        accepted these Terms (or accepted any subsequent changes to these
        Terms).
      </p>
      <p>
        <strong>General Terms</strong>
      </p>
      <p>
        These Terms constitute the entire and exclusive understanding and
        agreement between MAX and you regarding the Services, Events and
        Content, and these Terms supersede and replace any and all prior oral or
        written understandings or agreements between MAX and you regarding the
        Services and Content. If for any reason a court of competent
        jurisdiction finds any provision of these Terms invalid or
        unenforceable, that provision will be enforced to the maximum extent
        permissible and the other provisions of these Terms will remain in full
        force and effect.
      </p>
      <p>
        You may not assign or transfer these Terms, by operation of law or
        otherwise, without MAX's prior written consent. Any attempt by you to
        assign or transfer these Terms, without such consent, will be null and
        of no effect. MAX may freely assign or transfer these Terms without
        restriction. Subject to the foregoing, these Terms will bind and inure
        to the benefit of the parties, their successors and permitted assigns.
      </p>
      <p>
        Any notices or other communications provided by MAX under these Terms,
        including those regarding modifications to these Terms, will be given by
        MAX: (i) via email; or (ii) by posting to the Sites. For notices made by
        e-mail, the date of receipt will be deemed the date on which such notice
        is transmitted.
      </p>
      <p>
        MAX's failure to enforce any right or provision of these Terms will not
        be considered a waiver of those rights. The waiver of any such right or
        provision will be effective only if in writing and signed by a duly
        authorized representative of MAX. Except as expressly set forth in these
        Terms, the exercise by either party of any of its remedies under these
        Terms will be without prejudice to its other remedies under these Terms
        or otherwise
      </p>
    </Wrapper>
  );
};

const Wrapper = styled(FlexColumn)`
  padding: 0 24px 24px;
  a {
    word-break: break-all;
  }
  p {
    ${(props) => props.theme.typography.p};
    margin-top: 16px;
    strong {
      font-weight: 600;
    }
  }
  ul {
    padding-left: 24px;
    list-style: disc;
    li {
      margin-top: 8px;
      ${(props) => props.theme.typography.p};
    }
  }
`;
