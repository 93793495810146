import styled from "styled-components";
import { FlexColumn, Button } from "notes";

export const Section = styled(FlexColumn)`
  text-align: center;
  width: 100%;
  padding-top: min(22.67vw, 85px);
  padding-right: 32px;
  padding-bottom: min(19.2vw, 72px);
  padding-left: 32px;

  @media only screen and ${(props) => props.theme.media.medium} {
    padding-top: min(10%, 170px);
    padding-right: 14vw;
    padding-bottom: min(10%, 170px);
    padding-left: 14vw;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

export const Container = styled(FlexColumn)`
  display: block;
  width: 100%;
  max-width: 540px;
  margin: 0 auto;
  text-align: center;
  @media only screen and ${(props) => props.theme.media.medium} {
    max-width: 1080px;
  }
`;

export const H2 = styled.h2`
  font-size: min(9.6vw, 36px);
  font-weight: 600;
  line-height: 1.4em;
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: min(3.44vw, 44px);
  }
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: 44px;
  }
`;

export const H3 = styled.h3`
  font-size: min(5.87vw, 22px);
  font-weight: 300;
  line-height: 1.2em;
  margin-bottom: 1vw;
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: min(2.73vw, 35px);
  }
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: 35px;
  }
`;

export const H4 = styled.h4`
  font-size: min(4.8vw, 18px);
  font-weight: 600;
  line-height: 1.2em;
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: min(1.95vw, 25px);
  }
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: 25px;
  }
`;

export const Paragraph = styled.p`
  font-size: min(4.27vw, 16px);
  font-weight: 300;
  line-height: 1.3em;
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: min(1.41vw, 18px);
  }
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: 18px;
  }
`;

export const GradientButton = styled.button`
  background: transparent linear-gradient(239deg, #160a71 0%, #e71e3d 100%) 0%
    0% no-repeat padding-box;
  border: none;
  border-radius: 22px;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  height: 44px;
  line-height: 24px;
  margin-top: 24px;
  padding: 0 32px;
  &:hover {
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.32);
  }
  /* @media only screen and ${(props) => props.theme.media.small} {
    border-radius: 22px;
    font-size: 12px;
    font-weight: 400;
    height: 32px;
    line-height: 15px;
    margin-top: 16px;
    min-width: initial;
    padding: 0 16px;
  } */
`;
