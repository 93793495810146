import { H2, H3, Paragraph } from ".";
import styled from "styled-components";
import { FlexColumn } from "notes";
import { Container } from ".";

interface SectionHeaderProps {
  icon?: string;
  title?: string;
  subtitle?: string;
  description?: string;
}

export const SectionHeader = ({
  icon,
  title,
  subtitle,
  description,
  ...props
}: SectionHeaderProps) => {
  return (
    <Section {...props}>
      <Wrapper>
        {icon && <SectionLogo src={icon} />}
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {description && <Description>{description}</Description>}
      </Wrapper>
    </Section>
  );
};

const Section = styled(FlexColumn)`
  text-align: center;
  width: 100%;
  padding-bottom: min(11.32vw, 45px);
  @media only screen and ${(props) => props.theme.media.medium} {
    padding-bottom: min(5.63vw, 72px);
  }
`;

const Wrapper = styled(Container)``;

const Title = styled(H2)``;

const Subtitle = styled(H3)`
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 18px;
  }
`;

const Description = styled(Paragraph)`
  width: 80%;
  margin-left: auto;
  margin-right: auto;
`;

const SectionLogo = styled.img`
  width: 70px;
  height: 70px;
  @media only screen and ${(props) => props.theme.media.medium} {
    width: 100px;
    height: 100px;
  }
`;
