export const updatePriority = (value, songs, sortableSongs) => {
  const { oldIndex, newIndex } = value;
  const currSongId = sortableSongs[oldIndex]?._id;
  const prevSong = sortableSongs[newIndex + (oldIndex > newIndex ? -1 : 0)];
  const nextSong = sortableSongs[newIndex + (oldIndex < newIndex ? 1 : 0)];
  const prevSongPriority = prevSong?.priority || 0;
  const nextSongPriority = nextSong?.priority || Number.MAX_VALUE;
  const priority = prevSongPriority / 2 + nextSongPriority / 2;
  const oldRequest = songs.find((request) => request?._id === currSongId);
  const arrayMinusId = songs.filter((request) => request?._id !== currSongId);
  const localSongs = arrayMinusId.concat([
    { ...oldRequest, priority: priority },
  ]);
  return { localSongs, priority, currSongId };
};
