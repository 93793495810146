import styled, { css } from "styled-components";

interface ActionButtonProps {
  played?: boolean;
  isReadOnly?: boolean;
}

export const ActionButton = styled.button<ActionButtonProps>`
  display: flex;
  background-color: ${(props) =>
    props.theme.branding.event.colors.action ??
    props.theme.branding.colors.action};
  align-items: center;
  font-family: ${(props) => props.theme.branding.bodyFont};
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 12px;
  margin-left: 16px;
  text-transform: uppercase;
  padding: 7px 8px 6px;
  border-radius: 4px;
  border: none;
  color: ${(props) =>
    props.theme.branding.event.colors.actionLabel ??
    props.theme.branding.colors.actionLabel};
  white-space: nowrap;
  margin-top: -6px;
  svg {
    width: 12px;
    height: 12px;
    margin-top: -3px;
    margin-right: 4px;
    path {
      fill: ${(props) =>
        props.theme.branding.event.colors.actionLabel ??
        props.theme.branding.colors.actionLabel};
    }
  }
  ${(props) =>
    props.isReadOnly &&
    !props.played &&
    css`
      background-color: transparent;
      color: ${(props) =>
        props.theme.branding.event.colors.action ??
        props.theme.branding.colors.action}cc;
      font-weight: 800;
    `};
  ${(props) =>
    props.played &&
    props.disabled &&
    css`
      background-color: transparent;
      color: ${(props) => props.theme.palette.gray.lighter};
    `};
  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        cursor: pointer;
        background-color: ${(props) =>
          props.theme.branding.event.colors.action ??
          props.theme.branding.colors.action};
        text-decoration: none;
      }
    `}
`;
