import { useState, FC, useRef } from "react";
import styled, { css } from "styled-components";
import { useSwipeable } from "react-swipeable";
import {
  FlexColumn,
  FlexRow,
  H4,
  P,
  Link,
  Icon,
  Footnote,
  Small,
  useIsMobile,
} from "notes";
import { updateFirestoreDoc } from "firebase-internal";
import { arrayUnion, Timestamp, deleteField } from "firebase/firestore";
import { SortableHandle } from "react-sortable-hoc";
import { ItemContentVote } from "./ItemContentVote";
import { ItemContentSponsor } from "./ItemContentSponsor";
import { useArtistEventContext } from "../../Components";

import type { UpdatedEventSongModel } from "../../Components";

const config = {
  delta: 10,
  preventDefaultTouchmoveEvent: false,
  trackTouch: true,
  trackMouse: true,
  rotationAngle: 0,
};

const DragHandle = SortableHandle(() => (
  <DragArea yCenter xCenter>
    <Icon indicator name="Drag" />
  </DragArea>
));

export const ListItemSwipe: FC<{
  eventId: string;
  song: UpdatedEventSongModel;
  index: number;
}> = ({ eventId, song, ...props }) => {
  const isMobile = useIsMobile();
  const { event } = useArtistEventContext();
  const { _id, requests, priority } = song;

  const [swipe, setSwipe] = useState<number>(0);

  const containerRef = useRef();
  const container = containerRef.current ?? { clientWidth: window.innerWidth };

  const swipePercentage = (swipe / container?.clientWidth) * 100;
  const swipeThreshold = isMobile ? 35 : 20;
  const isSwipeRight = swipePercentage > swipeThreshold;
  const isSwipeLeft = swipePercentage < -swipeThreshold;

  const handlers = useSwipeable({
    onSwiping: (eventData) => {
      const swipePercentage =
        eventData && (eventData?.deltaX / container?.clientWidth) * 100;
      const isValidSwipe =
        swipePercentage && swipePercentage < 50 && swipePercentage > -50;
      if (
        (eventData.dir === "Right" || eventData.dir === "Left") &&
        isValidSwipe
      ) {
        setSwipe(eventData.deltaX);
      }
    },
    onSwiped: () => {
      const path = `nextsong_events/${eventId}/playlist/${_id}`;
      if (isSwipeRight) {
        updateFirestoreDoc(path, {
          playedAt: arrayUnion(Timestamp.now()),
          priority: deleteField(),
        });
      } else if (isSwipeLeft) {
        updateFirestoreDoc(path, { declined: true });
      }
      setSwipe(0);
    },
    ...config,
  });

  return (
    <Wrapper {...props} yCenter ref={containerRef}>
      <SwipeContainer
        position={swipe}
        {...handlers}
        yCenter
        isSponsorEvent={event?.type === "sponsor"}
        hasSponsor={!!requests?.length}
      >
        {(priority !== (undefined || null) && requests?.length === 0) ||
        event?.type === "sponsor" ? (
          <DragHandle />
        ) : (
          <Spacer />
        )}
        {event?.type === "sponsor" ? (
          <ItemContentSponsor song={song} />
        ) : (
          <ItemContentVote song={song} />
        )}
      </SwipeContainer>
      <SongPlayed swipe={isSwipeRight} yCenter>
        {isSwipeRight ? (
          <Icon badge name="FacebookVerified" />
        ) : (
          <Icon indicator name="ProgressInactive" />
        )}
        <P style={{ marginLeft: "12px" }}>Played</P>
      </SongPlayed>
      <SongDecline swipe={isSwipeLeft} xEnd yCenter>
        <P style={{ marginRight: "12px" }}>Decline</P>
        {isSwipeLeft ? (
          <Icon form name="RemoveCircle" />
        ) : (
          <Icon indicator name="ProgressInactive" />
        )}
      </SongDecline>
    </Wrapper>
  );
};

const SongPlayed = styled(FlexRow)`
  background-color: ${(props) =>
    props.theme.palette.green[props.swipe ? "primary" : "lighter"]};
  padding: 0 24px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1;
  transition: background-color 0.2s ease;
  width: 50%;
  svg {
    width: 24px;
    height: 24px;
    path {
      fill: #ffffff;
    }
  }
  ${P} {
    color: #ffffff;
    font-weight: 700;
  }
`;

const SongDecline = styled(SongPlayed)`
  background-color: ${(props) =>
    props.theme.palette.red[props.swipe ? "primary" : "lighter"]};
  right: 0;
  left: initial;
  z-index: 1;
  ${P} {
    color: #ffffff;
    font-weight: 700;
  }
`;

const DragArea = styled(FlexRow)`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  margin-right: 8px;
  color: ${(props) => props.theme.palette.gray.lighter};
  cursor: grab;
`;

const SwipeContainer = styled(FlexRow)`
  align-items: flex-start;
  background-color: #ffffff;
  box-shadow: -8px 0 16px 0 rgba(0, 0, 0, 0.25),
    8px 0 16px 0 rgba(0, 0, 0, 0.25);
  padding: 12px 24px 12px 8px;
  position: relative;
  left: ${(props) => props.position ?? 0}px;
  width: 100%;
  user-select: none;
  touch-action: pan-y;
  z-index: 3;
  ${(props) =>
    props.isSponsorEvent &&
    css`
      padding-right: 16px;
      padding-left: 0px;
      ${DragArea} {
        margin-right: 2px;
      }
    `};
  ${(props) =>
    props.hasSponsor &&
    props.isSponsorEvent &&
    css`
      padding-bottom: 16px;
    `};
`;

const Wrapper = styled(FlexRow)`
  background-color: #ffffff;
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.lighter};
  position: relative;
  overflow: hidden;
  ${H4} {
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
  }
  ${Footnote}, ${Small} {
    color: ${(props) => props.theme.palette.gray.primary};
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
  }
  ${Small} {
    margin-bottom: 7px;
  }
  ${Link} {
    font-size: 12px;
    font-weight: 900;
    line-height: 15px;
    text-transform: uppercase;
  }
  ${Footnote} {
    span {
      font-weight: 600;
    }
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    border: none;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    margin-top: 8px;
  }
`;

const Spacer = styled(FlexColumn)`
  width: 16px;
  flex-shrink: 0;
`;
