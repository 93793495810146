import styled from "styled-components";
import { H4, FlexColumn, Link } from "notes";
import { NavHeader, Footer } from "Components";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();
  return (
    <>
      <NavHeader secondary onBack={() => history.push("/")} />
      <Content>
        <H4>Privacy Policy</H4>
        <p>
          Effective date: <strong>July 28, 2020</strong>
        </p>
        <p>
          NEXT SONG (“NS”) is a wholly owned property of MUSIC AUDIENCE
          EXCHANGE, INC., a Delaware corporation (“MAX”). This document acts as
          our official Privacy policy for services and products used or provided
          by MAX through NS (“Site”).
        </p>
        <p>
          We at MAX know you care about how your personal information is used
          and shared, and we take your privacy seriously. Please read the
          following to learn more about our Privacy Policy.{" "}
          <strong>
            By using or accessing the “Services” (as defined by the{" "}
            <Link onClick={() => history.push("/terms")}>Terms of Use</Link>) in
            any manner, you acknowledge that you accept the practices and
            policies outlined in the{" "}
            <Link onClick={() => history.push("/terms")}>Terms of Use</Link> and
            this Privacy Policy, and you hereby consent that we will collect,
            use, and share your information in the following ways.
          </strong>
        </p>
        <p>
          Remember that your use of MAX's Services is at all times subject to
          the <Link onClick={() => history.push("/terms")}>Terms of Use</Link>,
          which incorporates this Privacy Policy. Any terms we use in this
          Policy without defining them have the definitions given to them in the{" "}
          <Link onClick={() => history.push("/terms")}>Terms of Use</Link>.
        </p>
        <p>
          <strong>What does this Privacy Policy cover?</strong>
        </p>
        <p>
          This Privacy Policy covers our treatment of information that we gather
          when you are accessing or using our Services, but not to the practices
          of companies we don't own or control, or people that we don't manage.
          We gather various types of Personal Information from our users, as
          explained in more detail below, and we use this Personal Information
          internally in connection with our Services, including to personalize,
          provide, and improve our services, to allow you to set up a user
          account and profile, to contact you and allow other users to contact
          you, to fulfill your requests for certain products and services, and
          to analyze how you use the Services. In certain cases, we may also
          share some Personal Information with third parties, but only as
          described below.
        </p>
        <p>
          We do not knowingly collect or solicit personal information from
          anyone under the age of 13. If you are under 13, please do not attempt
          to register for the Services or send any personal information about
          yourself to us. If we learn that we have collected personal
          information from a child under age 13 without verification of parental
          consent, we will delete that information as quickly as possible. If
          you believe that a child under 13 may have provided us personal
          information, please contact us at
          <strong>privacy@musicaudience.net</strong>.
        </p>
        <h3></h3>
        <p>
          <strong>Will MAX ever change this Privacy Policy?</strong>
        </p>
        <p>
          We're constantly trying to improve our Services, so we may need to
          change this Privacy Policy from time to time as well, but we will
          alert you to changes by placing a notice on the Site, by sending you
          an email, and/or by some other means. In any event, your use of the
          site will constitute your acceptance of any changes to this Privacy
          Policy. Please note that if you've opted not to receive legal notice
          emails from us (or you haven't provided us with your email address),
          those legal notices will still govern your use of the Services, and
          you are still responsible for reading and understanding them. We
          encourage you to check this Privacy Policy and our{" "}
          <Link onClick={() => history.push("/terms")}>Terms of Use</Link>
          frequently for updates.‎ If you use the Services after any changes to
          the Privacy Policy have been posted, that means you agree to all of
          the changes. Use of information we collect now is subject to the
          Privacy Policy in effect at the time such information is collected.
        </p>
        <p>
          <strong>What Information does MAX Collect?</strong>
        </p>
        <p>
          <strong>Information You Provide to Us:</strong>
        </p>
        <p>
          We receive and store any information you knowingly provide to us. For
          example, through the registration process and/or through your account
          settings, we may collect information by which you may be personally
          identified, such as your name, email address, phone number, zip code,
          third-party account credentials (for example, your log-in credentials
          for Facebook or other third party sites), and Third Party Account
          Information (as defined below) (“Personal Information”). If you
          provide your third-party account credentials to us or otherwise sign
          in to the Services through a third party site or service, you
          understand some content and/or information in those accounts (“Third
          Party Account Information”) may be transmitted into your account with
          us if you authorize such transmissions, and that Third Party Account
          Information transmitted to our Services is covered by this Privacy
          Policy. For example, you may provide us with read access to your
          Spotify account which will cause information from Spotify to be shared
          with us, including your name and username, your profile picture, how
          many followers you have on Spotify, and your public playlists. Certain
          information may be required to register with us or to take advantage
          of some of our features.
        </p>
        <p>
          We may communicate with you if you've provided us the means to do so.
          For example, if you've given us your email address, we may send you
          promotional email offers on behalf of other businesses, or email you
          about your use of the Services. Also, we may receive a confirmation
          when you open an email from us. This confirmation helps us make our
          communications with you more interesting and improve our services. If
          you do not want to receive communications from us or promotional email
          offers on behalf of other businesses, please indicate your preference
          by emailing us at <strong>privacy@musicaudience.net</strong>.
        </p>
        <p>
          <strong>Information Collected Automatically</strong>
        </p>
        <p>
          Whenever you interact with our Services, we automatically receive and
          record information on our server logs from your browser or device,
          which may include your IP address, geolocation data, device
          identification, “cookie” information, information received from web
          beacons, embedded scripts, browser fingerprinting, iBeacons, ETags (or
          ‎‎“entity tags”), ‎the type of browser and/or device you're using to
          access our Services, and the page or feature you requested. “Cookies”
          are identifiers we transfer to your browser or device that allow us to
          recognize your browser or device and tell us how and when pages and
          features in our Services are visited and by how many people. You may
          be able to change the preferences on your browser or device to prevent
          or limit your device's acceptance of cookies, but this may prevent you
          from taking advantage of some of our features. Our advertising
          partners may also transmit cookies to your browser or device, when you
          click on ads that appear on the Services. Also, if you click on a link
          to a third party website or service, a third party may also transmit
          cookies to you. Again, this Privacy Policy does not cover the use of
          cookies by any third parties, and we do not control and aren't
          responsible for their privacy policies and practices. We may use this
          data to customize content for you that we think you might like, based
          on your usage patterns. We may also use it to improve the Services –
          for example, this data can tell us how often users use a particular
          feature of the Services, and we can use that knowledge to make the
          Services interesting to as many users as possible. This data does not
          aim to identify you personally; it simply enables us to compile
          statistics about our visitors ‎and their use of the‎ Services.
        </p>
        <p>
          <strong>Analytics Services and Targeted Ads</strong>
        </p>
        <p>
          Our Services use third-party analytics services, including, but not
          necessarily limited to, Google Analytics. ‎These services may track
          details about your online activities over time and across different
          sites. ‎These services help us to improve our Services. These services
          may also allow us to ‎provide you with targeted advertisements or
          other content that you may be interested in based on ‎your online
          activities. You can learn more about Google Analytics and your choices
          at this link. If you would like to learn more ‎about targeted ads that
          may be based on your online activities (and the choices that you may
          ‎exercise for certain sites and advertisers) you may wish to visit the
          Network Advertising Initiative or ‎the Digital Advertising Alliance
          Web sites. In addition, you may prevent the collection of the data
          generated by the cookie and related to your use of the website
          (including your IP address) by Google as well as the processing of
          this data by Google by downloading and installing the browser plug-in
          available under the following link.
        </p>
        <p>
          <strong>Do Not Track</strong>
        </p>
        <p>
          Do Not Track is a technology that enables users to opt out of tracking
          by websites they do not visit. ‎‎Currently, we do not monitor or take
          any action with respect to Do Not Track technology. Please be aware
          that cookies placed by third parties may continue to track your
          activities online even after you have left our Services, and those
          third parties may not honor “Do Not Track” requests you have set using
          your browser or device.
        </p>
        <p>
          <strong>
            Will MAX Share Any of the Personal Information it Receives?
          </strong>
        </p>
        <p>
          We may share your Personal Information with third parties as described
          in this section:
        </p>
        <p>
          <strong>Information that's been de-identified</strong>. We may
          de-identify your Personal Information so that you are not identified
          as an individual, and provide that information to our partners. We may
          also provide aggregate usage information to our partners (or allow
          partners to collect that information from you), who may use such
          information to understand how often and in what ways people use our
          Services, so that they, too, can provide you with an optimal online
          experience.
        </p>
        <p>
          <strong>Merchant Partners, Artists, and Advertisers: </strong>We allow
          advertisers, artists and/or merchant partners (“Partners”) to choose
          the demographic information of users who will see their advertisements
          and/or promotional offers and we may provide information we have
          collected from you to such Partners, in two different formats.
        </p>
        <p>
          First, we may provide any of the information that we have collected
          from you in non-personally identifiable form to a Partner, in order
          for that Partner to select the appropriate audience for those
          advertisements and/or offers. Or, we might allow Partners to display
          their ads to users with similar usage patterns to yours. Note that if
          a Partner asks us to show an ad to a certain audience or audience
          segment and you respond to that ad, the Partner may conclude that you
          fit the description of the audience they were trying to reach.
        </p>
        <p>
          Second, if you have provided us with an email address, we may provide
          that email address (along with your name) to a Partner, who may send
          you promotional offers directly, for products or services they believe
          you may be interested in. Or, for example, if you have provided us
          with an email address as part of an artist-specific interaction with
          the Services, we may provide that email address to the artist, who may
          add you to their mailing list. We do not give Partners the right to
          share your personal information with third parties.
        </p>
        <p>
          Also, we may deliver a file to you through the Services (known as a
          “web beacon”) from an ad network. Web beacons allow ad networks to
          provide anonymized, aggregated auditing, research and reporting for us
          and for advertisers. Web beacons also enable ad networks to serve
          targeted advertisements to you when you visit other websites. Because
          your web browser must request these advertisements and web beacons
          from the ad network's servers, these companies can view, edit, or set
          their own cookies, just as if you had requested a web page from their
          site. You may be able to opt-out of web beacon tracking conducted by
          third parties through our Services by adjusting the Do Not Track
          settings on your browser; please note that we don't control whether or
          how these third parties comply with Do Not Track requests.
        </p>
        <p>
          <strong>Affiliated Businesses: </strong>In certain situations,
          businesses or third party websites we're affiliated with may sell or
          provide products or services to you through or in connection with the
          Services (either alone or jointly with us). You can recognize when an
          affiliated business is associated with such a transaction or service,
          and we will share your Personal Information with that affiliated
          business only to the extent that it is related to such transaction or
          service. One such service may include the ability for you to
          automatically transmit Third Party Account Information to your
          Services profile or to automatically transmit information in your
          Services profile to your third party account; for example, posting
          your MAX playlist to your Spotify account. We have no control over the
          policies and practices of third party websites or businesses as to
          privacy or anything else, so if you choose to take part in any
          transaction or service relating to an affiliated website or business,
          please review all such business' or websites' policies.
        </p>
        <p>
          <strong>Agents: </strong>We employ other companies and people to
          perform tasks on our behalf and need to share your information with
          them to provide products or services to you. For example, sometimes a
          third party may have access to your information in order to support
          ‎our information technology or to handle mailings on our behalf.‎
          Unless we tell you differently, our agents do not have any right to
          use the Personal Information we share with them beyond what is
          necessary to assist us.
        </p>
        <p>
          <strong>User Profiles and Submissions: </strong>Certain user profile
          information, including your name, location, and any video or image
          content that such user has uploaded to the Services, may be displayed
          to other users to facilitate user interaction within the Services or
          address your request for our services. Your account privacy settings
          may allow you to limit the other users who can see the Personal
          Information in your user profile and/or what information in your user
          profile is visible to others. Please remember that any content you
          upload to your public user profile, along with any Personal
          Information or content that you voluntarily disclose online in a
          manner other users can view (on discussion boards, in messages and
          chat areas, etc.) becomes publicly available, and can be collected and
          used by anyone. Your user name may also be displayed to other users if
          and when you send messages or comments or upload images or videos
          through the Services and other users can contact you through messages
          and comments. Additionally, if you sign into the Services through a
          third party social networking site or service, your list of “friends”
          from that site or service may be automatically imported to the
          Services, and such “friends,” if they are also registered users of the
          Services, may be able to access certain non-public information you
          have entered in your Services user profile. Again, we do not control
          the policies and practices of any other third party site or service.
        </p>
        <p>
          <strong>Business Transfers: </strong>We may choose to buy or sell
          assets, and may share and/or transfer information in connection with
          the evaluation of and entry into such transactions. Also, if we (or
          our assets) are acquired, or if we go out of business, enter
          bankruptcy, or go through some other change of control, Personal
          Information could be one of the assets transferred to or acquired by a
          third party.
        </p>
        <p>
          <strong>Protection of MAX and Others: </strong>We reserve the right to
          access, read, preserve, and disclose any information that we believe
          is necessary to comply with law or court order; enforce or apply our
          <Link onClick={() => history.push("/terms")}>Terms of Use</Link> and
          other agreements; protect the rights, property, or safety of MAX, our
          employees, our users, or others or to interact with anti-fraud
          databases, protect your vital interests, or protect the security or
          integrity ‎of our databases or the‎ Services.
        </p>
        <p>
          <strong>Is Personal Information about me secure?</strong>
        </p>
        <p>
          Your account is protected by a password for your privacy and security.
          The security measures in place on the Website and computer systems aim
          to protect the loss, misuse ‎or alteration of the information you
          provide to us.‎ If you access your account via a third party site or
          service, you may have additional or different sign-on protections via
          that third party site or service. You must prevent unauthorized access
          to your account and Personal Information by selecting and protecting
          your password and/or other sign-on mechanism appropriately and
          limiting access to your computer or device and browser by signing off
          after you have finished accessing your account.
        </p>
        <p>
          We endeavor to protect the privacy and security of your account and
          other Personal Information we hold in our records, but unfortunately,
          we cannot guarantee complete security. Unauthorized entry or use,
          hardware or software failure, and other factors, may compromise the
          security of user information at any time.
        </p>
        <p>
          <strong>What Personal Information can I access?</strong>
        </p>
        <p>
          Through your account settings, you may access, and, in some cases,
          edit or delete, information you've provided to us including, for
          example:
        </p>
        <p>name and password</p>
        <p>email address</p>
        <p>
          user profile information, including images and files you have uploaded
          to the site
        </p>
        <p>
          The information you can view, update, and delete may change as the
          Services change. If you have any questions about viewing or updating
          information we have on file about you, please contact us at{" "}
          <strong>privacy@musicaudience.net</strong>. Upon receipt of your
          request and enough information to permit us to identify you, ‎we will
          correct or amend any ‎information that is inaccurate and notify any
          third party recipients of the necessary changes.‎
        </p>
        <p>
          <strong>Your California privacy rights</strong>
        </p>
        <p>
          We may from time to time elect to share certain information about ‎you
          collected by us on the Services ‎with third parties for those third
          parties' direct marketing purposes. California Civil Code Section
          ‎‎1798.83 permits California residents who have supplied personal
          ‎information, as defined in the ‎statute, to us to, under certain
          circumstances, to request and obtain ‎certain information regarding
          ‎our disclosure, if any, of personal information to third parties for
          their ‎direct marketing purposes. If ‎this applies, you may obtain the
          categories of personal information ‎shared and the names and
          ‎addresses of all third parties that received personal information for
          their ‎direct marketing purposes ‎during the immediately prior
          calendar year (e.g. requests made in 2019 will ‎receive information
          ‎about 2018 sharing activities) or to request to opt-out of such
          future sharing. ‎To make such a ‎request, please provide sufficient
          information for us to determine if this applies to ‎you, attest to the
          ‎fact that you are a California resident and provide a current
          California address for ‎our response. ‎You may make this request in
          writing at‎ <strong>privacy@musicaudience.net.</strong>
        </p>
        <p>
          The California Consumer Privacy Act (CCPA) provides California
          residents with certain rights regarding the collection and use of
          their Personal Information. We operate as a service provider with
          respect to our Partners that utilize the Services and that are subject
          to the CCPA. In those instances, we collect your Personal Information
          on such Partners' behalf, and we only use your Personal Information
          for the specific purposes of providing the Services to such Partners.
          To fulfill these purposes, we may access your Personal Information to
          provide our Services, to address technical or other service problems,
          or to comply with the instructions of our Partners on whose behalf we
          collected your Personal Information. We do not access, retain, use, or
          disclose your Personal Information for any other purpose.
        </p>
        <p>
          California Residents who wish to exercise their rights under the CCPA
          to access, delete, or opt-out of the sale of their Personal
          Information should submit their request directly to the Partner on
          whose behalf we collected your Personal Information. You may contact
          us to request information about the Partner on whose behalf we
          collected your Personal Information by emailing privacy@settheset.com.
          We are committed to supporting our Partners as needed in responding to
          your requests under the CCPA.
        </p>
        <p>
          <strong>
            What other information should I know if I live in the European
            Economic Area?
          </strong>
        </p>
        <p>
          This section of this Privacy Policy applies only if you use the
          Services from a country that is a Member State of the European
          Economic Area, and supplements the information in this Privacy Policy.
        </p>
        <p>
          We process personal data for the purposes set out in this Privacy
          Policy, as described above. Our legal basis to process personal data
          includes processing that is: necessary to offer the Services that you
          request to use (for example, to provide you with the features and
          products you request and to identify and authenticate you so you may
          use the Services); necessary to comply with legal requirements (for
          example, to comply with applicable accounting rules and to make
          mandatory disclosures to law enforcement); necessary for our
          legitimate interests (for example, to manage our relationship with you
          and to improve the Services provided to you); and based on consent by
          our customers (for example, to communicate with you about our
          products, features, and Services and provide you with marketing
          information), which may subsequently be withdrawn at any time (by
          using preference settings in emails or by contacting us with a request
          to update your preference settings by emailing{" "}
          <strong>privacy@musicaudience.net</strong>) without affecting the
          lawfulness of processing based on consent before its withdrawal.
        </p>
        <p>
          If you are from the European Union or the United Kingdom, upon
          request, free of charge, you have the right to:
        </p>
        <p>
          access and obtain a copy of the personal information we hold about
          you;
        </p>
        <p>
          obtain information about the purposes for which we process your
          personal information and the categories of personal information
          concerned;
        </p>
        <p>
          obtain information on the recipients or categories of recipients
          (including international recipients) to whom your personal information
          has been or will be disclosed;
        </p>
        <p>
          transfer of your personal information from us to another data
          controller;
        </p>
        <p>
          in certain circumstances, erasure of all personal information we hold
          about you;
        </p>
        <p>
          lodge a complaint to the supervisory authority in your jurisdiction in
          respect of our collection or use of your personal information; and
        </p>
        <p>
          withdraw your consent to our collection, use, storage, and
          dissemination of your data at any time.
        </p>
        <p>
          You can exercise your right at any time by emailing{" "}
          <strong>privacy@musicaudience.net.</strong>
        </p>
        <p>
          We may use automated decision making technologies, including
          profiling, to support our data processing activities. Our automated
          decision making capabilities include logic that attempts to identify
          products, offers, features or other services that we believe may
          interest you. By using this logic, it helps us personalize your
          interactions with MAX and our Partners. For you, this means that you
          may see online advertisements, direct marketing communications (if you
          have subscribed), or other advertising or marketing messages or
          special offers based on your activity with our Services or
          interactions with MAX or Partners or Affiliated Businesses.
        </p>
        <p>
          In some instances, you may be required to provide us with personal
          data for processing as described above, in order for us to be able to
          provide you with functionality of all the features of the Services.
        </p>
        <p>
          <strong>International and cross-border transfers</strong>
        </p>
        <p>
          The Services are controlled and operated from the United States;
          accordingly, this Privacy Policy, and our collection, use and
          disclosure of your personal information, is governed by the laws of
          the United States, and the Services are not intended to subject MAX or
          any of its affiliates to the laws or jurisdiction of any state,
          country or territory other than that of the United States. Your
          personal information may be stored and processed in any country where
          we have facilities or in which we engage service providers, and by
          using the Services you understand that your information will be
          transferred to countries outside of your country of residence,
          including the United States, which may have data protection rules that
          are different from those of your country.
        </p>
        <p>
          <strong>Links to other websites</strong>
        </p>
        <p>
          The Services may contain hyperlinks to websites that are not operated
          by us. These hyperlinks are ‎provided for your reference. We do not
          control these ‎websites and are not responsible for their data or
          privacy practices. We urge you to review any ‎privacy policy posted on
          any site you visit before using the site or providing any Personal
          Data ‎about yourself.‎
        </p>
        <p>
          <strong>What choices do I have?</strong>
        </p>
        <p>
          You can always opt not to disclose information to us, but keep in mind
          some information may be needed to register with us or to take
          advantage of some of our features. You may be able to add, update, or
          delete information as explained above. When you update information,
          however, we may maintain a copy of the unrevised information in our
          records. Some information may remain in our records after your
          deletion of such information from your account or after deletion of
          your account entirely, and we may continue to use and disclose such
          information as set forth in this privacy policy. We may also use any
          aggregated data derived from or incorporating your Personal
          Information after you update or delete it.
        </p>
        <p>
          <strong>What if I have questions about this policy?</strong>
        </p>
        <p>
          If you have any questions or concerns regarding our privacy policies,
          please send us a detailed message to{" "}
          <strong>privacy@musicaudience.net </strong>we will try to resolve your
          concerns.
        </p>
      </Content>
      <Footer />
    </>
  );
};

const Content = styled(FlexColumn)`
  margin: 0 auto;
  padding: 40px 24px;
  width: 100%;
  ${H4} {
    font-family: ${(props) => props.theme.branding.headerFont};
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    text-transform: uppercase;
  }
  a {
    word-break: break-all;
  }
  p {
    ${(props) => props.theme.typography.p};
    margin-top: 16px;
    strong {
      font-weight: 600;
    }
  }
  ul {
    padding-left: 24px;
    list-style: disc;
    li {
      margin-top: 8px;
      ${(props) => props.theme.typography.p};
    }
  }
`;
