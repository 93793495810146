import { useState, useContext } from "react";
import styled, { css } from "styled-components";
import { FlexRow, Input, Icon, FlexColumn } from "notes";
import { AnalyticsContext } from "Context";
import { Button } from "Components";
import { useCustomerDetails } from "Hooks";
import { useFanEventContext, useFanModalContext } from ".";

interface ShowSupportProps {
  layoutColumn?: boolean;
  enabled?: boolean;
  actionText?: string;
}

export const ShowSupport: React.FC<ShowSupportProps> = ({
  layoutColumn = false,
  enabled,
  actionText,
  ...props
}) => {
  const { event } = useFanEventContext();
  const { setPaymentState } = useFanModalContext();
  const customerDetails = useCustomerDetails();
  const [error, setError] = useState(false);
  const { logClick } = useContext(AnalyticsContext);

  const [support, setSupport]: any = useState(5);

  const handleSubmit = () => {
    logClick({
      label: `Show Your Support`,
      url: window.location.href,
    });
    const numberValue =
      typeof support === "string" ? parseFloat(support) : support;
    const amount = parseInt((numberValue || 0).toFixed(2));
    if (support >= 1) {
      setPaymentState({
        paymentForm: {
          amount,
          type: "donate",
          eventId: event?._id,
          ...customerDetails,
        },
      });
      setSupport(5);
    } else {
      setError(true);
    }
  };

  if (
    !enabled &&
    (event?.customizations?.support?.disabled ||
      ((event?.type === "encore" || event?.type === "checkIn") &&
        event?.customizations?.support?.disabled !== false))
  ) {
    return null;
  }

  return (
    <Container xStart yCenter layoutColumn={layoutColumn} {...props}>
      <InputContainer layoutColumn={layoutColumn}>
        <StyledInput
          leftIcon={<Icon form name="USD" />}
          value={support}
          onChange={(value) => {
            setSupport(value);
            setError(false);
          }}
          error={error}
          type="number"
          pattern="[0-9]*"
          inputmode="numeric"
        />
      </InputContainer>
      <StyledButton
        iconLeft={<Icon tertiary name="Charity" />}
        onClick={handleSubmit}
        layoutColumn={layoutColumn}
      >
        {actionText ??
          event?.customizations?.support?.buttonText ??
          "Show Your Support"}
      </StyledButton>
    </Container>
  );
};

const Container = styled(FlexRow)`
  padding: 0 16px;
  margin-bottom: 32px;
  width: 100%;
  max-width: 600px;
  ${(props) =>
    props.layoutColumn &&
    css`
      flex-direction: column;
    `};
`;

const StyledInput = styled(Input)`
  text-align: left;
  padding-left: 30px;
  flex-grow: 1;
`;

const InputContainer = styled(FlexColumn)`
  flex-grow: 0;
  width: 100px;
  ${(props) =>
    props.layoutColumn &&
    css`
      width: 100%;
      flex-grow: 1;
    `};
`;

const StyledButton = styled(Button)`
  margin-left: 8px;
  width: 100%;
  max-width: 288px;
  ${(props) =>
    props.layoutColumn &&
    css`
      margin-top: 16px;
      margin-left: 0;
      max-width: 100%;
    `};
`;
