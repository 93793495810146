import { DateTime, Duration } from "luxon";

export const buildDurationMessage = (time: DateTime, createdAt: DateTime) => {
  if (!time || !createdAt) {
    return null;
  }
  const duration = Duration.fromMillis(
    time.toMillis() - createdAt.toMillis()
  ).shiftTo("hours", "minutes", "seconds", "milliseconds");
  const m = duration.minutes;
  const h = duration.hours;
  const message =
    h > 2
      ? "more than 2 hrs. ago "
      : m > 59
      ? `1 hr. and ${m - 60} min. ago `
      : `${m >= 1 ? m : "less than 1"} min. ago `;
  return message;
};
