import styled from "styled-components";
import { FlexColumn, Input, Label, Button } from "notes";
import { useState } from "react";
import { PageModal } from "Components";
import { useArtistModalContext } from ".";

export const ModalVenueAddress = ({ open }) => {
  return (
    <PageModal open={open} title="Venue Address">
      <FormAddress />
    </PageModal>
  );
};

export const FormAddress = () => {
  const { setVenueAddress, venueAddress, setModal } = useArtistModalContext();
  const [form, setForm] = useState(venueAddress);

  const update = (key: keyof typeof form, value: unknown) => {
    setForm({ ...form, [key]: value });
  };

  const handleSubmit = () => {
    setVenueAddress(form);
    setModal({ addShow: true });
  };
  return (
    <Wrapper>
      <Label>Venue name</Label>
      <Input
        placeholder="Provide the venue name for people to search"
        value={form.venue}
        onChange={(v) => update("venue", v)}
      />
      <Label>Street address 1</Label>
      <Input
        placeholder="Address line 1..."
        value={form.street1}
        onChange={(v) => update("street1", v)}
      />
      <Label>Street address 2 (optional)</Label>
      <Input
        placeholder="Unit, Suite, Building Number..."
        value={form.street2}
        onChange={(v) => update("street2", v)}
      />
      <Label>City</Label>
      <Input
        placeholder="City..."
        value={form.city}
        onChange={(v) => update("city", v)}
      />
      <Label>State</Label>
      <Input
        placeholder="(ex: AZ)"
        value={form.state}
        onChange={(v) => update("state", v)}
      />
      <Label>Zip or postal code</Label>
      <Input
        placeholder="5-digit code..."
        value={form.zip}
        onChange={(v) => update("zip", v)}
      />
      <Button onClick={handleSubmit}>Save Changes</Button>
    </Wrapper>
  );
};

const Wrapper = styled(FlexColumn)`
  padding: 0 24px 64px;
  ${Label} {
    margin-top: 24px;
    margin-bottom: 4px;
  }
  button {
    margin-top: 40px;
  }
`;
