import styled from "styled-components";
import {
  FlexColumn,
  Modal,
  H4,
  Button,
  ModalContainer,
  Subheader,
} from "notes";
import { useArtistModalContext } from ".";

export const ModalConfirm = ({ open }) => {
  return (
    <StyledModal size="small" open={open}>
      <Confirm />
    </StyledModal>
  );
};

export const Confirm = () => {
  const { closeModal, modal } = useArtistModalContext();
  const confirm = modal?.confirm;
  const confirmAction = confirm?.action;

  const handleSubmit = () => {
    if (!!confirmAction && typeof confirmAction === "function") {
      confirmAction();
    }
    closeModal();
  };

  return (
    <Wrapper>
      <H4>{confirm?.title ?? "Confirmation"}</H4>
      {confirm?.description && <Subheader>{confirm?.description}</Subheader>}
      <Actions xEnd>
        <EndButton onClick={handleSubmit}>Confirm</EndButton>
        <Button type="tertiary" onClick={closeModal}>
          Cancel
        </Button>
      </Actions>
    </Wrapper>
  );
};

const StyledModal = styled(Modal)`
  ${ModalContainer} {
    border-radius: 8px;
    padding: 24px;
    width: 300px;
    min-height: 0;
    & > svg {
      display: none;
    }
  }
`;

const Actions = styled(FlexColumn)`
  padding: 24px 0 0 0;
  button {
    width: 100%;
  }
`;

const Wrapper = styled(FlexColumn)`
  ${H4} {
    color: ${(props) => props.theme.palette.black};
    font-weight: 700;
    text-align: center;
  }
  ${Subheader} {
    color: ${(props) => props.theme.palette.gray.primary};
    text-align: center;
  }
`;

const EndButton = styled(Button)`
  background-color: ${(props) => props.theme.palette.red.primary};
  margin-bottom: 8px;
  &:not(:disabled):hover,
  &:not(:disabled):focus {
    background-color: ${(props) => props.theme.palette.red.darker};
  }
`;
