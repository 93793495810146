import styled, { css } from "styled-components";
import { FlexRow, FlexColumn, Icon } from "notes";
import { LogoGradient, LogoWhite } from "Images";
import { useBrandingContext } from "Context";
import { useHistory } from "react-router-dom";

interface Props {
  children?: any;
  onBack?: () => void;
  showHelp?: boolean;
  secondary?: boolean;
}

export const NavHeader = ({
  children,
  onBack,
  showHelp = false,
  secondary = false,
  ...props
}: Props) => {
  const { logo, logoAlt, brand } = useBrandingContext();
  const history = useHistory();
  return (
    <>
      <Container xCenter yCenter secondary={secondary} {...props}>
        <BackIcon
          tertiary
          name="Left"
          onClick={() => {
            typeof onBack === "function" ? onBack() : history.push("/");
          }}
        />
        <FlexRow xCenter yCenter flex="0 0 auto">
          {secondary ? (
            logoAlt ? (
              <img src={logoAlt} alt={brand} />
            ) : (
              <LogoGradient />
            )
          ) : logo ? (
            <img src={logo} alt={brand} />
          ) : (
            <LogoWhite />
          )}
        </FlexRow>
        <Placeholder yCenter xCenter>
          {showHelp && <Help indicator name="Questions" />}
        </Placeholder>
        {children}
      </Container>
    </>
  );
};

const Placeholder = styled(FlexColumn)`
  width: 24px;
  height: 24px;
`;

const Help = styled(Icon)`
  width: 14px;
  height: 14px;
`;

const BackIcon = styled(Icon)`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

const Container = styled(FlexRow)`
  background: linear-gradient(45deg, #7c60d9 0%, #39a4a1 100%);
  justify-content: space-between;
  padding: 0 16px;
  position: sticky;
  top: 0;
  width: 100%;
  height: 40px;
  z-index: 10;
  img {
    max-width: 80px;
  }
  ${Help}, ${BackIcon} {
    path {
      fill: #ffffff;
    }
  }

  ${(props) =>
    props.secondary &&
    css`
      color: ${(props) => props.theme.palette.gray.primary};
      background: #ffffff;
      border-bottom: 1px solid ${(props) => props.theme.palette.gray.lightest};
      ${Help}, ${BackIcon} {
        path {
          fill: ${(props) => props.theme.palette.gray.primary};
        }
      }
    `};
`;
