import styled from "styled-components";
import { FlexColumn, Label, P, Subheader } from "notes";
import { Footer, Header as HeaderBase } from "Components";
import { useArtistModalContext } from "./Components";
import { QRCodes } from "./QRCodes";
import { Landing } from "./Landing";
export * from "./Event";

export const ArtistEventList = () => {
  const { eventView } = useArtistModalContext();
  const isUpcoming = eventView === "upcoming";
  const isPast = eventView === "past";
  const isQR = eventView === "qr";

  return (
    <Wrapper>
      <Header tertiary isArtist />
      <Content flex="1 1 100%">
        {/* {isUpcoming && (
          <Button
            type="tertiary"
            iconLeft={<Icon tertiary name="CalendarAdd" />}
            onClick={() => setModal({ addShow: true })}
          >
            Add Show
          </Button>
        )} */}
        {(isUpcoming || isPast) && <Landing />}
        {isQR && <QRCodes />}
      </Content>
      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled(FlexColumn)`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;
  }
`;

const Header = styled(HeaderBase)`
  position: sticky;
  top: 0;
`;

const StyledLabel = styled(Label)`
  color: ${(props) =>
    props.light ? props.theme.palette.gray.lighter : props.theme.palette.black};
  margin-bottom: 4px;
`;

const Content = styled(FlexColumn)`
  padding: 24px 24px 56px 24px;
  width: 100%;
  ${P} {
    font-weight: 400;
  }
  ${P} + ${StyledLabel} {
    margin-top: 24px;
  }
  & > ${Subheader} {
    color: ${(props) => props.theme.palette.gray.lighter};
    font-size: 20px;
    font-family: ${(props) => props.theme.branding.headerFont};
    line-height: 24px;
    text-transform: uppercase;
    margin-bottom: 8px;
  }
  button {
    margin-bottom: 24px;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    align-self: center;
    max-width: 600px;
  }
`;
