import { useState } from "react";
import styled from "styled-components";
import { FlexColumn, H1, P, Label, Input } from "notes";
import { Drawer, Button } from "Components";
import { updateFirestoreDoc } from "firebase-internal";
import { useFanEventContext, useFanModalContext } from ".";
import { useUser } from "auth";

export const ModalSuccess = ({ open }) => {
  const { setPaymentState } = useFanModalContext();
  return (
    <Drawer open={open} hideAction onClose={() => setPaymentState(null)}>
      <PaymentSuccess />
    </Drawer>
  );
};

export const PaymentSuccess = () => {
  const { setPaymentState, paymentState } = useFanModalContext();
  const { event, playlist } = useFanEventContext();
  const { user } = useUser();
  const { displayName, type, songId } = paymentState?.paymentSuccess;
  const [name, setName] = useState(displayName ?? "");
  const eventArtist = event?.artistName;
  const selectedSong = playlist?.find(({ _id }) => _id === songId);

  const handleSubmit = async () => {
    if (!!name?.length) {
      await updateFirestoreDoc(`nextsong_users/${user?.uid}`, {
        displayName: name,
      });
    }
    setPaymentState(null);
  };

  const title = type === "vote" ? "Vote Added!" : "Thanks For The Support!";
  const message =
    type === "vote" ? (
      <>
        {eventArtist} will be checking throughout the show to see which songs
        are getting the most support from the audience.
        <span style={{ display: "block", marginTop: "8px" }} />
        Visit the catalog anytime during the show to vote for other songs or
        vote for {selectedSong?.title} again!
      </>
    ) : (
      `Thanks for showing your support for ${eventArtist}! Contributions like yours keep live music going!`
    );

  return (
    <Wrapper>
      <H1>{title}</H1>
      <P>{message}</P>
      <Label>Who should the artist thank?</Label>
      <Input
        value={name}
        onChange={setName}
        placeholder="Please provide a name..."
      />
      <Button style={{ marginTop: "16px" }} onClick={handleSubmit}>
        Done
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled(FlexColumn)`
  padding: 16px 24px 32px 24px;
  ${H1} {
    font-size: 25px;
    line-height: 32px;
    margin-bottom: 4px;
  }
  ${P} {
    color: ${(props) => props.theme.palette.gray.primary};
    font-size: 15px;
    margin-bottom: 40px;
  }
  ${Label} {
    margin-bottom: 8px;
  }
`;
