import { createContext, useState, useEffect, useContext, FC } from "react";
import { DateTime } from "luxon";
import { getDatabase, ref, onValue } from "firebase/database";
import app from "firebase-internal";

interface TimeContextProps {
  time: DateTime;
}

export const TimeContext = createContext<TimeContextProps>(null);

export const TimeProvider: FC = ({ children }) => {
  const [serverOffset, setServerOffset] = useState(0);
  const [localTime, setLocalTime] = useState(Date.now());
  const time = DateTime.fromMillis(localTime + serverOffset);

  useEffect(() => {
    const db = getDatabase(app);
    onValue(
      ref(db, "/.info/serverTimeOffset"),
      (snapshot) => {
        setServerOffset(snapshot.val());
      },
      {
        onlyOnce: true,
      }
    );
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setLocalTime(Date.now());
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <TimeContext.Provider value={{ time }}>{children}</TimeContext.Provider>
  );
};

export const useTimeContext = () => useContext(TimeContext);
