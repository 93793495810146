import styled from "styled-components";
import { FlexRow, Icon } from "notes";

export const Notification = ({ text, onClose }) => {
  return (
    <Wrapper onClick={() => onClose()} isActive={!!text}>
      {text}
      <Icon platform name="Close" />
    </Wrapper>
  );
};

export const Wrapper = styled(FlexRow)`
  align-items: center;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.8);
  height: 44px;
  padding: 0 16px 0 12px;
  position: fixed;
  bottom: 75px;
  left: 12px;
  font-size: 15px;
  color: #ffffff;
  z-index: 9999;
  visibility: ${(props) => (props.isActive ? "visible" : "hidden")};
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  svg {
    margin-left: 20px;
    width: 15px;
    height: 15px;
    path {
      fill: #ffffff;
    }
  }
`;
