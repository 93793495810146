import { useEffect, useRef, useState, FC } from "react";
import styled from "styled-components";
import Joi from "@hapi/joi";
import { useCustomerDetails } from "Hooks";
import { validate } from "Utils";
import { useFanEventContext, useFanModalContext } from ".";
import {
  FlexColumn,
  FlexRow,
  Modal,
  ModalContainer,
  H4,
  P,
  Label,
  Footnote,
  Input,
  Icon,
  Select,
} from "notes";
import { ButtonLink } from "Components";
import _ from "lodash";
import { defaultSponsorOptions } from "./defaultFormContent";

export const ModalSponsor: FC<{ open: boolean }> = ({ open }) => {
  return (
    <StyledModal open={open}>
      <SponsorModalContent />
    </StyledModal>
  );
};

const formSchema = Joi.object({
  dedicationName: Joi.string().min(3).required(),
  displayName: Joi.string().min(3).required(),
  dedicationMessage: Joi.string().min(3).required(),
});

interface FormProps {
  displayName?: string;
  dedicationName?: string;
  dedicationMessage?: string;
  occasion?: string;
  tip?: number;
}

interface ErrorProps {
  displayName?: boolean;
  dedicationName?: boolean;
  dedicationMessage?: boolean;
  occasion?: boolean;
  tip?: boolean;
}

interface ErrorMessageProps {
  open?: boolean;
  message?: string;
}
interface SponsorMessageProps {
  id: number;
  text: string;
}

export const SponsorModalContent = () => {
  const { modalState, setModalState, setPaymentState } = useFanModalContext();
  const [step, setStep] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState<ErrorMessageProps>(null);
  const [error, setError] = useState<ErrorProps>({});
  const [form, setForm] = useState<FormProps>({});

  const { event, playlist } = useFanEventContext();
  const { artistName: eventArtist, minSponsorAmount } = event;

  const removeOptions = event?.customizations?.sponsor?.removeOptions;

  const filteredSelectOptions = !!removeOptions?.length
    ? defaultSponsorOptions?.filter(({ id }) => !removeOptions.includes(id))
    : defaultSponsorOptions;

  const selectOptions: SponsorMessageProps[] = _.merge(
    filteredSelectOptions,
    event?.customizations?.sponsor?.messageOptions
  )?.sort((a, b) => (a.id > b.id ? 1 : -1));

  const requestData = modalState?.sponsor;

  const { songId, type } = requestData;

  const { title: songName, requests } = playlist.find(
    ({ _id }) => _id === songId
  );

  const defaultAmount = minSponsorAmount ?? [1000, 2500, 5000];
  const costIndex =
    requests?.length <= defaultAmount?.length
      ? requests?.length
      : defaultAmount?.length - 1;
  const currentSponsorCost = defaultAmount[costIndex];

  const handleChange = (value, name) => {
    setForm({ ...form, [name]: value });
    if (error[name]) {
      delete error[name];
      setError(error);
      setErrorMessage(null);
    }
  };

  const handleClose = () => {
    setModalState(null);
    setError({});
    setStep(1);
  };

  const customerDetails = useCustomerDetails();

  const minBidMessage = `The minimum tip to sponsor this song is currently $${
    currentSponsorCost / 100
  }.00`;

  const handleSubmit = () => {
    const tip = form?.tip;
    const amount = typeof tip === "string" ? parseFloat(tip) : tip;
    const isWholeNumber = tip && tip % 1 === 0;
    if (tip && isWholeNumber && tip >= (currentSponsorCost ?? 100) / 100) {
      setPaymentState({
        paymentForm: {
          amount,
          songId,
          eventId: event?._id,
          type,
          ...customerDetails,
          sponsorship: {
            displayName: form.displayName,
            dedicationName: form.dedicationName,
            dedicationMessage: form.dedicationMessage,
            occasion: form.occasion ?? "",
          },
        },
      });
      handleClose();
    } else {
      setError({ tip: true });
      setErrorMessage({
        open: true,
        message: !isWholeNumber
          ? "Tips must be in increments of $1.00"
          : minBidMessage,
      });
    }
  };

  const handleNext = () => {
    const hasError = validate(form, formSchema);
    if (!hasError) {
      setStep(2);
    } else {
      setError(hasError);
    }
  };

  const inputRef = useRef<any>();

  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, []);

  const selectedMessage = selectOptions.find(
    (option) => option.text === form.dedicationMessage
  );

  return (
    <>
      <Wrapper>
        {step === 1 ? (
          <>
            <BoldText>
              Want {eventArtist} to make a shoutout along with playing{" "}
              {songName}?
            </BoldText>
            <Description>
              Sponsor the song by letting the artist know what sort of shoutout
              you would like and add a tip to show your support!
            </Description>
            <SponsorInfo>
              <FlexColumn>
                <Label>Who would you like to dedicate this song to?</Label>
                <Input
                  placeholder="Dedication name..."
                  value={form?.dedicationName}
                  onChange={(v) => handleChange(v, "dedicationName")}
                  error={error?.dedicationName}
                  maxLength={30}
                />
              </FlexColumn>
              <FlexColumn>
                <Label>How should the artist refer to you?</Label>
                <Input
                  placeholder="Your name..."
                  value={form?.displayName}
                  onChange={(v) => handleChange(v, "displayName")}
                  error={error?.displayName}
                  maxLength={15}
                />
              </FlexColumn>
              <FlexColumn>
                <Label>What message would you like attach to the song?</Label>
                <Select
                  placeholder="Select an option..."
                  options={selectOptions}
                  selected={selectedMessage}
                  onChange={(v) => handleChange(v.text, "dedicationMessage")}
                  error={error?.dedicationMessage}
                />
              </FlexColumn>
              {selectedMessage?.id === 8 && (
                <FlexColumn style={{ marginTop: "8px" }}>
                  <Input
                    placeholder="For what occasion?"
                    value={form?.occasion}
                    onChange={(v) => handleChange(v, "occasion")}
                    maxLength={30}
                  />
                </FlexColumn>
              )}
            </SponsorInfo>
          </>
        ) : (
          <>
            <BoldText>
              Enter a tip of at least ${currentSponsorCost / 100}
            </BoldText>
            <Description>
              Please note, the artist will do their best to incorporate your
              request, but sponsoring a song does not guarantee they will do so,
              and refunds are not issued.
            </Description>
            <FlexRow yCenter>
              <Label>SONG</Label>
              <H4>{songName}</H4>
            </FlexRow>
            <FlexRow yCenter>
              <Label>TIP</Label>
              <StyledInput
                leftIcon={<Icon form name="USD" />}
                value={form?.tip}
                min={currentSponsorCost / 100}
                step={1}
                onChange={(v) => handleChange(v, "tip")}
                error={error?.tip}
                style={{ flexGrow: 0, width: "100px" }}
                ref={inputRef}
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
              />
            </FlexRow>
            {errorMessage?.open && <Footnote>{errorMessage?.message}</Footnote>}
          </>
        )}
      </Wrapper>
      <Actions xEnd>
        {step !== 1 && (
          <FlexRow flex="1 1 100%">
            <ButtonLink
              type="link"
              style={{ color: "#596266" }}
              onClick={() => setStep(1)}
              iconLeft={
                <Icon style={{ marginTop: "-4px" }} tertiary name="Previous" />
              }
            >
              Back
            </ButtonLink>
          </FlexRow>
        )}
        <ButtonLink
          type="link"
          style={{ color: "#596266" }}
          onClick={handleClose}
        >
          Cancel
        </ButtonLink>
        {step === 1 ? (
          <ButtonLink type="link" onClick={handleNext}>
            Next
          </ButtonLink>
        ) : (
          <ButtonLink type="link" onClick={handleSubmit}>
            Submit
          </ButtonLink>
        )}
      </Actions>
    </>
  );
};

const SponsorInfo = styled(FlexColumn)`
  padding: 0 24px;
  margin-bottom: 24px;
  ${Label} {
    margin-top: 16px;
    font-size: 14px;
  }
`;

const StyledModal = styled(Modal)`
  ${ModalContainer} {
    padding: 24px 0 16px 0;
    min-height: initial;
    width: calc(100% - 40px);
    & > svg {
      display: none;
    }
  }
`;

const BoldText = styled(P)`
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  margin-bottom: 16px;
  padding: 0 24px 0 24px;
`;

const Description = styled(P)`
  font-size: 13px;
  line-height: 17px;
  margin-bottom: 8px;
  padding: 0 24px 0 24px;
`;

const Wrapper = styled(FlexColumn)`
  & > ${H4} {
    font-weight: 600;
    margin-bottom: 4px;
    padding: 0 0 0 24px;
  }
  ${Label} {
    flex-grow: 1;
  }
  & > ${FlexRow}:first-of-type {
    border-bottom: 1px solid ${(props) => props.theme.palette.gray.lightest};
    padding: 16px 24px 16px 8px;
    margin-left: 24px;
    & > ${H4} {
      line-height: 22px;
      font-weight: 700;
      text-align: right;
      width: 100%;
      max-width: 174px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  & > ${FlexRow}:nth-of-type(2) {
    padding: 8px 24px 8px 8px;
    margin-left: 24px;
  }
  & > ${Footnote} {
    padding-left: 8px;
    padding-right: 24px;
    margin-left: 24px;
    margin-bottom: 12px;
  }
`;

const Actions = styled(FlexRow)`
  border-top: 1px solid ${(props) => props.theme.palette.gray.lightest};
  padding: 16px 24px 0 24px;
  button + button {
    margin-left: 16px;
  }
`;

const StyledInput = styled(Input)`
  text-align: right;
`;
