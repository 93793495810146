import { createContext, useContext, FC, useState } from "react";
import { ModalSchedule } from ".";

interface AboutContextProps {
  scheduleModalOpen: boolean;
  setScheduleModalOpen: (value: boolean) => void;
}

export const AboutContext = createContext<AboutContextProps>(null);

export const AboutProvider: FC = ({ children }) => {
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);

  const value = { setScheduleModalOpen, scheduleModalOpen };
  return (
    <AboutContext.Provider value={value}>
      <ModalSchedule open={scheduleModalOpen} setOpen={setScheduleModalOpen} />
      {children}
    </AboutContext.Provider>
  );
};

export const useAboutContext = () => useContext(AboutContext);
