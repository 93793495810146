import { FC } from "react";
import styled, { css } from "styled-components";
import {
  FlexColumn,
  H4,
  FlexRow,
  Footnote,
  Icon,
  P,
  Small,
  Label as LabelBase,
} from "notes";
import { updateFirestoreDoc } from "firebase-internal";
import { useArtistEventContext } from "Routes/Artist/Components";

import type { UpdatedEventSongModel } from "../../Components";

export const ItemContentSponsor: FC<{ song: UpdatedEventSongModel }> = ({
  song,
}) => {
  const { event } = useArtistEventContext();
  const { title, artist, requests: allRequests } = song;
  const requests = allRequests?.filter(({ type }) => type === "sponsor");

  const handleChange = (reqId: string, checked: boolean) => {
    updateFirestoreDoc(`nextsong_events/${event?._id}/payments/${reqId}`, {
      read: checked,
    });
  };

  return (
    <Wrapper flex="1 1 100%">
      <FlexRow yStart>
        <FlexColumn flex="1 1 100%">
          <H4>{title}</H4>
          <Small>{artist}</Small>
        </FlexColumn>
      </FlexRow>
      <CardContainer yCenter>
        {!!requests?.length && (
          <>
            {requests?.map((req, index) => (
              <Card read={req.read} key={`${song?._id}-${index}`}>
                <CheckboxContainer
                  read={req.read}
                  onClick={() => handleChange(req?._id, !req.read)}
                >
                  <Icon badge name="FacebookVerified" />
                </CheckboxContainer>
                <LineItem style={{ marginRight: "36px" }}>
                  <Label>Dedication</Label>
                  <Value>{req.sponsorship?.dedicationMessage}</Value>
                </LineItem>
                {!req.read && (
                  <>
                    {req.sponsorship?.occasion && (
                      <LineItem>
                        <Value>{req.sponsorship?.occasion}</Value>
                      </LineItem>
                    )}
                    <FlexRow style={{ marginTop: "4px" }}>
                      <FlexRow flex="0 0 50%">
                        <LineItem>
                          <Label>From</Label>
                          <Value>{req.sponsorship?.displayName}</Value>
                        </LineItem>
                      </FlexRow>
                      <FlexRow flex="0 0 50%">
                        <LineItem>
                          <Label>To</Label>
                          <Value>{req.sponsorship?.dedicationName}</Value>
                        </LineItem>
                      </FlexRow>
                    </FlexRow>
                  </>
                )}
              </Card>
            ))}
          </>
        )}
      </CardContainer>
    </Wrapper>
  );
};

const CheckboxContainer = styled(FlexColumn)`
  position: absolute;
  top: 12px;
  right: 10px;
  width: 24px;
  height: 24px;
  svg {
    width: 24px;
    height: 24px;
    path {
      fill: ${(props) =>
        props.read
          ? props.theme.palette.green.lighter
          : props.theme.palette.gray.lighter};
    }
  }
`;

interface LabelProps {
  color: string;
}

const Label = styled(LabelBase)<LabelProps>`
  color: ${(props) => props.theme.palette.gray.lighter};
  font-size: 12px;
  font-weight: 600;
  margin-bottom: -2px;
  /* ${(props) =>
    props.color &&
    css`
      color: ${props.theme.palette[props.color].primary};
    `}; */
`;

const LineItem = styled(FlexColumn)`
  font-size: 14px;
  font-weight: 700;
  align-items: flex-start;
`;

const Value = styled(P)<LabelProps>`
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  ${(props) =>
    props.color &&
    css`
      color: ${props.theme.palette[props.color].primary};
    `};
`;

const Card = styled(FlexColumn)`
  background: ${(props) => props.theme.palette.gray.hover};
  border-radius: 8px;
  /* border-top: 1px solid ${(props) => props.theme.palette.gray.lightest}; */
  position: relative;
  text-align: center;
  width: 100%;
  height: auto;
  flex-grow: 0;
  padding: 4px 12px;
  transition: all ease 0.3s;
  width: auto;
  font-size: 14px;
  & + & {
    margin-top: 8px;
  }
  ${(props) =>
    props.read &&
    css`
      padding: 4px 12px;
      height: 48px;
      overflow: hidden;
      ${LineItem} {
        opacity: 0.3;
      }
    `};
`;

const CardContainer = styled(FlexColumn)`
  width: 100%;
`;

const Wrapper = styled(FlexColumn)`
  ${H4} {
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
  }
  ${Footnote}, ${Small} {
    color: ${(props) => props.theme.palette.gray.primary};
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
  }
  ${Small} {
    margin-bottom: 7px;
  }
  ${Footnote} {
    span {
      font-weight: 600;
    }
  }
`;
